import * as React from 'react';
import Common from '../Util/Common';
import { TabComponent } from '@syncfusion/ej2-react-navigations';
import {
  NumericTextBoxComponent,
  TextBoxComponent,
} from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import {
  DropDownListComponent,
  DropDownTreeComponent,
  TreeSettingsModel,
} from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { DateTime } from '@syncfusion/ej2-react-charts';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';

interface dataModel {
  isAdd?: boolean;
  selectorService: any;
  jsonSelector: any;
  peopleData: {};
  isEmployee: boolean;
  isLoading: boolean;
  correspondenceData?: {};
  showDialog: boolean;
  showDialogContractType: false;
  showDialogDepartment: false;
  showDialogBranch: false;
  showDialogJob: false;
  showDialogCompany: false;
  handleCancelClick?: (e: any) => void;
  handleSaveClick?: (e: any, isAdd: boolean) => void;
}

const { REACT_APP_ENDPOINT_CORESERVICE, REACT_APP_ENDPOINT_EMPLOYEESERVICE } =
  process.env;

export default class DialogAddEditTemplate extends React.Component<dataModel> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private tabRef: TabComponent | any;
  private selectorservice: any = null;
  private fields: object = { text: 'name', value: 'id' };
  private cfields: object = { value: 'id', text: 'itemValue' };
  private gfields: object = { value: 'id', text: 'itemValue' };
  private rfields: object = { value: 'id', text: 'itemValue' };
  private mfields: object = { value: 'id', text: 'itemValue' };
  private jsonpersonobj: any;
  private listObj: DropDownListComponent | any;
  private listContract: DropDownListComponent | any;
  private listGender: DropDownListComponent | any;
  private listJob: DropDownListComponent | any;
  private listBranch: DropDownListComponent | any;
  private listDepartment: DropDownTreeComponent | any;
  private listNationality: DropDownListComponent | any;
  private listReligion: DropDownListComponent | any;
  private listMaritalStatus: DropDownListComponent | any;
  private objNationality: any = [];
  private jsondepatment: any;
  private jsonbranch: any;
  private jsonjob: any;
  private contract_type: any;
  private sCombobox: string = '';
  public extradialogheader = 'New Person';
  private emp_personfname: string = '';
  private emp_personmname: string = '';
  private emp_personlname: string = '';
  private emp_personbirthdate?: DateTime;
  private emp_persongender: string = '';
  private personbirthplace: string = '';
  private personNationality: string = '';
  private intNewPersonId: any = 0;
  private drpSender: DropDownTreeComponent | any = undefined;
  private drpRecipient: DropDownTreeComponent | any = undefined;
  toastObj: any;

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.selectorservice = props.selectorService;
  }

  componentDidMount() {
    switch (this.selectorservice.Id) {
      case this._structure.SidebarConstants.SidebarService.Correspondence.Id:
        this.GetPerson();
        break;
      case this._structure.SidebarConstants.SidebarService.People.Id:
        this.GetPerson();
        this.GetDepartment();
        this.GetBranch();
        this.GetJob();
        this.GetCountryList();
        break;

      default:
        break;
    }
  }

  state = {
    isAdd: this.props.isAdd ?? true,
    peopleData: this.props.peopleData ?? undefined,
    correspondenceData: this.props.correspondenceData ?? undefined,
    isEmployee: this.props.isEmployee,
    isLoading: this.props.isLoading,
    showDialog: this.props.showDialog,
    showDialogContractType: this.props.showDialog,
    showDialogDepartment: this.props.showDialog,
    showDialogBranch: this.props.showDialog,
    showDialogJob: this.props.showDialog,
    showDialogCompany: this.props.showDialog,
  };

  private setSelector(selectorService: any, payload: any, IsEdit?: boolean) {
    Common.ShowSpinner();
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/${selectorService.UrlSet}`,
      payload,
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        switch (Number(selectorService.Id)) {
          case Number(
            this._structure.SidebarConstants.SidebarService.People.Id
          ):
            this.toastObj = ToastUtility.show({
              cssClass: 'e-toast-success',
              icon: 'far fa-circle-check',
              showCloseButton: true,
              content: !IsEdit
                ? 'People Created Successfully ..!'
                : 'People Updated Successfully ..!',
              target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 4000,
            });
            if (!IsEdit) {
              if (this.props.handleSaveClick)
                this.props.handleSaveClick(response.data, true);
            } else {
              if (this.props.handleSaveClick)
                this.props.handleSaveClick(response.data, false);
            }
            break;
          case Number(
            this._structure.SidebarConstants.SidebarService.Correspondence.Id
          ):
            this.toastObj = ToastUtility.show({
              cssClass: 'e-toast-success',
              icon: 'far fa-circle-check',
              showCloseButton: true,
              content: 'Correspondence Created Successfully ..!',
              target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 4000,
            });
            if (this.props.handleSaveClick)
              this.props.handleSaveClick(response.data, true);
            return response.data;
            break;
          default:
            break;
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        Common.HideSpinner();
      });
  }

  private RefreshDropDown() {
    try {
      if (
        this.listObj &&
        this.listObj.text !== null &&
        this.listObj.value === '-999'
      ) {
        this.listObj.value = '';
        this.listObj.text = '';
        this.listObj.refresh();
      }
      if (
        this.listContract &&
        this.listContract.text !== null &&
        this.listContract.value === '-999'
      ) {
        this.listContract.value = '';
        this.listContract.text = '';
        this.listContract.refresh();
      }
      if (
        this.listJob &&
        this.listJob.text !== null &&
        this.listJob.value === '-999'
      ) {
        this.listJob.value = '';
      }
      if (
        this.listBranch &&
        this.listBranch.text !== null &&
        this.listBranch.value[0] === '-999'
      )
        //BRANCH
        this.listBranch.value = '';

      if (
        this.listDepartment &&
        this.listDepartment.text !== null &&
        this.listDepartment.value[0] === '-999'
      ) {
        this.listDepartment.value = '';
      }
    } catch (error: any) {
      console.log(error);
    }
  }

  public GetPerson() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/${this._structure.SidebarConstants.SidebarService.Person.UrlGet}`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        switch (this.selectorservice.Id) {
          case this._structure.SidebarConstants.SidebarService.Correspondence
            .Id:
            this.GetCompany(data.filter((x: any) => x.employee !== null));
            break;

          default:
            break;
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetJob() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/listjob`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        data.splice(0, 0, { id: '-999', name: 'New ...' });
        this.jsonjob = data;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetBranch() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/listbranch`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        data.splice(0, 0, { id: '-999', name: 'New ...' });
        this.jsonbranch = data;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private GetDepartment() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/listdepartment`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        data.splice(0, 0, { id: '-999', name: 'New ...' });
        this.jsondepatment = data;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetContractType() {
    let contracttype: any;
    contracttype = this.user.commondata.filter(
      (g: any) => g.itemType == this._structure.CommonDataItems.ContractType
    );
    contracttype.splice(0, 0, {
      id: '-999',
      itemType: 5,
      itemValue: 'New ...',
    });
    return contracttype;
  }

  public GetCountryList() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/country/get`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        // response.data.forEach((x: any) => {
        //   this.objNationality.push({
        //     id: x.id,
        //     name: x.name,
        //   });
        this.objNationality = response.data.map((x: any) => {
          return { id: x.id, name: x.name };
        });
        // });
        let obj: any = document.querySelector(
          '#dlgAddEditRecorddialog #peopleAddEditDialogTab #nationalityid'
        );
        if (obj) {
          obj.ej2_instances[0].dataSource = this.objNationality;
          obj.ej2_instances[0].refresh();
        }
        return response.data;
      })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetCompany(persondata: any = null) {
    let dataCorres: any = this.state.correspondenceData;
    let decodedtoken: any = Common.parseJwt(Common.getToken()!);
    let companydata: any = [];
    companydata.push({
      id: decodedtoken.tenant,
      name: this.user.tenantName,
      parentid: -1,
    });
    persondata
      .sort((a: any, b: any) => {
        return a.name > b.name ? 1 : -1;
      })
      .forEach((x: any) => {
        x['parentid'] = -2;
        if (
          this.selectorservice.Id ===
          this._structure.SidebarConstants.SidebarService.Correspondence.Id
        ) {
          x['id'] = x.employee.id;
        }
      });
    persondata.splice(1, 0, { id: -1, name: 'Company', hasChild: true });
    persondata.splice(2, 0, { id: -2, name: 'Person', hasChild: true });
    persondata = persondata.concat(
      companydata.map((x: any) => ({
        id: x.id,
        name: x.name,
        parentid: x.parentid,
      }))
    );
    dataCorres['jsonpersoncompany'] = persondata;
    this.setState({ correspondenceData: dataCorres });
    return companydata;
  }

  public SetPerson() {
    if (
      this.emp_personfname !== '' &&
      this.emp_personlname !== '' &&
      (this.emp_personbirthdate !== undefined ||
        this.emp_personbirthdate !== null)
    ) {
      let personSetDto: any = {
        firstname: this.emp_personfname,
        lastname: this.emp_personlname,
        birthdate: this.emp_personbirthdate,
        middlename: this.emp_personmname ? this.emp_personmname : '',
        birthplace: this.personbirthplace ? this.personbirthplace : '',
        nationalityid: this.personNationality ? this.personNationality : '',
        bio: [
          {
            commonDataId: this.emp_persongender,
          },
        ],
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/${this._structure.SidebarConstants.SidebarService.Person.UrlSet}`,
        personSetDto,
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((data: any) => {
          switch (this.selectorservice.Id) {
            case this._structure.SidebarConstants.SidebarService.Correspondence
              .Id:
              let datacorresp: any = this.state.correspondenceData;
              let pcdata: any = datacorresp.jsonpersoncompany;
              let flter: any = pcdata.filter((x: any) => x.parentid === -2);
              if (flter.length > 0) {
                pcdata.splice(3, 0, {
                  id: data.id,
                  name: data.name,
                  parentid: -2,
                });
              }
              let s1: string[] =
                this.drpSender.value.length > 0 &&
                this.drpSender.value[0] === '-999'
                  ? [data.id.toString()]
                  : this.drpSender.value;
              let s2: string[] =
                this.drpRecipient.value.length > 0 &&
                this.drpRecipient.value[0] === '-999'
                  ? [data.id.toString()]
                  : this.drpRecipient.value;
              let t1: string[] =
                this.drpSender.value.length > 0 &&
                this.drpSender.value[0] === '-999'
                  ? data.name.toString()
                  : [this.drpSender.text];
              let t2: string[] =
                this.drpRecipient.value.length > 0 &&
                this.drpRecipient.value[0] === '-999'
                  ? data.name.toString()
                  : [this.drpRecipient.text];

              datacorresp['jsonpersoncompany'] = pcdata;
              this.setState({
                correspondenceData: datacorresp,
                showDialog: false,
              });
              this.drpSender.refresh();
              this.drpRecipient.refresh();
              this.drpSender.value = s1;
              this.drpSender.text = t1;
              this.drpRecipient.value = s2;
              this.drpRecipient.text = t2;
              this.drpSender.ensureVisible(s1[0]);
              this.drpRecipient.ensureVisible(s2[0]);
              break;

            default:
              break;
          }
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  }

  public SetCompany() {
    let data: any = this.state.correspondenceData;
    if (data.company_name) {
      let companySetDto: any = {
        name: data.company_name,
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/company/Set`,
        companySetDto,
        Common.getToken() || '',
        this.user
      )
        .then((response: any) => {
          return response.data;
        })
        .then((data: any) => {
          switch (this.selectorservice.Id) {
            case this._structure.SidebarConstants.SidebarService.Correspondence
              .Id:
              let datacorresp: any = this.state.correspondenceData;
              let pcdata: any = datacorresp.jsonpersoncompany;
              let flter: any = pcdata.filter((x: any) => x.parentid === -1);
              if (flter.length > 0) {
                pcdata.splice(1, 0, {
                  id: data.id,
                  name: data.name,
                  parentid: -1,
                });
              }
              let s1: string[] =
                this.drpSender.value.length > 0 &&
                this.drpSender.value[0] === '-888'
                  ? [data.id.toString()]
                  : this.drpSender.value;
              let s2: string[] =
                this.drpRecipient.value.length > 0 &&
                this.drpRecipient.value[0] === '-888'
                  ? [data.id.toString()]
                  : this.drpRecipient.value;

              datacorresp['jsonpersoncompany'] = pcdata;
              this.setState({
                correspondenceData: datacorresp,
                showDialog: false,
                showDialogCompany: false,
              });
              this.toastObj = ToastUtility.show({
                cssClass: 'e-toast-success',
                icon: 'far fa-circle-check',
                showCloseButton: true,
                content: 'Record saved SuccessFully..!',
                target: '#basic_card',
                position: { X: 'Center', Y: 'Top' },
                width: 'auto',
                timeOut: 3000,
              });
              this.drpSender.refresh();
              this.drpRecipient.refresh();
              this.drpSender.value = s1;
              this.drpRecipient.value = s2;
              this.drpSender.ensureVisible(s1[0]);
              this.drpRecipient.ensureVisible(s2[0]);
              break;

            default:
              break;
          }
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  }

  ValidateForm = () => {
    const inputs = [
      document.querySelector<HTMLInputElement>('#firstname'),
      document.querySelector<HTMLInputElement>('#lastname'),
      document.querySelector<HTMLInputElement>('#birthdate'),
      document.querySelector<HTMLInputElement>('#gender'),
      document.querySelector<HTMLInputElement>('#employeecode'),
      document.querySelector<HTMLInputElement>('#appointmentdate'),
      document.querySelector<HTMLInputElement>('#contractid'),
      document.querySelector<HTMLInputElement>('#company_name'),
      document.querySelector<HTMLInputElement>('#pgender'),
      document.querySelector<HTMLInputElement>('#pdate'),
      document.querySelector<HTMLInputElement>('#plname'),
      document.querySelector<HTMLInputElement>('#pfname'),
      document.querySelector<HTMLInputElement>('#contract_type'),
    ];

    inputs.forEach((input) => {
      if (input) {
        input.classList.remove('textbox-border-error');

        if (input.value.trim() === '') {
          input.classList.add('textbox-border-error');
        } else {
          input.classList.add('textbox-class');
        }
      }
    });
  };

  private ContractTypeSave() {
    this.ValidateForm();
    var _input: any = document.getElementById('contract_type');
    if (_input.value.trim().length <= 0) {
      const TargetInput =
        document.querySelector<HTMLInputElement>('#contract_type');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }
    var payload: any = {
      itemType: this._structure.CommonDataItems.ContractType,
      itemValue: _input.value,
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/commondata/add`,
      payload,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        if (this.user.commondata) {
          this.user.commondata.push({
            id: resopnse.data.id,
            itemType: this._structure.CommonDataItems.ContractType,
            itemValue: resopnse.data.itemValue,
          });
          Common.setItem('user', this.user);
          this.setState({ showDialog: false });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-success',
            icon: 'far fa-circle-check',
            showCloseButton: true,
            content: 'Contract type saved SuccessFully..!',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          let contract: any = this.contract_type;
          contract.splice(this.contract_type.length + 1, 0, {
            id: resopnse.data.id,
            itemType: this._structure.CommonDataItems.ContractType,
            itemValue: resopnse.data.itemValue,
          });
          this.contract_type = contract;
          this.listContract.value = resopnse.data.id;
          this.listContract.text = resopnse.data.itemValue;
          this.listContract.refresh();
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private DepartmentSave() {
    this.ValidateForm();
    var _input: any = document.getElementById('department');
    if (_input.value.trim().length <= 0) {
      const TargetInput =
        document.querySelector<HTMLInputElement>('#department');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }

    var payload: any = {
      name: _input.value,
      isgroup: false,
      selectable: true,
      showAllDuplicates: true,
      addNewOption: true,
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/adddepartment`,
      payload,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        this.setState({ showDialog: false });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-success',
          icon: 'far fa-circle-check',
          showCloseButton: true,
          content: 'Department Record saved SuccessFully..!',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        let department: any = this.jsondepatment;
        department.splice(this.jsondepatment.length + 1, 0, {
          id: resopnse.data.id,
          name: resopnse.data.name,
          addNewOption: null,
          departmentId: null,
          isgroup: false,
          selectable: true,
          showAllDuplicates: null,
          subDepartments: [],
        });
        this.jsondepatment = department;
        this.listDepartment.refresh();
        this.listDepartment.value = resopnse.data.id;
        this.listDepartment.text = resopnse.data.name;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private BranchSave() {
    this.ValidateForm();
    var _input: any = document.getElementById('branch');
    if (_input.value.trim().length <= 0) {
      const TargetInput = document.querySelector<HTMLInputElement>('#branch');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }

    var payload: any = {
      name: _input.value,
      isgroup: false,
      selectable: true,
      showAllDuplicates: true,
      addNewOption: true,
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/addbranch`,
      payload,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        this.setState({ showDialog: false });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-success',
          icon: 'far fa-circle-check',
          showCloseButton: true,
          content: 'Branch Record saved SuccessFully..!',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        let branch: any = this.jsonbranch;
        branch.splice(this.jsonbranch.length + 1, 0, {
          id: resopnse.data.id,
          name: resopnse.data.name,
          addNewOption: null,
          branchId: null,
          isgroup: false,
          selectable: true,
          showAllDuplicates: null,
          subBranchs: [],
        });
        this.jsonbranch = branch;
        this.listBranch.refresh();
        this.listBranch.value = resopnse.data.id;
        this.listBranch.text = resopnse.data.name;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private JobSave() {
    this.ValidateForm();
    var _input: any = document.getElementById('job');
    if (_input.value.trim().length <= 0) {
      const TargetInput = document.querySelector<HTMLInputElement>('#job');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }

    var payload: any = {
      code: _input.value,
      name: _input.value,
      reportToJobId: null,
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/addjob`,
      payload,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        this.setState({ showDialog: false });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-success',
          icon: 'far fa-circle-check',
          showCloseButton: true,
          content: 'Job Record saved SuccessFully..!',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        let job: any = this.jsonjob;
        job.splice(this.jsonjob.length + 1, 0, {
          id: resopnse.data.id,
          name: resopnse.data.name,
          addNewOption: null,
          branchId: null,
          isgroup: false,
          selectable: true,
          showAllDuplicates: null,
          subBranchs: [],
        });
        this.jsonjob = job;
        this.listJob.refresh();
        this.listJob.value = resopnse.data.id;
        this.listJob.text = resopnse.data.name;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private personsave() {
    this.ValidateForm();
    if (this.emp_personfname == '') {
      const TargetInput = document.querySelector<HTMLInputElement>('#pfname');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }
    if (this.emp_personlname == '') {
      const TargetInput = document.querySelector<HTMLInputElement>('#plname');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }
    if (this.emp_persongender == '') {
      const TargetInput = document.querySelector<HTMLInputElement>('#pgender');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }
    if (
      this.emp_personbirthdate == undefined ||
      this.emp_personbirthdate == null
    ) {
      const TargetInput = document.querySelector<HTMLInputElement>('#pdate');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }
    this.SetPerson();
  }

  private CompanySave() {
    this.ValidateForm();
    let data: any = this.state.correspondenceData;
    if (!data.company_name) {
      const TargetInput =
        document.querySelector<HTMLInputElement>('#company_name');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }
    this.SetCompany();
  }

  public extradialogbuttons: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        switch (this.selectorservice.Id) {
          case this._structure.SidebarConstants.SidebarService.Correspondence
            .Id:
            if (this.state.showDialogCompany === true) {
              if (this.drpSender.value[0] === '-888') this.drpSender.clear();
              if (this.drpRecipient.value[0] === '-888')
                this.drpRecipient.clear();
            } else {
              if (this.drpSender.value[0] === '-999') this.drpSender.clear();
              if (this.drpRecipient.value[0] === '-999')
                this.drpRecipient.clear();
            }
            this.setState({ showDialog: false, showDialogCompany: false });
            break;

          case this._structure.SidebarConstants.SidebarService.People.Id:
            this.RefreshDropDown();
            this.setState({
              showDialog: false,
              showDialogContractType: false,
              showDialogJob: false,
              showDialogBranch: false,
              showDialogDepartment: false,
              showDialogCompany: false,
            });
            break;

          default:
            break;
        }
      },
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        switch (this.selectorservice.Id) {
          case this._structure.SidebarConstants.SidebarService.People.Id:
            if (this.state.showDialogContractType === true) {
              this.ContractTypeSave();
            } else if (this.state.showDialogDepartment === true) {
              this.DepartmentSave();
            } else if (this.state.showDialogBranch === true) {
              this.BranchSave();
            } else if (this.state.showDialogJob === true) {
              this.JobSave();
            } else {
              this.personsave();
            }
            break;
          case this._structure.SidebarConstants.SidebarService.Correspondence
            .Id:
            if (this.state.showDialogCompany === true) {
              this.CompanySave();
            } else {
              this.personsave();
            }
            break;
          default:
            break;
        }
      },
    },
  ];

  onFocusFunction = (event: any) => {
    event.target.classList.remove('textbox-border-error');
  };

  onChangeCompany = (e: any) => {
    this.setState({
      [(e.target as HTMLInputElement).name]: e.target.value,
    });
  };

  private CloseDialog = (e: any) => {
    this.RefreshDropDown();
    this.setState({
      showDialog: false,
      showDialogContractType: false,
      showDialogJob: false,
      showDialogBranch: false,
      showDialogDepartment: false,
      showDialogCompany: false,
    });
  };

  public dialogContentCompany() {
    let datacorresp: any = this.state.correspondenceData;
    return (
      <>
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            <TextBoxComponent
              className='input-text-border'
              id='compayname'
              name='company_name'
              type='text'
              placeholder='Enter Company Name *'
              floatLabelType='Auto'
              onFocus={this.onFocusFunction}
              value={datacorresp.company_name}
              autocomplete='off'
              // onChange={this.onChangeCompany.bind(this)}
            />
          </div>
        </div>
      </>
    );
  }

  public dialogContentContractType() {
    let datacontract: any = this.state.peopleData;
    return (
      <>
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            <TextBoxComponent
              className='textbox-class'
              id='contract_type'
              name='contract_type'
              type='text'
              placeholder='Enter Contract Type *'
              floatLabelType='Auto'
              onFocus={this.onFocusFunction}
              value={datacontract.contract_type!}
              autocomplete='off'
              style={{ fontSize: '15px' }}
            />
          </div>
        </div>
      </>
    );
  }

  public dialogContentJob() {
    let datajob: any = this.state.peopleData;
    return (
      <>
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            <TextBoxComponent
              className='input-text-border'
              id='job'
              name='job'
              type='text'
              placeholder='Enter Job *'
              floatLabelType='Auto'
              onFocus={this.onFocusFunction}
              value={datajob.job}
              autocomplete='off'
            />
          </div>
        </div>
      </>
    );
  }

  public dialogContentBranch() {
    let databranch: any = this.state.peopleData;
    return (
      <>
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            <TextBoxComponent
              className='input-text-border'
              id='branch'
              name='branch'
              type='text'
              placeholder='Enter Branch *'
              floatLabelType='Auto'
              onFocus={this.onFocusFunction}
              value={databranch.branch!}
              autocomplete='off'
            />
          </div>
        </div>
      </>
    );
  }

  public dialogContentDepartment() {
    let dataDepartment: any = this.state.peopleData;
    return (
      <>
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            <TextBoxComponent
              className='input-text-border'
              id='department'
              name='department'
              type='text'
              placeholder='Enter Department *'
              floatLabelType='Auto'
              onFocus={this.onFocusFunction}
              value={dataDepartment.department}
              autocomplete='off'
            />
          </div>
        </div>
      </>
    );
  }

  private saveClick(args: any) {
    this.ValidateForm();
    let peopleData: any = this.state.peopleData;
    let correspondanceData: any = this.state.correspondenceData;
    switch (this.selectorservice.Id) {
      case this._structure.SidebarConstants.SidebarService.People.Id:
        let personSetDto: any = null;
        let employeeSetDto: any = null;
        if (!peopleData.id) {
          personSetDto = {
            firstname: peopleData.firstname,
            lastname: peopleData.lastname,
            middlename: peopleData.middlename ? peopleData.middlename : '',
            birthdate: peopleData.birthdate,
            birthplace: peopleData.birthplace ? peopleData.birthplace : '',
            nationalityid: peopleData.nationalityid
              ? peopleData.nationalityid
              : null,
            bio: [
              {
                commonDataId: peopleData.gender,
                commonData: {
                  itemType: this._structure.CommonDataItems.Gender,
                },
              },
            ],
          };
          this.state.isEmployee
            ? (employeeSetDto = {
                employeecode: peopleData.employeecode,
                personId: peopleData.personid ? peopleData.personid : null,
                email: peopleData.email,
                isApproved: true,
                isActive: true,
                tenantId: this.token.tenant,
                contract: [
                  {
                    name:
                      'EC' + Common.formatDate(new Date(), 'ddhhmmss') + '-01',
                    dateStart: Common.RemoveTimezoneOffsetFromDate(
                      new Date(peopleData.appointmentdate)
                    ),
                    commonDataId: peopleData.contractid,
                    salary: peopleData.scale ? peopleData.scale : 0,
                    jobId: peopleData.jobId ? peopleData.jobId : null,
                    branchId: peopleData.branchId ? peopleData.branchId : null,
                    departmentId: peopleData.departmentId
                      ? peopleData.departmentId
                      : null,
                  },
                ],
                Employeeshift: [{}],
              })
            : (employeeSetDto = null);
        } else {
          personSetDto = {
            id: peopleData.id,
            firstname: peopleData.firstname,
            lastname: peopleData.lastname,
            middlename: peopleData.middlename ? peopleData.middlename : '',
            birthdate: peopleData.birthdate,
            birthplace: peopleData.birthplace ? peopleData.birthplace : '',
            nationalityid: peopleData.nationalityid
              ? peopleData.nationalityid
              : null,
            bio: [
              {
                commonDataId: peopleData.gender,
                commonData: {
                  itemType: this._structure.CommonDataItems.Gender,
                },
              },
            ],
            email: peopleData.personalemail,
            mobile: peopleData.mobileno,
          };

          if (peopleData.religion) {
            personSetDto.bio.push({
              commonDataId: peopleData.religion,
              commonData: {
                itemType: this._structure.CommonDataItems.Religion,
              },
            });
          }

          if (peopleData.maritalstatus) {
            personSetDto.bio.push({
              commonDataId: peopleData.maritalstatus,
              commonData: {
                itemType: this._structure.CommonDataItems.MaritalStatus,
              },
            });
          }

          this.state.isEmployee
            ? (employeeSetDto = {
                id: peopleData.employeeId,
                employeecode: peopleData.employeecode,
                personId: peopleData.personId ? peopleData.personid : null,
                email: peopleData.email,
                isApproved: true,
                isActive: true,
                tenantId: this.token.tenant,
                contract: [
                  {
                    name:
                      'EC' + Common.formatDate(new Date(), 'ddhhmmss') + '-01',
                    dateStart: Common.RemoveTimezoneOffsetFromDate(
                      new Date(peopleData.appointmentdate)
                    ),
                    commonDataId: peopleData.contractid,
                    salary: peopleData.scale ? peopleData.scale : 0,
                    jobId: peopleData.jobId ? peopleData.jobId : null,
                    branchId: peopleData.branchId ? peopleData.branchId : null,
                    departmentId: peopleData.departmentId
                      ? peopleData.departmentId
                      : null,
                  },
                ],
                Employeeshift: [{}],
              })
            : (employeeSetDto = null);
        }
        let payload: any = {
          person: personSetDto,
          employee: employeeSetDto,
        };

        this.setSelector(
          this.props.selectorService,
          payload,
          peopleData.id ? true : false
        );
        break;
      case this._structure.SidebarConstants.SidebarService.Correspondence.Id:
        let correspondanceSetDto: any = null;
        correspondanceSetDto = {
          account: { id: correspondanceData.accountid },
          dossiertype: correspondanceData.dossier,
          senderId: correspondanceData.senderid,
          recipientId: correspondanceData.recipientid,
          date: Common.RemoveTimezoneOffsetFromDate(
            new Date(correspondanceData['correspondence.date'])
          ),
          subject: correspondanceData['correspondence.subject'],
          protocol: correspondanceData['correspondence.protocol'],
        };
        this.setSelector(this.props.selectorService, correspondanceSetDto);
        break;
      default:
        break;
    }
  }

  private onCancelClick = (args: any) => {
    if (this.props.handleCancelClick) this.props.handleCancelClick(args);
  };

  private dialogTemplate() {
    const onChange = (args: any) => {
      if (args.target !== undefined) {
        if (args.target.value != null) {
          let peopleData: any = this.state.peopleData;
          peopleData[(args.target as HTMLInputElement).name] =
            args.target.value;
          this.setState({
            peopleData: peopleData,
          });
        }
      }
    };

    const onChangeCorres = (args: any) => {
      if (args.target !== undefined) {
        if (args.target.value != null) {
          let corresData: any = this.state.correspondenceData;
          corresData[(args.target as HTMLInputElement).name] =
            args.target.value;
          this.setState({
            correspondenceData: corresData,
          });
        }
      }
    };
    const dialogTemplateProfileInfo = (data: any) => {
      const handleGenderChange = (e: any) => {
        if (this.listGender === null) {
          return;
        }
        let peopleData: any = this.state.peopleData;
        peopleData['gender'] = this.listGender.value;
        this.setState({ peopleData: peopleData });
      };
      const handleNationalityChange = (e: any) => {
        if (this.listNationality === null) {
          return;
        }
        let peopleData: any = this.state.peopleData;
        peopleData['nationalityid'] = this.listNationality.value;
        this.setState({ peopleData: peopleData });
      };
      const handleReligionChange = (e: any) => {
        if (this.listReligion == null) {
          return;
        }
        let peopleData: any = this.state.peopleData;
        peopleData['religion'] = this.listReligion.value;
        this.setState({ peopleData: peopleData });
      };
      const handleMaritalStatusChange = (e: any) => {
        if (this.listMaritalStatus == null) {
          return;
        }
        let peopleData: any = this.state.peopleData;
        peopleData['maritalstatus'] = this.listMaritalStatus.value;
        this.setState({ peopleData: peopleData });
      };
      return (
        <>
          <div className='row align-items-center'>
            <div className='col-6 py-2'>
              <TextBoxComponent
                className='textbox-class'
                id='firstname'
                name='firstname'
                type='text'
                floatLabelType='Auto'
                value={data.firstname!}
                onChange={onChange}
                autocomplete='off'
                placeholder='Enter First Name *'
                style={{ fontSize: '15px' }}
                showClearButton={true}
              />
            </div>
            <div className='col-6 py-2'>
              <TextBoxComponent
                className='textbox-class'
                id='lastname'
                name='lastname'
                type='text'
                floatLabelType='Auto'
                value={data.lastname!}
                onChange={onChange}
                placeholder='Enter Last Name *'
                autocomplete='off'
                style={{ fontSize: '15px' }}
              />
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-6 py-2'>
              <TextBoxComponent
                className='textbox-class'
                id='middlename'
                floatLabelType='Auto'
                name='middlename'
                type='text'
                value={data.middlename!}
                onChange={onChange}
                placeholder='Enter Middle Name'
                autocomplete='off'
                style={{ fontSize: '15px' }}
              />
            </div>
            <div className='col-6 py-2'>
              <DatePickerComponent
                id='birthdate'
                className='textbox-class'
                name='birthdate'
                //onFocus={onFocusFunction}
                format={this.user.settings.formats.DateShort}
                value={data.birthdate!}
                showClearButton={false}
                cssClass='e-hidedate-icon'
                openOnFocus={true}
                showTodayButton={false}
                floatLabelType='Auto'
                onChange={onChange}
                // placeholder={this.user.settings.formats.DateShort}
                placeholder='Birth date *'
                style={{ fontSize: '15px' }}
              />
            </div>
          </div>
          <div className='row align-items-center'>
            {data.isAdd ? (
              <>
                <div className='col-12 py-2'>
                  <DropDownListComponent
                    id='gender'
                    className='textbox-class'
                    //onFocus={onFocusFunction}
                    dataSource={this.user.commondata.filter(
                      (g: any) =>
                        g.itemType == this._structure.CommonDataItems.Gender
                    )}
                    ref={(scope) => {
                      this.listGender = scope;
                    }}
                    floatLabelType='Auto'
                    filterBarPlaceholder='Gender'
                    allowFiltering={true}
                    filterType='Contains'
                    fields={this.gfields}
                    change={handleGenderChange}
                    placeholder='Select Gender *'
                    popupHeight='220px'
                    style={{ fontSize: '15px' }}
                    value={data.gender}
                  />
                </div>
              </>
            ) : (
              <>
                <div className='col-6 py-2'>
                  <TextBoxComponent
                    className='textbox-class'
                    id='birthplace'
                    floatLabelType='Auto'
                    name='birthplace'
                    type='text'
                    value={data.birthplace!}
                    onChange={onChange}
                    placeholder='Enter Birth Place'
                    autocomplete='off'
                    style={{ fontSize: '15px' }}
                  />
                </div>
                <div className='col-6 py-2'>
                  <DropDownListComponent
                    id='gender'
                    className='textbox-class'
                    //onFocus={onFocusFunction}
                    dataSource={this.user.commondata.filter(
                      (g: any) =>
                        g.itemType == this._structure.CommonDataItems.Gender
                    )}
                    ref={(scope) => {
                      this.listGender = scope;
                    }}
                    floatLabelType='Auto'
                    filterBarPlaceholder='Gender'
                    allowFiltering={true}
                    filterType='Contains'
                    fields={this.gfields}
                    change={handleGenderChange}
                    placeholder='Select Gender *'
                    popupHeight='220px'
                    style={{ fontSize: '15px' }}
                    value={data.gender}
                  />
                </div>
              </>
            )}
          </div>
          {!data.isAdd ? (
            <>
              <div className='row align-items-center'>
                <div className='col-12 py-2'>
                  <DropDownListComponent
                    id='nationalityid'
                    className='textbox-class'
                    dataSource={this.objNationality}
                    ref={(scope) => {
                      this.listNationality = scope;
                    }}
                    value={data.nationalityid}
                    floatLabelType='Auto'
                    filterBarPlaceholder='Search Nationality'
                    allowFiltering={true}
                    filterType='Contains'
                    fields={this.fields}
                    onChange={handleNationalityChange}
                    placeholder='Select Nationality'
                    popupHeight='220px'
                    style={{ fontSize: '15px' }}
                    showClearButton={true}
                  />
                </div>
              </div>
              <div className='row align-items-center'>
                <div className='col-6 py-2'>
                  <DropDownListComponent
                    id='religion'
                    className='textbox-class'
                    dataSource={this.user.commondata.filter(
                      (r: any) =>
                        r.itemType == this._structure.CommonDataItems.Religion
                    )}
                    ref={(sc) => {
                      this.listReligion = sc;
                    }}
                    value={data.religion}
                    popupHeight='220px'
                    floatLabelType='Auto'
                    filterBarPlaceholder='Religion'
                    fields={this.rfields}
                    placeholder='Select Religion'
                    allowFiltering={true}
                    filterType='Contains'
                    style={{
                      fontSize: '15px',
                    }}
                    onChange={handleReligionChange}
                  />
                </div>
                <div className='col-6 py-2'>
                  <DropDownListComponent
                    id='maritalstatus'
                    className='textbox-class'
                    dataSource={this.user.commondata.filter(
                      (m: any) =>
                        m.itemType ==
                        this._structure.CommonDataItems.MaritalStatus
                    )}
                    ref={(sc) => {
                      this.listMaritalStatus = sc;
                    }}
                    popupHeight='220px'
                    floatLabelType='Auto'
                    filterBarPlaceholder='Marital Status'
                    fields={this.mfields}
                    placeholder='Select Marital Status'
                    allowFiltering={true}
                    filterType='Contains'
                    style={{
                      fontSize: '15px',
                    }}
                    value={data.maritalstatus}
                    onChange={handleMaritalStatusChange}
                  />
                </div>
              </div>
              <div className='row align-items-center'>
                <div className='col-6 py-2'>
                  <TextBoxComponent
                    id='personalemail'
                    className='textbox-class'
                    //onFocus={onFocusFunction}
                    name='personalemail'
                    floatLabelType='Auto'
                    type='email'
                    placeholder='Enter email'
                    autocomplete='off'
                    style={{ padding: '8px 0px' }}
                    value={data.personalemail!}
                    onChange={onChange}
                  />
                </div>
                <div className='col-6 py-2'>
                  <TextBoxComponent
                    id='mobile'
                    className='textbox-class'
                    //onFocus={onFocusFunction}
                    name='mobileno'
                    floatLabelType='Auto'
                    type='text'
                    placeholder='Enter Mobile No'
                    autocomplete='off'
                    style={{ padding: '8px 0px' }}
                    value={data.mobileno!}
                    onChange={onChange}
                  />
                </div>
              </div>
            </>
          ) : (
            ''
          )}
        </>
      );
    };
    const dialogTemplateEmployeeInfo = (data: any) => {
      this.contract_type = this.GetContractType();
      let peopleData: any = this.state.peopleData;
      const dfields = {
        dataSource: this.jsondepatment,
        value: 'id',
        text: 'name',
        child: 'subDepartments',
        selectable: 'selectable',
      };
      const bfields = {
        dataSource: this.jsonbranch,
        value: 'id',
        text: 'name',
        child: 'subBranchs',
        selectable: 'selectable',
      };
      const jfields = {
        dataSource: this.jsonjob,
        value: 'id',
        text: 'name',
        child: 'subJob',
        selectable: 'selectable',
      };
      const handleContractChange = (e: any) => {
        this.sCombobox = 'ContractType';
        if (this.listContract === null) {
          return;
        }
        peopleData['contractid'] = this.listContract.value;
        if (parseInt(e.itemData.id) == -999) {
          peopleData['contract_type'] = '';
          this.setState({
            peopleData: peopleData,
            showDialog: true,
            showDialogContractType: true,
            showDialogDepartment: false,
            showDialogBranch: false,
            showDialogJob: false,
            showDialogCompany: false,
          });
        } else {
          this.setState({
            peopleData: peopleData,
          });
        }
      };
      const handleJobChange = (e: any) => {
        this.sCombobox = 'Job';
        if (this.listJob === null) {
          return;
        }
        peopleData['jobId'] = this.listJob.value[0];
        if (parseInt(e.itemData.id) == -999) {
          peopleData['job'] = '';
          this.setState({
            peopleData: peopleData,
            showDialog: true,
            showDialogJob: true,
            showDialogContractType: false,
            showDialogDepartment: false,
            showDialogBranch: false,
            showDialogCompany: false,
          });
        } else {
          this.setState({
            peopleData: peopleData,
          });
        }
      };
      const handleBranchChange = (e: any) => {
        this.sCombobox = 'Branch';
        if (this.listBranch === null) {
          return;
        }
        peopleData['branchId'] = this.listBranch.value[0];
        if (parseInt(e.itemData.id) == -999) {
          peopleData['branch'] = '';
          this.setState({
            peopleData: peopleData,
            showDialog: true,
            showDialogBranch: true,
            showDialogContractType: false,
            showDialogDepartment: false,
            showDialogJob: false,
            showDialogCompany: false,
          });
        } else {
          this.setState({
            peopleData: peopleData,
          });
        }
      };
      const handleDepartmentChange = (e: any) => {
        this.sCombobox = 'Department';
        if (this.listDepartment === null) {
          return;
        }
        peopleData['departmentId'] = this.listDepartment.value[0];
        if (parseInt(e.itemData.id) == -999) {
          peopleData['department'] = '';
          this.setState({
            peopleData: peopleData,
            showDialog: true,
            showDialogDepartment: true,
            showDialogContractType: false,
            showDialogBranch: false,
            showDialogJob: false,
            showDialogCompany: false,
          });
        } else {
          this.setState({
            peopleData: peopleData,
          });
        }
      };
      return (
        <>
          <div className='row align-items-center'>
            <div className='col-6 py-2'>
              <TextBoxComponent
                id='employeecode'
                name='employeecode'
                className='textbox-class'
                ref={(scope) => {
                  this.listObj = scope;
                }}
                //onFocus={onFocusFunction}
                floatLabelType='Auto'
                type='text'
                placeholder='Enter employee code *'
                style={{ padding: '8px 0px' }}
                autocomplete='off'
                value={data.employeecode!}
                onChange={onChange}
              />
            </div>
            <div className='col-6 py-2'>
              <DatePickerComponent
                id='appointmentdate'
                className='textbox-class'
                name='appointmentdate'
                format={this.user.settings.formats.DateShort}
                onChange={onChange}
                value={data.appointmentdate!}
                floatLabelType='Auto'
                showClearButton={false}
                cssClass='e-hidedate-icon'
                openOnFocus={true}
                showTodayButton={false}
                placeholder='Appointment Date *'
                style={{ fontSize: '15px' }}
              />
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-6 py-2'>
              <DropDownListComponent
                id='contractid'
                className='textbox-class'
                name='contractid'
                dataSource={this.contract_type}
                ref={(scope) => {
                  this.listContract = scope;
                }}
                filterBarPlaceholder='Search a Contract'
                allowFiltering={true}
                filterType='Contains'
                floatLabelType='Auto'
                fields={this.cfields}
                change={handleContractChange}
                placeholder='Select Contract *'
                popupHeight='220px'
                style={{ fontSize: '15px' }}
                value={data.contractid!}
              ></DropDownListComponent>
            </div>
            <div className='col-6 py-2'>
              <TextBoxComponent
                id='email'
                className='textbox-class'
                //onFocus={onFocusFunction}
                name='email'
                floatLabelType='Auto'
                type='email'
                placeholder='Enter email'
                autocomplete='off'
                style={{ padding: '8px 0px' }}
                onChange={onChange}
                value={data.email ? data.email : data.personEmail}
              />
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-6 py-2'>
              <DropDownTreeComponent
                id='jobid'
                name='jobId'
                className='textbox-class'
                //onFocus={onFocusFunction}
                treeSettings={{ expandOn: 'Click' }}
                fields={jfields}
                floatLabelType='Auto'
                ref={(scope) => {
                  this.listJob = scope;
                }}
                filterBarPlaceholder='Search a Job'
                allowFiltering={true}
                filterType='Contains'
                select={handleJobChange}
                placeholder='Select Job'
                popupHeight='220px'
                style={{ fontSize: '15px' }}
                value={data.jobId!}
              />
            </div>
            <div className='col-6 py-2'>
              <DropDownTreeComponent
                id='departmentid'
                className='textbox-class'
                //onFocus={onFocusFunction}
                name='departmentId'
                floatLabelType='Auto'
                ref={(scope) => {
                  this.listDepartment = scope;
                }}
                treeSettings={{ expandOn: 'Click' }}
                fields={dfields}
                filterBarPlaceholder='Search a Department'
                allowFiltering={true}
                filterType='Contains'
                select={handleDepartmentChange}
                placeholder='Select Department'
                popupHeight='220px'
                style={{ fontSize: '15px' }}
                value={data.departmentId!}
              ></DropDownTreeComponent>
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-6 py-2'>
              <NumericTextBoxComponent
                id='scale'
                name='scale'
                className='textbox-class'
                //onFocus={onFocusFunction}
                floatLabelType='Auto'
                placeholder='Salary'
                decimals={
                  this.user.settings.formats.Decimal.includes('.')
                    ? this.user.settings.formats.Decimal.split('.').pop().length
                    : null
                }
                format={`${this.user.currencySign} 0.${
                  this.user.settings.formats.Decimal.includes('.')
                    ? '0'.repeat(
                        this.user.settings.formats.Decimal.split('.').pop()
                          .length
                      )
                    : '0'.repeat(2)
                }`}
                showSpinButton={false}
                onChange={onChange}
                created={(e: any) => {
                  document
                    .querySelector('#scale')
                    ?.addEventListener('paste', (e: any) => {
                      e.target.ej2_instances[0].value = e.clipboardData
                        .getData('Text')
                        .replaceAll(',', '');
                    });
                }}
                value={data.scale!}
              />
            </div>
            <div className='col-6 py-2'>
              <DropDownTreeComponent
                id='branchid'
                className='textbox-class'
                //onFocus={onFocusFunction}
                floatLabelType='Auto'
                name='branchId'
                treeSettings={{ expandOn: 'Click' }}
                fields={bfields}
                ref={(scope) => {
                  this.listBranch = scope;
                }}
                filterBarPlaceholder='Search a Branch'
                allowFiltering={true}
                filterType='Contains'
                select={handleBranchChange}
                placeholder='Select Branch'
                popupHeight='220px'
                style={{ fontSize: '15px' }}
                value={data.branchId!}
              ></DropDownTreeComponent>
            </div>
          </div>
        </>
      );
    };
    const handleOnChange = (args: any) => {
      this.setState({ isEmployee: args.target.checked });
      this.tabRef.items[1].visible = this.state.isEmployee;
    };
    const tabCreated = (e: any) => {
      let dataPeople = this.state.peopleData;
      let itemsData: Object[] = [];
      itemsData.push({
        header: {
          text: '<i class="fa-regular fa-circle-user tabicon"></i><span>Profile Information</span><i class="fa-regular fa-check"></i>',
        },
        content: () => dialogTemplateProfileInfo(dataPeople),
      });
      itemsData.push({
        header: {
          text: '<i class="fa-regular fa-user-tie tabicon"></i><span>Employment Details</span><i class="fa-regular fa-check"></i>',
        },
        content: () => dialogTemplateEmployeeInfo(dataPeople),
        visible: this.state.isEmployee,
      });
      this.tabRef.items = itemsData;
    };
    const dialogTemplatePeople = (data: any) => {
      return (
        <>
          <div style={{ width: '43vw', height: '100%' }}>
            <TabComponent
              id='peopleAddEditDialogTab'
              ref={(obj: any) => (this.tabRef = obj)}
              created={tabCreated}
            ></TabComponent>
            <div className='e-footer-content'>
              <div className='switch-employee'>
                <SwitchComponent
                  id='makeEmployee'
                  name='makeEmployee'
                  checked={this.state.isEmployee}
                  onChange={handleOnChange}
                />
                <label>Make an Employee</label>
              </div>
              <div>
                <button
                  id='btnCancel'
                  className='e-control e-btn e-lib flat-button e-flat'
                  onClick={this.onCancelClick.bind(this)}
                >
                  Cancel
                </button>
                <button
                  id='btnAccept'
                  className='e-control e-btn e-lib flat-button e-primary e-flat'
                  onClick={this.saveClick.bind(this)}
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        </>
      );
    };
    const dialogTemplateCorrespondence = (data: any) => {
      let dataCorres: any = this.state.correspondenceData;
      if (data['correspondence.subject'] === undefined) {
        data['correspondence.subject'] = data.accountname;
      }
      let fields = {
        dataSource: data.jsonpersoncompany,
        value: 'id',
        text: 'name',
        parentValue: 'parentid',
        hasChildren: 'hasChild',
      };
      let showCheckBox = true;
      let treeSettings: TreeSettingsModel = { expandOn: 'Click' };
      let customTemplate = 'Selected items count: ${value.length} item(s) ';

      const handleDropDownTreeChange = (e: any) => {
        let itemid: string = `#${e.item.id}`;
        if (e.item.id === '') {
          if (e.itemData.parentID === '-1') {
            (document.getElementById('correspondence-sender-id') as any).value =
              e.itemData.id;
            dataCorres['senderid'] = e.itemData.id;
          } else if (e.itemData.parentID === '-2') {
            (
              document.getElementById('correspondence-recipient-id') as any
            ).value = e.itemData.id;
            dataCorres['recipientid'] = e.itemData.id;
          }
          return;
        } else {
          let drp: HTMLSelectElement = document
            .querySelector(itemid)
            ?.parentElement?.closest('.e-treeview') as HTMLSelectElement;

          if (
            Number(e.itemData.id) === -999 ||
            Number(e.itemData.id) === -888
          ) {
            if (Number(e.itemData.id) === -999) {
              this.setState({ showDialog: true });
            } else if (Number(e.itemData.id) === -888) {
              this.setState({ showDialog: true, showDialogCompany: true });
            }
            if (drp.id === this.drpSender.element.id + '_tree') {
              (
                document.getElementById('correspondence-sender-id') as any
              ).value = null;
              dataCorres['senderid'] = null;
            } else if (drp.id === this.drpRecipient.element.id + '_tree') {
              (
                document.getElementById('correspondence-recipient-id') as any
              ).value = null;
              dataCorres['recipientid'] = null;
            }
            return;
          } else if (
            Number(e.itemData.id) === -1 ||
            Number(e.itemData.id) === -2
          ) {
            if (Number(e.itemData.id) === -1) {
              this.drpSender.showPopup();
            } else if (Number(e.itemData.id) === -2) {
              this.drpRecipient.showPopup();
            }
          } else {
            if (drp.id === this.drpSender.element.id + '_tree') {
              if (
                (document.getElementById('correspondence-recipient-id') as any)
                  .value === e.itemData.id
              ) {
                (
                  document.getElementById('correspondence-sender-id') as any
                ).value = null;
                dataCorres['senderid'] = null;
                (
                  document.querySelector(
                    '#correspondence-sender-id.e-dropdowntree'
                  ) as any
                ).ej2_instances[0].value = null;
                return;
              }
              (
                document.getElementById('correspondence-sender-id') as any
              ).value = e.itemData.id;
              dataCorres['senderid'] = e.itemData.id;
            } else if (drp.id === this.drpRecipient.element.id + '_tree') {
              if (
                (document.getElementById('correspondence-sender-id') as any)
                  .value === e.itemData.id
              ) {
                (
                  document.getElementById('correspondence-recipient-id') as any
                ).value = null;
                dataCorres['recipientid'] = null;
                (
                  document.querySelector(
                    '#correspondence-recipient-id.e-dropdowntree'
                  ) as any
                ).ej2_instances[0].value = null;
                return;
              }
              (
                document.getElementById('correspondence-recipient-id') as any
              ).value = e.itemData.id;
              dataCorres['recipientid'] = e.itemData.id;
            }
          }
        }
      };

      const handleDataBoundSender = (e: any) => {
        if (this.drpSender && this.drpSender.value.length <= 0) {
          setTimeout(() => {
            let s: string[] = [Common.parseJwt(Common.getToken()!).tenant];
            this.drpSender.value = s;
            this.drpSender.ensureVisible(s[0]);
          }, 500);
        }
      };

      return (
        <div style={{ width: '38vw', height: '100%' }}>
          <div>
            <input
              id='correspondence-accountid'
              name='correspondence.accountid'
              type='hidden'
              value={data.accountid!}
              onChange={onChangeCorres}
            />
            <input
              id='correspondence-sender-id'
              name='correspondence.sender.id'
              type='hidden'
              autoComplete='off'
              onChange={onChangeCorres}
            />
            <input
              id='correspondence-recipient-id'
              name='correspondence.recipient.id'
              type='hidden'
              autoComplete='off'
              onChange={onChangeCorres}
            />
            <div className='row align-items-center'>
              <div className='col-3 py-2'>
                <label>Sender *</label>
              </div>
              <div className='col-9 py-2'>
                <DropDownTreeComponent
                  id='correspondence-sender-id'
                  name='correspondence.sender.id'
                  allowFiltering={true}
                  filterType='Contains'
                  fields={fields}
                  treeSettings={treeSettings}
                  mode='Custom'
                  placeholder='Select a sender'
                  popupHeight='250px'
                  select={handleDropDownTreeChange}
                  ref={(obj: any) => (this.drpSender = obj)}
                  style={{ fontSize: '15px' }}
                  dataBound={handleDataBoundSender}
                />
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-3 py-2'>
                <label>Recipient *</label>
              </div>
              <div className='col-9 py-2'>
                <DropDownTreeComponent
                  id='correspondence-recipient-id'
                  name='correspondence.recipient.id'
                  allowFiltering={true}
                  filterType='Contains'
                  fields={fields}
                  treeSettings={treeSettings}
                  customTemplate={customTemplate}
                  mode='Custom'
                  placeholder='Select a recipient'
                  popupHeight='250px'
                  select={handleDropDownTreeChange}
                  ref={(obj: any) => (this.drpRecipient = obj)}
                  style={{ fontSize: '15px' }}
                />
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-3 py-2'>
                <label>Date *</label>
              </div>
              <div className='col-9 py-2'>
                <DatePickerComponent
                  id='correspondencedate'
                  name='correspondence.date'
                  disabled={!data.isAdd}
                  value={data.correspondencedate}
                  format={this.user.settings.formats.DateShort}
                  showClearButton={false}
                  cssClass='e-hidedate-icon'
                  onChange={onChangeCorres}
                  openOnFocus={true}
                  showTodayButton={false}
                  placeholder='Select Date'
                  style={{ fontSize: '15px' }}
                />
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-3 py-2'>
                <label>Subject *</label>
              </div>
              <div className='col-9 py-2'>
                <input
                  id='correspondence-subject'
                  name='correspondence.subject'
                  type='text'
                  className='input-text-border'
                  disabled={!data.isAdd}
                  value={data['correspondence.subject']!}
                  autoComplete='off'
                  onChange={onChangeCorres}
                  required={true}
                  placeholder='Enter Subject'
                  style={{ fontSize: '15px' }}
                />
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-3 py-2'>
                <label>Protocol</label>
              </div>
              <div className='col-9 py-2'>
                <input
                  id='correspondence-protocol'
                  name='correspondence.protocol'
                  type='text'
                  className='input-text-border'
                  disabled={!data.isAdd}
                  value={data['correspondence.protocol']}
                  autoComplete='off'
                  onChange={onChangeCorres}
                  placeholder='Enter Protocol'
                  style={{ fontSize: '15px' }}
                />
              </div>
            </div>
          </div>
          <div className='correspondence e-footer-content'>
            <div>
              <button
                id='btnCancel'
                className='e-control e-btn e-lib flat-button e-flat'
                onClick={this.onCancelClick.bind(this)}
              >
                Cancel
              </button>
              <button
                id='btnAccept'
                className='e-control e-btn e-lib flat-button e-primary e-flat'
                onClick={this.saveClick.bind(this)}
              >
                Accept
              </button>
            </div>
          </div>
        </div>
      );
    };

    switch (this.props.selectorService.Id) {
      case this._structure.SidebarConstants.SidebarService.People.Id:
        let dataPeople = this.state.peopleData;
        return <>{dialogTemplatePeople(dataPeople)}</>;
        break;
      case this._structure.SidebarConstants.SidebarService.Correspondence.Id:
        let dataCorres = this.state.correspondenceData;
        return <>{dialogTemplateCorrespondence(dataCorres)}</>;
        break;
      default:
        return <></>;
        break;
    }
  }
  render() {
    return (
      <>
        {this.dialogTemplate()}
        {this.state.showDialog === true ? (
          <DialogComponent
            id='defalutDialogEmployeeData'
            showCloseIcon={true}
            visible={this.state.showDialog}
            width={'25vw'}
            close={this.CloseDialog.bind(this)}
            content={
              this.sCombobox === 'ContractType' &&
              this.state.showDialogContractType === true
                ? this.dialogContentContractType.bind(this)
                : this.sCombobox === 'Job' && this.state.showDialogJob === true
                ? this.dialogContentJob.bind(this)
                : this.sCombobox === 'Branch' &&
                  this.state.showDialogBranch === true
                ? this.dialogContentBranch.bind(this)
                : this.sCombobox === 'Department' &&
                  this.state.showDialogDepartment === true
                ? this.dialogContentDepartment.bind(this)
                : this.state.showDialogCompany === true
                ? this.dialogContentCompany.bind(this)
                : ''
            }
            isModal={true}
            header={
              this.sCombobox === 'ContractType' &&
              this.state.showDialogContractType === true
                ? 'New Contract Type'
                : this.sCombobox === 'Job' && this.state.showDialogJob === true
                ? 'New Job'
                : this.sCombobox === 'Branch' &&
                  this.state.showDialogBranch === true
                ? 'New Branch'
                : this.sCombobox === 'Department' &&
                  this.state.showDialogDepartment === true
                ? 'New Department'
                : this.state.showDialogCompany === true
                ? 'New Company'
                : this.extradialogheader
            }
            buttons={this.extradialogbuttons}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null}
      </>
    );
  }
}
