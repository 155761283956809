import React, { Component, createRef } from 'react';
import { createElement } from '@syncfusion/ej2-base';
import { ImageEditorComponent } from '@syncfusion/ej2-react-image-editor';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import Common from '../Util/Common';

interface ProfilePictureProps {
  imgSrc?: any;
  showImgDialog?: boolean;
  jsonDataViewer?: any;
  getFinalImage?: any;
}

interface ProfilePictureState {
  imgSrc: string;
  showSecondButton: boolean;
  showRemainingButtons: boolean;
  showImgDialog: boolean;
}
const { REACT_APP_ENDPOINT_FOXSYSTEMSERVICE, REACT_APP_ENDPOINT_CORESERVICE } =
  process.env;

export class ImageEditorSync extends Component<
  ProfilePictureProps,
  ProfilePictureState
> {
  private _structure: any;
  fileInputRef = createRef<HTMLInputElement>();
  private user: any;
  private token: any = null;
  dialogInstance: DialogComponent | null = null;
  imageEditorInstance: ImageEditorComponent | null = null;
  animationSettings: any = { effect: 'None' };
  zoomSettings: any = { maxZoomFactor: 30, minZoomFactor: 0.1 };
  private zoomLevel: number = 1;
  constructor(props: ProfilePictureProps) {
    super(props);
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.user = JSON.parse(Common.getUser() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
    this.state = {
      showImgDialog: props.showImgDialog || false,
      imgSrc: props.imgSrc || '',
      showSecondButton: false,
      showRemainingButtons: false,
    };
  }

  componentDidUpdate(prevProps: ProfilePictureProps) {
    if (prevProps.imgSrc !== this.props.imgSrc && this.imageEditorInstance) {
      this.imageEditorInstance.open(this.props.imgSrc || '');
    }
  }

  contentTemplate() {
    return (
      <ImageEditorComponent
        ref={(img) => (this.imageEditorInstance = img)}
        toolbar={[]}
        fileOpened={this.fileOpened.bind(this)}
        created={this.created.bind(this)}
      />
    );
  }

  fileOpened() {
    if (this.imageEditorInstance) {
      this.imageEditorInstance.select('custom');
    }
  }

  created() {
    if (this.imageEditorInstance) {
      const editorInstance: any = this.imageEditorInstance;

      if (editorInstance['disableZoomOutBtn']) {
        const originalDisableZoomOutBtn = editorInstance['disableZoomOutBtn'];

        editorInstance['disableZoomOutBtn'] = function (...args: any) {
          try {
            originalDisableZoomOutBtn.apply(this, args);
          } catch (error) {
            console.warn(
              'ZoomOut button not available in the DOM or skipped due to error.'
            );
          }
        };
      }
    }
  }

  dlgCancelButtonClick(): void {
    if (this.dialogInstance) {
      this.dialogInstance.hide();
    }
  }
  dlgZoomInButtonClick(): void {
    if (this.zoomLevel < 1) {
      this.zoomLevel += 0.1;
    } else {
      this.zoomLevel += 1;
    }

    const maxZoom = this.zoomSettings.maxZoomFactor;
    if (this.zoomLevel > maxZoom) {
      this.zoomLevel = maxZoom;
    }

    if (this.imageEditorInstance) {
      try {
        this.imageEditorInstance.zoom(this.zoomLevel);
      } catch (error) {
        console.error('Error during zoom in:', error);
      }
    }
  }

  dlgZoomOutButtonClick(): void {
    const minZoom = this.zoomSettings.minZoomFactor;

    if (this.zoomLevel <= 1) {
      this.zoomLevel -= 0.1;
    } else {
      this.zoomLevel -= 1;
    }

    if (this.zoomLevel < minZoom) {
      this.zoomLevel = minZoom;
    }

    if (this.imageEditorInstance) {
      try {
        this.imageEditorInstance.zoom(this.zoomLevel);
      } catch (error) {
        console.error('Error during zoom out:', error);
      }
    }
  }
  beginButtons: any[] = [
    {
      click: this.dlgOpenButtonClick.bind(this),
      buttonModel: {
        content: 'Change',
        cssClass: 'imgEditorbtns custom-img-change',
      },
    },
    {
      click: this.dlgOpenButtonClick.bind(this),
      buttonModel: {
        iconCss: 'fa fa-folder-open',
        cssClass: 'imgEditorbtns custom-img-open',
        title: 'Choose File',
      },
    },
    {
      click: this.dlgResetButtonClick.bind(this),
      buttonModel: {
        iconCss: 'fa fa-power-off',
        cssClass: 'imgEditorbtns custom-img-reset',
        title: 'Reset',
      },
    },
    {
      click: this.dlgRotateButtonClick.bind(this),
      buttonModel: {
        iconCss: 'fa fa-sync-alt',
        cssClass: 'imgEditorbtns custom-img-rotate',
        title: 'Rotate',
      },
    },
    {
      click: this.dlgZoomInButtonClick.bind(this),
      buttonModel: {
        iconCss: 'fa fa-search-plus',
        cssClass: 'imgEditorbtns custom-img-zoomin',
        title: 'Zoom In',
      },
    },
    {
      click: this.dlgZoomOutButtonClick.bind(this),
      buttonModel: {
        iconCss: 'fa fa-search-minus',
        cssClass: 'imgEditorbtns custom-img-zoomout',
        title: 'Zoom Out',
      },
    },
  ];

  handleFirstButtonClick = () => {
    this.beginButtons[0].click();
    this.setState({ showRemainingButtons: true });
  };

  dlgDoneButtonClick = () => {
    if (this.imageEditorInstance) {
      this.imageEditorInstance.crop();
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const croppedData = this.imageEditorInstance.getImageData();

      if (context && croppedData) {
        canvas.width = croppedData.width;
        canvas.height = croppedData.height;
        context.putImageData(croppedData, 0, 0);

        const imageDataURL = canvas.toDataURL('image/jpeg', 1);
        const payload = {
          Id: this.props.jsonDataViewer.id,
          Image: imageDataURL.replace('data:', '').replace(/^.+,/, ''),
        };

        this.savePersonImage(payload);
      }

      this.dialogInstance?.hide();
      this.setState({ showRemainingButtons: false });
    } else {
      console.error('Image editor instance is not initialized.');
    }
  };

  savePersonImage = (payload: any) => {
    if (payload) {
      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/persons/update${
          !this.user.isManager ? '/ess' : ''
        }`,
        payload,
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((resopnse: any) => {
          if (
            this.user.person &&
            this.user.person.id === this.props.jsonDataViewer.id
          ) {
            this.user.person = resopnse.data;
            Common.setItem('user', this.user);
          }
          this.props.getFinalImage({
            image: payload.Image,
          });
          return resopnse.data;
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  };

  lastButtons: any[] = [
    {
      click: this.dlgDoneButtonClick.bind(this),
      buttonModel: {
        content: 'Set As Profile',
        cssClass: 'imgEditorbtns custom-img-apply',
        isPrimary: true,
      },
    },
    {
      click: this.dlgCancelButtonClick.bind(this),
      buttonModel: {
        content: 'Cancel',
        cssClass: 'imgEditorbtns custom-img-cancel',
      },
    },
  ];
  dlgOpenButtonClick(): void {
    if (this.fileInputRef.current) {
      this.fileInputRef.current.click();
    } else {
      console.error('File input reference is not available.');
    }

    this.setState((prevState: any) => ({
      showSecondButton: !prevState.showSecondButton,
    }));
  }
  dlgResetButtonClick(): void {
    if (this.imageEditorInstance) {
      this.imageEditorInstance.reset();
    }
  }
  dlgRotateButtonClick(): void {
    if (this.imageEditorInstance) {
      this.imageEditorInstance.rotate(-90);
    }
  }
  fileChanged(args: React.ChangeEvent<HTMLInputElement>): void {
    const URL = window.URL;
    const url = URL.createObjectURL(args.target.files![0]);
    if (this.imageEditorInstance) {
      this.imageEditorInstance.open(url.toString());
    }
    if (this.fileInputRef.current) {
      this.fileInputRef.current.value = '';
    }
    this.setState({ imgSrc: url.toString() });
  }

  footerTemplate() {
    return (
      <div className='dialog-footer'>
        <div className='begin-buttons'>
          {!this.state.showRemainingButtons ? (
            <div className='button-container show'>
              <input
                type='file'
                id='img-upload'
                ref={this.fileInputRef}
                style={{ display: 'none' }}
                className='e-custom-file'
                onChange={this.fileChanged.bind(this)}
                accept='image/*'
              />
              <ButtonComponent
                {...this.beginButtons[0].buttonModel}
                onClick={this.handleFirstButtonClick} // First button click handler
              />
            </div>
          ) : (
            // Render the remaining three buttons when `showRemainingButtons` is true
            this.beginButtons.slice(1).map((button, index) => (
              <div key={index} className='button-container show'>
                <ButtonComponent
                  {...button.buttonModel}
                  onClick={button.click} // Directly invoke button's click handler
                />
              </div>
            ))
          )}
        </div>
        <div className='last-buttons'>
          {this.lastButtons.map((button, index) => (
            <ButtonComponent
              key={index}
              {...button.buttonModel}
              onClick={button.click} // Invoke the respective click handler
            />
          ))}
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.state.showImgDialog && (
          <DialogComponent
            id='profile-dialog'
            showCloseIcon={true}
            visible={this.state.showImgDialog}
            ref={(dialog) => (this.dialogInstance = dialog)}
            width={'25vw'}
            height={'50vh'}
            header='Edit Profile Image'
            content={this.contentTemplate.bind(this)}
            footerTemplate={this.footerTemplate.bind(this)}
            position={{ X: '500', Y: '50' }}
          ></DialogComponent>
        )}
      </>
    );
  }
}
