// * -----------------------------------------------------------------------------------------------------------------1.Meeting

import React, { Component } from 'react';

import Common from '../Util/Common';
import {
  contract_detail_data,
  document_detail_data,
  contract_contextmenu,
  newmenu_groupaccount_contextmenu,
  newmenu_contextmenu,
  contract_cntextmenu,
} from '../dummydata/xml_data_employee';

import { Dialog, DialogComponent } from '@syncfusion/ej2-react-popups';
import './Contract.css';

import {
  AutoComplete,
  DropDownListComponent,
  DropDownTreeComponent,
  TreeSettingsModel,
} from '@syncfusion/ej2-react-dropdowns';

import {
  ContextMenuComponent,
  MenuItem,
  MenuItemModel,
  TreeViewComponent,
} from '@syncfusion/ej2-react-navigations';
import DocumentViewer from './Layout/DocumentViewer';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { InputElement } from '@syncfusion/ej2-react-pdfviewer';
import { select } from '@syncfusion/ej2-react-schedule';
import { Refresh } from 'react-ionicons';
import { Data } from '@syncfusion/ej2-react-grids';
import DialogConfirm from './Dialogbox/DialogConfirm';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';

const parser = require('xml2js').Parser({
  explicitRoot: false,
  explicitArray: false,
});

interface props {
  id: string;
  selectorData: any;
  contractData: any;
  inoffcanvas?: boolean;
  showallfields?: boolean;
  mandatoryfields?: string[];
  refreshContract?: any;
  contractTitle: string;
  /**
   * expected value : 'contract' | 'document'
   *
   * @default 'contract'
   */
  showDataFrom: string;
  Selector?: any;
  prevctrenddate?: any;
}
const { REACT_APP_ENDPOINT_CORESERVICE, REACT_APP_ENDPOINT_PAYROLLSERVICE } =
  process.env;

// * -----------------------------------------------------------------------------------------------------------------1.Acknowledge
class Contract extends React.Component<props> {
  private user: any;
  private _structure: any;
  private jsonBranch: any = [];
  private jsonDepartment: any = [];
  private jsonJob: any = [];
  private jsonGrade: any = [];
  private jsonCostCenter: any = [];
  private jsonClass: any = [];
  private jsonSection: any = [];
  private jsonUnit: any = [];
  private jsonTeam: any = [];
  private jsonJobReportTo: any = [];
  private jsonJobBranch: any = [];
  private jsonJobDepartment: any = [];
  private jsonJobClass: any = [];
  private jsonJobSection: any = [];
  private jsonJobUnit: any = [];
  private jsonJobTeam: any = [];
  private jsonJobGrade: any = [];
  private jsonContractType: any = [];
  private token: any = null;
  private treeSettings: TreeSettingsModel = { expandOn: 'Click' };
  private dblclickedctrlid: string = '';
  public contractchanged: boolean = false;

  private dialogHeader: string = '';
  private endpoint: string = '';
  private allocation: string = '';
  private fieldsname: string = '';
  private suballocation: string = '';
  private allocationidfield: string = '';
  private allocfields: any = null;
  private isgroupselected: boolean = false;
  private selectedmenuid: string = '';
  private selectednode: any = null;
  private newitemnode: any = null;
  private treemenuObj: ContextMenuComponent | any = null;
  private treeObj: TreeViewComponent | any = null;
  private ddlTree: DropDownTreeComponent | any = null;
  private txtCode: any = null;
  private txtName: any = null;
  private txtCustomCode: any = null;
  private cntxitems: MenuItemModel[] = [];
  toastObj: any;

  constructor(props: props) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    var xmlContract;
    switch (this.props.showDataFrom) {
      case 'contract':
        parser.parseString(
          contract_detail_data,
          function (err: any, result: any) {
            xmlContract = JSON.parse(JSON.stringify(result));
          }
        );
        break;

      case 'document':
        parser.parseString(
          document_detail_data,
          function (err: any, result: any) {
            xmlContract = JSON.parse(JSON.stringify(result));
          }
        );
        break;

      default:
        break;
    }

    _result = null;
    parser.parseString(contract_cntextmenu, function (err: any, result: any) {
      _result = JSON.parse(JSON.stringify(result));
    });
    this.cntxitems = Common.GetContextMenuFromXML(
      _result.ContextMenu.Item
    ) as MenuItemModel[];

    this.InitializeData();

    var _result: any;
    parser.parseString(
      newmenu_groupaccount_contextmenu,
      function (err: any, result: any) {
        _result = JSON.parse(JSON.stringify(result));
      }
    );
  }

  static defaultProps = {
    inoffcanvas: false,
    mandatoryfields: [],
    contractTitle: 'Contracts Details',
    showDataFrom: 'contract',
  };

  state = {
    refreshpropsvalue: 0,
    //branchid: null,
    bfields: undefined,
    bfieldsjob: undefined,
    //departmentid: null,
    dfields: undefined,
    dfieldsjob: undefined,
    //jobid: null,
    jfields: undefined,
    jfieldsreportto: undefined,
    //gradeid: null,
    gfields: undefined,
    gfieldsjob: undefined,
    //costcenterid: null,
    ccfields: undefined,
    //classid: null,
    cfields: undefined,
    cfieldsjob: undefined,
    //sectionid: null,
    sfields: undefined,
    sfieldsjob: undefined,
    //unitid: null,
    ufields: undefined,
    ufieldsjob: undefined,
    //teamid: null,
    tfields: undefined,
    tfieldsjob: undefined,
    showjobdoc:
      this.props.contractData.job && this.props.contractData.job.id > 0
        ? true
        : false,
    showdialog: false,
    dlgcontent: '',
    allocationlistdialog: false,
    allocationfields: undefined,
    allocationchangedialog: false,
    alloccode: '',
    allocname: '',
    alloccustomcode: '',
    allocdesc: '',
    openperiod: undefined,
    periodclosed: false,
    minDate: new Date(),
    periodlist: [],
    branchfetched: false,
    deptfetched: false,
    jobfetched: false,
    gradefetched: false,
    contextmenuItems: [],
    selectedvalue: undefined,
    tempEndDate: null,
  };

  componentDidMount() {
    if (this.state.openperiod == undefined) {
      this.getopenperiod();
    }

    if (this.state.periodlist.length <= 0) {
      this.FetchPeriod();
    }

    let obj: any = document.getElementById('dtpdateend');
    if (obj) {
      let ddl: DatePickerComponent = obj.ej2_instances[0];
      if (ddl) {
        if (obj.value == '') {
          this.setState({ tempEndDate: null });
        } else {
          this.setState({ tempEndDate: obj.value });
        }
      }
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      this.state.branchfetched === true &&
      this.state.deptfetched === true &&
      this.state.jobfetched === true &&
      this.state.gradefetched == true &&
      prevState.contextmenuItems.length <= 0
    ) {
      this.setState({ contextmenuItems: this.cntxitems });
    }

    // if (
    //   this.dropdownTreeObj !== null &&
    //   prevState.selectedvalue !== this.state.selectedvalue
    // ) {
    //   this.dropdownTreeObj.value = this.state.selectedvalue;
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
    let cchanged: any = this.contractchanged;
    this.SaveContract();

    if (cchanged === false) return;

    this.setState({ tempEndDate: this.props.contractData.dateEnd });

    let emp: any = document.querySelector('#treegrid-selector');
    if (emp) {
      let jsonEmployee = emp.ej2_instances[0].dataSource;

      if (jsonEmployee !== undefined && jsonEmployee.length > 0) {
        if (
          this.props.contractData.dateEnd !== undefined ||
          this.props.contractData.dateEnd == null
        ) {
          if (
            this.props.contractData.dateEnd === null ||
            new Date(this.props.contractData.dateEnd).getTime() >=
              new Date(Date.now()).getTime()
          ) {
            emp.ej2_instances[0].dataSource[
              emp.ej2_instances[0].dataSource.findIndex(
                (x: any) => x.id === this.props.contractData.employeeId
              )
            ].isActive = true;
            emp.ej2_instances[0].refresh();
            // emp.ej2_instances[0].dataSource.filter(
            //   (x: any) => x.isActive === false
            // );

            // let p: any = emp.querySelectorAll(`.e-gridcontent .e-table tr`);

            // //emp.ej2_instances[0].render();
            // //emp.ej2_instances[0].refresh();

            // emp.ej2_instances[0].deleteRow(
            //   p[
            //     emp.ej2_instances[0].dataSource.findIndex(
            //       (x: any) => x.id === this.props.contractData.employeeId
            //     )
            //   ]
            // );
          } else {
            emp.ej2_instances[0].dataSource[
              emp.ej2_instances[0].dataSource.findIndex(
                (x: any) => x.id === this.props.contractData.employeeId
              )
            ].isActive = false;
            emp.ej2_instances[0].refresh();
            // //emp.ej2_instances[0].dataSource =
            // emp.ej2_instances[0].dataSource.filter(
            //   (x: any) => x.isActive === true
            // );

            // let p: any = emp.querySelectorAll(`.e-gridcontent .e-table tr`);

            //emp.ej2_instances[0].render();
            //emp.ej2_instances[0].refresh();
            //}
          }
        }
      }
    }
  }

  private getopenperiod() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/firstperiod`,
      {
        statusId: Number(this._structure.PeriodStatusId.Open),
        lastPeriodOnNoOpenPeriod: false,
      },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        if (
          new Date(this.props.contractData['dateStart']).getTime() <
          new Date(response.startDate).getTime()
        ) {
          var dt = new Date(response.startDate);
          this.setState({
            openperiod: response,
            periodclosed: true,
            minDate: new Date(dt.setDate(dt.getDate() - 1)),
          });
        } else {
          var dt = new Date(response.startDate);
          this.setState({
            openperiod: response,
            periodclosed: false,
            minDate: new Date(dt.setDate(dt.getDate() - 1)),
          });
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public FetchPeriod() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/period/listperiods`,
      {},
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        let data = response.sort((a: any, b: any) => {
          return (
            new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
          );
        });
        this.setState({
          periodlist: data,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  onUnload = (e: any) => {
    window.removeEventListener('beforeunload', this.onUnload);
    e.preventDefault();
    e.returnValue = '';
    this.SaveContract();
  };

  public InitializeData() {
    this.GetContractType();
    this.GetBranch();
    this.GetDepartment();
    this.GetJob();
    this.GetGrade();
    this.GetCostCenter();
    this.GetClass();
    this.GetSection();
    this.GetUnit();
    this.GetTeam();
  }

  public GetBranch() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/listbranch`,
      { addNewOption: true, showAllDuplicates: true },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.jsonBranch = data;
        this.jsonJobBranch = data.filter(
          (x: any) => x.id !== Common.blankguid && x.isgroup === false
        );

        let cbranch: any = this.cntxitems.filter(
          (x: any) => x.text.toLowerCase() === 'branch'
        );

        if (cbranch.length > 0) {
          this.jsonJobBranch.forEach((x: any) => {
            cbranch[0].items.push({
              id: x.id,
              text: x.name,
              iconCss: '',
              separator: false,
              items: [],
            });
          });
        }

        this.setState({
          bfields: {
            dataSource: this.jsonBranch.filter((x: any) => x.branchId === null),
            value: 'id',
            text: 'name',
            child: 'subBranchs',
            selectable: 'selectable',
          },
          // branchid: this.props.contractData.branch
          //   ? this.props.contractData.branch.id
          //   : null,
          bfieldsjob: {
            dataSource: this.jsonJobBranch,
            value: 'id',
            text: 'name',
            child: 'subBranchs',
            selectable: 'selectable',
          },
          branchfetched: true,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private GetDepartment() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/listdepartment`,
      { addNewOption: true, showAllDuplicates: true },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.jsonDepartment = data;
        this.jsonJobDepartment = data.filter(
          (x: any) => x.id !== Common.blankguid && x.isgroup === false
        );

        let cdepartment: any = this.cntxitems.filter(
          (x: any) => x.text.toLowerCase() === 'department'
        );

        if (cdepartment.length > 0) {
          this.jsonJobDepartment.forEach((x: any) => {
            cdepartment[0].items.push({
              id: x.id,
              text: x.name,
              iconCss: '',
              separator: false,
              items: [],
            });
          });
        }

        this.setState({
          dfields: {
            dataSource: this.jsonDepartment.filter(
              (x: any) => x.departmentId === null
            ),
            value: 'id',
            text: 'name',
            child: 'subDepartments',
            selectable: 'selectable',
          },
          // departmentid: this.props.contractData.department
          //   ? this.props.contractData.department.id
          //   : null,
          dfieldsjob: {
            dataSource: this.jsonJobDepartment,
            value: 'id',
            text: 'name',
            child: 'subDepartments',
            selectable: 'selectable',
          },
          deptfetched: true,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetClass() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/listclass`,
      { addNewOption: true, showAllDuplicates: true },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.jsonClass = data;
        this.jsonJobClass = data.filter(
          (x: any) => x.id !== Common.blankguid && x.isgroup === false
        );
        this.setState({
          cfields: {
            dataSource: this.jsonClass.filter((x: any) => x.classId === null),
            value: 'id',
            text: 'name',
            child: 'subClasses',
            selectable: 'selectable',
          },
          // classid: this.props.contractData.class
          //   ? this.props.contractData.class.id
          //   : null,
          cfieldsjob: {
            dataSource: this.jsonJobClass,
            value: 'id',
            text: 'name',
            child: 'subDepartments',
            selectable: 'selectable',
          },
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetSection() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/listsection`,
      { addNewOption: true, showAllDuplicates: true },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.jsonSection = data;
        this.jsonJobSection = data.filter(
          (x: any) => x.id !== Common.blankguid && x.isgroup === false
        );
        this.setState({
          sfields: {
            dataSource: this.jsonSection.filter(
              (x: any) => x.sectionId === null
            ),
            value: 'id',
            text: 'name',
            child: 'subSections',
            selectable: 'selectable',
          },
          // sectionid: this.props.contractData.section
          //   ? this.props.contractData.section.id
          //   : null,
          sfieldsjob: {
            dataSource: this.jsonJobSection,
            value: 'id',
            text: 'name',
            child: 'subDepartments',
            selectable: 'selectable',
          },
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetUnit() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/listunit`,
      { addNewOption: true, showAllDuplicates: true },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.jsonUnit = data;
        this.jsonJobUnit = data.filter(
          (x: any) => x.id !== Common.blankguid && x.isgroup === false
        );
        this.setState({
          ufields: {
            dataSource: this.jsonUnit.filter((x: any) => x.unitId === null),
            value: 'id',
            text: 'name',
            child: 'subUnits',
            selectable: 'selectable',
          },
          // unitid: this.props.contractData.unit
          //   ? this.props.contractData.unit.id
          //   : null,
          ufieldsjob: {
            dataSource: this.jsonJobUnit,
            value: 'id',
            text: 'name',
            child: 'subDepartments',
            selectable: 'selectable',
          },
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetTeam() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/listteam`,
      { addNewOption: true, showAllDuplicates: true },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.jsonTeam = data;
        this.jsonJobTeam = data.filter(
          (x: any) => x.id !== Common.blankguid && x.isgroup === false
        );
        this.setState({
          tfields: {
            dataSource: this.jsonTeam.filter((x: any) => x.teamId === null),
            value: 'id',
            text: 'name',
            child: 'subTeams',
            selectable: 'selectable',
          },
          // teamid: this.props.contractData.team
          //   ? this.props.contractData.team.id
          //   : null,
          tfieldsjob: {
            dataSource: this.jsonJobTeam,
            value: 'id',
            text: 'name',
            child: 'subDepartments',
            selectable: 'selectable',
          },
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetJob() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/listjob`,
      { addNewOption: true, showAllDuplicates: true },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.jsonJob = data;
        this.jsonJobReportTo = data.filter(
          (x: any) => x.id !== Common.blankguid && x.isgroup === false
        );

        let cjob: any = this.cntxitems.filter(
          (x: any) => x.text.toLowerCase() === 'job'
        );

        if (cjob.length > 0) {
          this.jsonJobReportTo.forEach((x: any) => {
            cjob[0].items.push({
              id: x.id,
              text: x.name,
              iconCss: '',
              separator: false,
              items: [],
            });
          });
        }

        this.setState({
          jfields: {
            dataSource: this.jsonJob.filter((x: any) => x.jobId === null),
            value: 'id',
            text: 'name',
            child: 'subJob',
            selectable: 'selectable',
          },
          // jobid: this.props.contractData.job
          //   ? this.props.contractData.job.id
          //   : null,
          jfieldsreportto: {
            dataSource: this.jsonJobReportTo,
            value: 'id',
            text: 'name',
            child: 'subJob',
            selectable: 'selectable',
          },
          jobfetched: true,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private GetGrade() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/grades/listgrades`,
      { addNewOption: true, showAllDuplicates: true },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.jsonGrade = data;
        this.jsonJobGrade = data.filter(
          (x: any) => x.id !== Common.blankguid && x.isgroup === false
        );

        let cgrade: any = this.cntxitems.filter(
          (x: any) => x.text.toLowerCase() === 'grade'
        );

        if (cgrade.length > 0) {
          this.jsonJobGrade.forEach((x: any) => {
            cgrade[0].items.push({
              id: x.id,
              text: x.name,
              iconCss: '',
              separator: false,
              items: [],
            });
          });
        }

        this.setState({
          gfields: {
            dataSource: this.jsonGrade.filter((x: any) => x.gradeId === null),
            value: 'id',
            text: 'name',
            child: 'subGrades',
            selectable: 'selectable',
          },
          // gradeid: this.props.contractData.grade
          //   ? this.props.contractData.grade.id
          //   : null,
          gfieldsjob: {
            dataSource: this.jsonJobGrade,
            value: 'id',
            text: 'name',
            child: 'subDepartments',
            selectable: 'selectable',
          },
          gradefetched: true,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetCostCenter() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/costcenter/listcostcenters`,
      { addNewOption: true },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        this.jsonCostCenter = data;
        this.setState({
          ccfields: {
            dataSource: this.jsonCostCenter,
            value: 'id',
            text: 'name',
            child: 'subCostcenters',
            selectable: 'selectable',
          },
          // costcenterid: this.props.contractData.costcenter
          //   ? this.props.contractData.costcenter.id
          //   : null,
        });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetContractType() {
    this.jsonContractType = Common.GetContractType(
      this.user,
      this._structure.CommonDataItems.ContractType
    );

    let ctype: any = this.cntxitems.filter(
      (x: any) => x.text.toLowerCase() === 'contract type'
    );

    if (ctype.length > 0) {
      let ctypecntxmenu: any = this.user.commondata.filter(
        (x: any) => x.itemType === this.jsonContractType[0].itemType
      );
      if (ctypecntxmenu.length > 0) {
        ctype[0].items = ctypecntxmenu.map((x: any) => ({
          ...x,
          text: x.itemValue,
          iconCss: '',
          separator: false,
          items: [],
        }));
      }
    }
  }

  public SaveContract() {
    if (this.contractchanged === true) {
      this.contractchanged = false;
      window.removeEventListener('beforeunload', this.onUnload);

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/employee/add_contract`,
        this.props.contractData,
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          this.setState({ tempEndDate: this.props.contractData.dateEnd });
          return response.data;
        })
        .then((response: any) => {
          if (this.user.employee && this.user.employee.id === response.id) {
            this.user.employee = response;
            Common.setItem('user', this.user);
          }
          this.setState({ tempEndDate: this.props.contractData.dateEnd });
          return response;
        })
        .catch((error: any) => {
          console.error(error);

          if (
            error.response.data.messages ==
            'Contract End date cannot be set because this employee has booked leave on the selected end date. To proceed, delete/cancel the affected leave day(s).'
          ) {
            this.props.contractData['dateEnd'] = this.state.tempEndDate;
            this.props.contractData['RemoveDateEndIfNull'] = true;

            this.props.selectorData.contract[0].dateEnd =
              this.props.contractData['dateEnd'];

            let emp: any = document.querySelector('#treegrid-selector');
            if (emp) {
              let jsonEmployee = emp.ej2_instances[0].dataSource;

              if (jsonEmployee !== undefined && jsonEmployee.length > 0) {
                if (
                  this.props.contractData.dateEnd !== undefined ||
                  this.props.contractData.dateEnd == null
                ) {
                  if (
                    this.props.contractData.dateEnd === null ||
                    new Date(this.props.contractData.dateEnd).getTime() >=
                      new Date(Date.now()).getTime()
                  ) {
                    emp.ej2_instances[0].dataSource[
                      emp.ej2_instances[0].dataSource.findIndex(
                        (x: any) => x.id === this.props.contractData.employeeId
                      )
                    ].isActive = true;
                    emp.ej2_instances[0].refresh();
                  } else {
                    emp.ej2_instances[0].dataSource[
                      emp.ej2_instances[0].dataSource.findIndex(
                        (x: any) => x.id === this.props.contractData.employeeId
                      )
                    ].isActive = false;
                    emp.ej2_instances[0].refresh();
                  }
                }
              }
            }
          }
        })
        .finally(() => {});
    }
  }

  public SaveAllocation(payload: any) {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/${this.endpoint}`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((response: any) => {
        let obj: any = response;

        let jfreportto: any = this.state.jfieldsreportto;
        let bfieldsjob: any = this.state.bfieldsjob;
        let dfieldsjob: any = this.state.dfieldsjob;
        let cfieldsjob: any = this.state.cfieldsjob;
        let sfieldsjob: any = this.state.sfieldsjob;
        let ufieldsjob: any = this.state.ufieldsjob;
        let tfieldsjob: any = this.state.tfieldsjob;
        let gfieldsjob: any = this.state.gfieldsjob;
        if (this.selectednode) {
          if (
            this.selectedmenuid === 'newgroup' ||
            this.selectedmenuid === 'newitem'
          ) {
            if (!this.selectednode[this.suballocation]) {
              this.selectednode[this.suballocation] = [];
            }
            this.selectednode[this.suballocation].push(obj);

            if (obj.isgroup === false) {
              switch (this.allocation) {
                case 'branch':
                  this.jsonJobBranch.push(obj);
                  break;

                case 'department':
                  this.jsonJobDepartment.push(obj);
                  break;

                case 'job':
                  this.jsonJobReportTo.push(obj);
                  break;

                case 'grade':
                  this.jsonJobGrade.push(obj);
                  break;

                case 'costcenter':
                  break;

                case 'section':
                  this.jsonJobSection.push(obj);
                  break;

                case 'unit':
                  this.jsonJobUnit.push(obj);
                  break;

                case 'class':
                  this.jsonJobClass.push(obj);
                  break;

                case 'team':
                  this.jsonJobTeam.push(obj);
                  break;

                default:
                  return;
                  break;
              }
            }
          } else if (this.selectedmenuid === 'changeitem') {
            let jsn: any = Common.FindParentJsonByIdKeyChildren(
              this.state.allocationfields!['dataSource'],
              this.selectednode.id,
              'id',
              this.treeObj.fields.child
            );
            if (jsn) {
              jsn[this.suballocation][
                jsn[this.suballocation].indexOf(this.selectednode)
              ] = obj;
            } else {
              let jsn: any = Common.FindJsonByIdKeyChildren(
                this.state.allocationfields!['dataSource'],
                this.selectednode.id,
                'id',
                this.treeObj.fields.child
              );
              if (jsn) {
                let a: any[] = this.state.allocationfields!['dataSource'] as [];

                a[a.indexOf(jsn)] = obj;
              }
            }

            if (obj.isgroup === false) {
              let fltr: any = null;
              switch (this.allocation) {
                case 'branch':
                  fltr = this.jsonJobBranch.filter(
                    (x: any) => x.id === this.selectednode.id
                  );
                  if (fltr.length > 0) {
                    this.jsonJobBranch[this.jsonJobBranch.indexOf(fltr[0])] =
                      obj;
                    bfieldsjob.dataSource = this.jsonJobBranch;
                  }
                  break;

                case 'department':
                  fltr = this.jsonJobDepartment.filter(
                    (x: any) => x.id === this.selectednode.id
                  );
                  if (fltr.length > 0) {
                    this.jsonJobDepartment[
                      this.jsonJobDepartment.indexOf(fltr[0])
                    ] = obj;
                    dfieldsjob.dataSource = this.jsonJobDepartment;
                  }
                  break;

                case 'job':
                  fltr = this.jsonJobReportTo.filter(
                    (x: any) => x.id === this.selectednode.id
                  );
                  if (fltr.length > 0) {
                    this.jsonJobReportTo[
                      this.jsonJobReportTo.indexOf(fltr[0])
                    ] = obj;
                    jfreportto.dataSource = this.jsonJobReportTo;
                  }
                  break;

                case 'grade':
                  fltr = this.jsonJobGrade.filter(
                    (x: any) => x.id === this.selectednode.id
                  );
                  if (fltr.length > 0) {
                    this.jsonJobGrade[this.jsonJobGrade.indexOf(fltr[0])] = obj;
                    gfieldsjob.dataSource = this.jsonJobGrade;
                  }
                  break;

                case 'costcenter':
                  break;

                case 'section':
                  fltr = this.jsonJobSection.filter(
                    (x: any) => x.id === this.selectednode.id
                  );
                  if (fltr.length > 0) {
                    this.jsonJobSection[this.jsonJobSection.indexOf(fltr[0])] =
                      obj;
                    sfieldsjob.dataSource = this.jsonJobSection;
                  }
                  break;

                case 'unit':
                  fltr = this.jsonJobUnit.filter(
                    (x: any) => x.id === this.selectednode.id
                  );
                  if (fltr.length > 0) {
                    this.jsonJobUnit[this.jsonJobUnit.indexOf(fltr[0])] = obj;
                    ufieldsjob.dataSource = this.jsonJobUnit;
                  }
                  break;

                case 'class':
                  fltr = this.jsonJobClass.filter(
                    (x: any) => x.id === this.selectednode.id
                  );
                  if (fltr.length > 0) {
                    this.jsonJobClass[this.jsonJobClass.indexOf(fltr[0])] = obj;
                    cfieldsjob.dataSource = this.jsonJobClass;
                  }
                  break;

                case 'team':
                  fltr = this.jsonJobTeam.filter(
                    (x: any) => x.id === this.selectednode.id
                  );
                  if (fltr.length > 0) {
                    this.jsonJobTeam[this.jsonJobTeam.indexOf(fltr[0])] = obj;
                    tfieldsjob.dataSource = this.jsonJobTeam;
                  }
                  break;

                default:
                  return;
                  break;
              }
            }
          }
          this.setState({
            alloccode: '',
            allocname: '',
            alloccustomcode: '',
            allocdesc: '',
            jfieldsreportto: jfreportto,
            bfieldsjob: bfieldsjob,
            dfieldsjob: dfieldsjob,
            cfieldsjob: cfieldsjob,
            sfieldsjob: sfieldsjob,
            ufieldsjob: ufieldsjob,
            tfieldsjob: tfieldsjob,
            gfieldsjob: gfieldsjob,
          });
        } else {
          let a: any[] = this.state.allocationfields!['dataSource'] as [];
          a.push(obj);

          if (obj.isgroup === false) {
            switch (this.allocation) {
              case 'branch':
                this.jsonJobBranch.push(obj);
                bfieldsjob.dataSource = this.jsonJobBranch;
                break;

              case 'department':
                this.jsonJobDepartment.push(obj);
                dfieldsjob.dataSource = this.jsonJobDepartment;
                break;

              case 'job':
                this.jsonJobReportTo.push(obj);
                jfreportto.dataSource = this.jsonJobReportTo;
                break;

              case 'grade':
                this.jsonJobGrade.push(obj);
                gfieldsjob.dataSource = this.jsonJobGrade;
                break;

              case 'costcenter':
                break;

              case 'section':
                this.jsonJobSection.push(obj);
                sfieldsjob.dataSource = this.jsonJobSection;
                break;

              case 'unit':
                this.jsonJobUnit.push(obj);
                ufieldsjob.dataSource = this.jsonJobUnit;
                break;

              case 'class':
                this.jsonJobClass.push(obj);
                cfieldsjob.dataSource = this.jsonJobClass;
                break;

              case 'team':
                this.jsonJobTeam.push(obj);
                tfieldsjob.dataSource = this.jsonJobTeam;
                break;

              default:
                return;
                break;
            }
          }

          this.setState({
            alloccode: '',
            allocname: '',
            alloccustomcode: '',
            allocdesc: '',
            jfieldsreportto: jfreportto,
            bfieldsjob: bfieldsjob,
            dfieldsjob: dfieldsjob,
            cfieldsjob: cfieldsjob,
            sfieldsjob: sfieldsjob,
            ufieldsjob: ufieldsjob,
            tfieldsjob: tfieldsjob,
            gfieldsjob: gfieldsjob,
          });
        }

        switch (this.allocation) {
          case 'branch':
            break;

          case 'department':
            break;

          case 'job':
            break;

          case 'grade':
            break;

          case 'costcenter':
            break;

          case 'section':
            break;

          case 'unit':
            break;

          case 'class':
            break;

          case 'team':
            break;

          default:
            return;
            break;
        }

        this.treeObj.ensureVisible(obj.id);
        if (obj.isgroup === true) {
          this.treeObj.expandAll([obj.id]);
        }
        this.selectednode = obj;

        return response;
      })
      .catch((error: any) => {
        console.error(error);
        // if (
        //   error.response &&
        //   error.response.data &&
        //   error.response.data.messages &&
        //   error.response.data.messages.length > 0
        // ) {
        // DialogConfirm.showDialog({
        //    content: `<p class="dialog-contain">${error.response.data.messages.join('<br>')}</p>`,

        //   });
        // }
      })
      .finally(() => {});
  }
  onFocusFunction = (event: any) => {
    event.target.classList.remove('textbox-border-error');
  };
  ValidateForm = () => {
    const inputs = [
      document.querySelector<HTMLInputElement>('#allocname'),
      document.querySelector<HTMLInputElement>('#alloccode'),
    ];

    inputs.forEach((input) => {
      if (input) {
        input.classList.remove('textbox-border-error');

        if (input.value.trim() === '') {
          input.classList.add('textbox-border-error');
        } else {
          input.classList.add('textbox-class');
        }
      }
    });
  };

  public DialogButtonModel: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        if (this.state.allocationchangedialog === true) {
          this.setState({ allocationchangedialog: false });
        } else {
          this.setSelectedDropdownTreeValue.bind(this);
        }
      },
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        this.ValidateForm();
        if (this.state.allocationchangedialog === true) {
          if (
            this.allocation === 'job' ||
            this.allocation === 'grade' ||
            this.allocation === 'costcenter'
          ) {
            if (!this.state.alloccode) {
              // DialogConfirm.showDialog({
              //   content: '<p class="dialog-contain">Please enter code</p>',
              // });
              const TargetInput =
                document.querySelector<HTMLInputElement>('#alloccode');
              if (TargetInput) {
                TargetInput.style.borderBottom = '2px solid red';
              }
              return;
            }
          }

          if (!this.state.allocname) {
            // DialogConfirm.showDialog({
            //   content: '<p class="dialog-contain">Please enter name</p>',
            // });
            const TargetInput =
              document.querySelector<HTMLInputElement>('#allocname');
            if (TargetInput) {
              TargetInput.style.borderBottom = '2px solid red';
            }
            return;
          }

          let payload: any = null;
          payload = {
            id:
              this.selectedmenuid === 'changeitem'
                ? this.selectednode!['id']
                : null,
            name: this.state.allocname,
            isgroup: this.isgroupselected,
            [this.allocationidfield]:
              this.treeObj.fields.dataSource.length > 0 &&
              this.selectednode &&
              (this.selectedmenuid === 'newgroup' ||
                this.selectedmenuid === 'newitem')
                ? this.selectednode!['id']
                : null,
          };
          switch (this.allocation) {
            case 'branch':
              break;

            case 'department':
              break;

            case 'job':
              payload['code'] = this.state.alloccode;
              //payload['description'] = this.state.allocdesc;
              let ctrl: any = document.querySelector(`#ddljobreportto`);
              let ddltree: DropDownTreeComponent = ctrl
                .ej2_instances[0] as DropDownTreeComponent;

              if (ddltree) {
                if (ddltree.value && ddltree.value.length > 0) {
                  payload['reportToJobId'] = ddltree.value[0];
                } else {
                  payload['reportToJobId'] = null;
                  payload['removeReportToJobIfNull'] = true;
                }
              }

              ctrl = document.querySelector(`#ddljobbranch`);
              ddltree = ctrl.ej2_instances[0] as DropDownTreeComponent;
              if (ddltree) {
                if (ddltree.value && ddltree.value.length > 0) {
                  payload['branchId'] = ddltree.value[0];
                } else {
                  payload['branchId'] = null;
                  payload['removeBranchIfNull'] = true;
                }
              }

              ctrl = document.querySelector(`#ddljobdepartment`);
              ddltree = ctrl.ej2_instances[0] as DropDownTreeComponent;
              if (ddltree) {
                if (ddltree.value && ddltree.value.length > 0) {
                  payload['departmentId'] = ddltree.value[0];
                } else {
                  payload['departmentId'] = null;
                  payload['removeDepartmentIfNull'] = true;
                }
              }

              ctrl = document.querySelector(`#ddljobclass`);
              ddltree = ctrl.ej2_instances[0] as DropDownTreeComponent;
              if (ddltree) {
                if (ddltree.value && ddltree.value.length > 0) {
                  payload['classId'] = ddltree.value[0];
                } else {
                  payload['classId'] = null;
                  payload['removeClassIfNull'] = true;
                }
              }

              ctrl = document.querySelector(`#ddljobsection`);
              ddltree = ctrl.ej2_instances[0] as DropDownTreeComponent;
              if (ddltree) {
                if (ddltree.value && ddltree.value.length > 0) {
                  payload['sectionId'] = ddltree.value[0];
                } else {
                  payload['sectionId'] = null;
                  payload['removeSectionIfNull'] = true;
                }
              }

              ctrl = document.querySelector(`#ddljobunit`);
              ddltree = ctrl.ej2_instances[0] as DropDownTreeComponent;
              if (ddltree) {
                if (ddltree.value && ddltree.value.length > 0) {
                  payload['unitId'] = ddltree.value[0];
                } else {
                  payload['unitId'] = null;
                  payload['removeUnitIfNull'] = true;
                }
              }

              ctrl = document.querySelector(`#ddljobteam`);
              ddltree = ctrl.ej2_instances[0] as DropDownTreeComponent;
              if (ddltree) {
                if (ddltree.value && ddltree.value.length > 0) {
                  payload['teamId'] = ddltree.value[0];
                } else {
                  payload['teamId'] = null;
                  payload['removeTeamIfNull'] = true;
                }
              }

              ctrl = document.querySelector(`#ddljobgrade`);
              ddltree = ctrl.ej2_instances[0] as DropDownTreeComponent;
              if (ddltree) {
                if (ddltree.value && ddltree.value.length > 0) {
                  payload['gradeId'] = ddltree.value[0];
                } else {
                  payload['gradeId'] = null;
                  payload['removeGradeIfNull'] = true;
                }
              }
              break;

            case 'grade':
              payload['code'] = this.state.alloccode;
              break;

            case 'costcenter':
              payload['code'] = this.state.alloccode;
              payload['customCode'] = this.state.alloccustomcode;
              break;

            case 'section':
              break;

            case 'unit':
              break;

            case 'class':
              break;

            case 'team':
              break;

            default:
              return;
              break;
          }

          this.SaveAllocation(payload);
          this.setState({ allocationchangedialog: false });
        } else {
          this.setState({ allocationlistdialog: false });
        }
      },
    },
  ];

  public AllocationListDialogContent() {
    setTimeout(() => {
      /* Open contextmenu on three vertical dots */
      document
        .querySelector('#contract-moreoptions')
        ?.removeEventListener('click', (e: any) =>
          this.treemenuObj.open(e.y, e.x)
        );
      document
        .querySelector('#contract-moreoptions')
        ?.addEventListener('click', (e: any) =>
          this.treemenuObj.open(e.y, e.x)
        );
    }, 1000);

    //var _content: any;
    let menuItems: MenuItemModel[] = [
      { id: 'newgroup', text: 'Add New Group' },
      { id: 'newitem', text: 'Add New Item' },
      { id: 'newseparator', text: ' ', separator: true },
      { id: 'changeitem', text: 'Change Group/Item' },
      { id: 'changeseparator', text: '  ', separator: true },
      { id: 'delete', text: 'Delete' },
    ];

    const nodeclicked = (e: any) => {
      if (e.event.which === 3) {
        this.treeObj.selectedNodes = [e.node.getAttribute('data-uid')];
      }
    };

    const beforeopen = (e: any) => {
      this.selectednode = null;
      if (this.treeObj.fields.dataSource.length > 0) {
        let targetNodeId: string = this.treeObj.selectedNodes[0];
        let targetNode: any = document.querySelector(
          '[data-uid="' + targetNodeId + '"]'
        );
        if (targetNode) {
          this.selectednode = Common.FindJsonByIdKeyChildren(
            this.state.allocationfields!['dataSource'],
            this.treeObj.selectedNodes[0],
            'id',
            this.state.allocationfields!['child']
          );

          if (!this.selectednode.isgroup) {
            let itms: string[] = this.treemenuObj.items
              .filter(
                (x: any) =>
                  x.id === 'newgroup' ||
                  x.id === 'newitem' ||
                  x.id === 'newseparator'
              )
              .map((x: any) => x.text);
            this.treemenuObj.hideItems(itms);
          } else {
            let itms: string[] = this.treemenuObj.items.map((x: any) => x.text);
            this.treemenuObj.showItems(itms);
          }
        }
      }
    };

    const menuclick = (e: any) => {
      this.selectedmenuid = e.item.id;

      if (this.selectedmenuid === 'newgroup') {
        this.isgroupselected = true;

        this.setState({
          allocationchangedialog: true,
          alloccode: '',
          allocname: '',
          alloccustomcode: '',
          allocdesc: '',
          //selectednode: selectednode,
        });
      } else if (this.selectedmenuid === 'newitem') {
        this.isgroupselected = false;

        let jfreportto: any = this.state.jfieldsreportto;
        let bfieldsjob: any = this.state.bfieldsjob;
        let dfieldsjob: any = this.state.dfieldsjob;
        let cfieldsjob: any = this.state.cfieldsjob;
        let sfieldsjob: any = this.state.sfieldsjob;
        let ufieldsjob: any = this.state.ufieldsjob;
        let tfieldsjob: any = this.state.tfieldsjob;
        let gfieldsjob: any = this.state.gfieldsjob;
        switch (this.allocation) {
          case 'branch':
            bfieldsjob.dataSource = this.jsonJobBranch;
            break;

          case 'department':
            dfieldsjob.dataSource = this.jsonJobDepartment;
            break;

          case 'job':
            jfreportto.dataSource = this.jsonJobReportTo;
            break;

          case 'grade':
            gfieldsjob.dataSource = this.jsonJobGrade;
            break;

          case 'costcenter':
            break;

          case 'section':
            sfieldsjob.dataSource = this.jsonJobSection;
            break;

          case 'unit':
            ufieldsjob.dataSource = this.jsonJobUnit;
            break;

          case 'class':
            cfieldsjob.dataSource = this.jsonJobClass;
            break;

          case 'team':
            tfieldsjob.dataSource = this.jsonJobTeam;
            break;

          default:
            return;
            break;
        }

        this.setState({
          allocationchangedialog: true,
          alloccode: '',
          allocname: '',
          alloccustomcode: '',
          allocdesc: '',
          jfieldsreportto: jfreportto,
          bfieldsjob: bfieldsjob,
          dfieldsjob: dfieldsjob,
          cfieldsjob: cfieldsjob,
          sfieldsjob: sfieldsjob,
          ufieldsjob: ufieldsjob,
          tfieldsjob: tfieldsjob,
          gfieldsjob: gfieldsjob,
          //selectednode: selectednode,
        });
      } else if (this.selectedmenuid === 'changeitem' && this.selectednode) {
        this.isgroupselected = this.selectednode.isgroup;
        let code: string = '';
        let customcode: string = '';
        let decription: string = '';

        switch (this.allocation) {
          case 'branch':
            break;

          case 'department':
            break;

          case 'job':
            code = this.selectednode.code;
            //decription = this.selectednode.description;
            if (this.selectednode.isgroup === false) {
              setTimeout(() => {
                let ctrl: any = document.querySelector(`#ddljobreportto`);
                let ddltree: DropDownTreeComponent = ctrl
                  .ej2_instances[0] as DropDownTreeComponent;

                if (ddltree) {
                  ddltree.value = this.selectednode.reportToJob
                    ? [this.selectednode.reportToJobId]
                    : [];
                }

                ctrl = document.querySelector(`#ddljobbranch`);
                ddltree = ctrl.ej2_instances[0] as DropDownTreeComponent;
                if (ddltree) {
                  ddltree.value = this.selectednode.branch
                    ? [this.selectednode.branchId]
                    : [];
                }

                ctrl = document.querySelector(`#ddljobdepartment`);
                ddltree = ctrl.ej2_instances[0] as DropDownTreeComponent;
                if (ddltree) {
                  ddltree.value = this.selectednode.department
                    ? [this.selectednode.departmentId]
                    : [];
                }

                ctrl = document.querySelector(`#ddljobclass`);
                ddltree = ctrl.ej2_instances[0] as DropDownTreeComponent;
                if (ddltree) {
                  ddltree.value = this.selectednode.class
                    ? [this.selectednode.classId]
                    : [];
                }

                ctrl = document.querySelector(`#ddljobsection`);
                ddltree = ctrl.ej2_instances[0] as DropDownTreeComponent;
                if (ddltree) {
                  ddltree.value = this.selectednode.section
                    ? [this.selectednode.sectionId]
                    : [];
                }

                ctrl = document.querySelector(`#ddljobunit`);
                ddltree = ctrl.ej2_instances[0] as DropDownTreeComponent;
                if (ddltree) {
                  ddltree.value = this.selectednode.unit
                    ? [this.selectednode.unitId]
                    : [];
                }

                ctrl = document.querySelector(`#ddljobteam`);
                ddltree = ctrl.ej2_instances[0] as DropDownTreeComponent;
                if (ddltree) {
                  ddltree.value = this.selectednode.team
                    ? [this.selectednode.teamId]
                    : [];
                }

                ctrl = document.querySelector(`#ddljobgrade`);
                ddltree = ctrl.ej2_instances[0] as DropDownTreeComponent;
                if (ddltree) {
                  ddltree.value = this.selectednode.grade
                    ? [this.selectednode.gradeId]
                    : [];
                }
              }, 1000);
            }
            break;

          case 'grade':
            code = this.selectednode.code;
            break;

          case 'costcenter':
            code = this.selectednode.code;
            customcode = this.selectednode.customCode;
            break;

          case 'section':
            break;

          case 'unit':
            break;

          case 'class':
            break;

          case 'team':
            break;

          default:
            return;
            break;
        }

        let jfreportto: any = this.state.jfieldsreportto;
        let bfieldsjob: any = this.state.bfieldsjob;
        let dfieldsjob: any = this.state.dfieldsjob;
        let cfieldsjob: any = this.state.cfieldsjob;
        let sfieldsjob: any = this.state.sfieldsjob;
        let ufieldsjob: any = this.state.ufieldsjob;
        let tfieldsjob: any = this.state.tfieldsjob;
        let gfieldsjob: any = this.state.gfieldsjob;
        switch (this.allocation) {
          case 'branch':
            bfieldsjob.dataSource = this.jsonJobBranch.filter(
              (x: any) => x.id !== '' && x.id !== this.selectednode.id
            );
            break;

          case 'department':
            dfieldsjob.dataSource = this.jsonJobDepartment.filter(
              (x: any) => x.id !== '' && x.id !== this.selectednode.id
            );
            break;

          case 'job':
            jfreportto.dataSource = this.jsonJobReportTo.filter(
              (x: any) => x.id !== '' && x.id !== this.selectednode.id
            );
            break;

          case 'grade':
            gfieldsjob.dataSource = this.jsonJobGrade.filter(
              (x: any) => x.id !== '' && x.id !== this.selectednode.id
            );
            break;

          case 'costcenter':
            break;

          case 'section':
            sfieldsjob.dataSource = this.jsonJobSection.filter(
              (x: any) => x.id !== '' && x.id !== this.selectednode.id
            );
            break;

          case 'unit':
            ufieldsjob.dataSource = this.jsonJobUnit.filter(
              (x: any) => x.id !== '' && x.id !== this.selectednode.id
            );
            break;

          case 'class':
            cfieldsjob.dataSource = this.jsonJobClass.filter(
              (x: any) => x.id !== '' && x.id !== this.selectednode.id
            );
            break;

          case 'team':
            tfieldsjob.dataSource = this.jsonJobTeam.filter(
              (x: any) => x.id !== '' && x.id !== this.selectednode.id
            );
            break;

          default:
            return;
            break;
        }

        this.setState({
          allocationchangedialog: true,
          allocname: this.selectednode.name,
          alloccode: code,
          alloccustomcode: customcode,
          allocdesc: decription,
          jfieldsreportto: jfreportto,
          bfieldsjob: bfieldsjob,
          dfieldsjob: dfieldsjob,
          cfieldsjob: cfieldsjob,
          sfieldsjob: sfieldsjob,
          ufieldsjob: ufieldsjob,
          tfieldsjob: tfieldsjob,
          gfieldsjob: gfieldsjob,
        });
      }
    };

    return (
      <>
        <div className='control-pane'>
          <div className='control-section'>
            <div className='control_wrapper'>
              <div id='allocationtree' className='allocationtree '>
                <div className='three-dots-parent'>
                  <div id='contract-moreoptions' className='three-dots'></div>
                </div>
                <TreeViewComponent
                  fields={this.state.allocationfields}
                  ref={(treeview) => {
                    this.treeObj = treeview;
                  }}
                  nodeClicked={nodeclicked}
                  expandOn='Click'
                />
                <ContextMenuComponent
                  id='contextmenutree'
                  target='#allocationtree'
                  items={menuItems}
                  beforeOpen={beforeopen}
                  select={menuclick}
                  ref={(contextmenu) => {
                    this.treemenuObj = contextmenu;
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  public AllocationChangeDialogContent() {
    return (
      <>
        {this.allocation === 'job' ||
        this.allocation === 'grade' ||
        this.allocation === 'costcenter' ? (
          <div className='row align-items-center'>
            <div className='col-12 py-2'>
              <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                Code
              </label>
              <span className='mandatoryinfo'>*</span>
              <input
                className='input-text-border'
                // onFocus={this.onFocusFunction}
                id='alloccode'
                name='alloccode'
                type='text'
                placeholder={'Code'}
                autoComplete='off'
                style={{ fontSize: '15px' }}
                ref={(obj: any) => (this.txtCode = obj)}
                value={this.state.alloccode}
                onChange={(e: any) => {
                  this.setState({ alloccode: e.target.value });
                  setTimeout(() => {
                    this.txtCode.focus();
                  }, 100);
                }}
              />
            </div>
          </div>
        ) : null}
        <div className='row align-items-center'>
          <div className='col-12 py-2'>
            <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
              Name
            </label>
            <span className='mandatoryinfo'>*</span>
            <input
              className='input-text-border'
              id='allocname'
              name='allocname'
              key={Math.random()}
              type='text'
              placeholder={'Name'}
              autoComplete='off'
              style={{ fontSize: '15px' }}
              //autoFocus={true}
              ref={(obj: any) => (this.txtName = obj)}
              value={this.state.allocname}
              onChange={(e: any) => {
                this.setState({ allocname: e.target.value });
                setTimeout(() => {
                  this.txtName.focus();
                }, 100);
              }}
            />
          </div>
        </div>
        {this.allocation === 'costcenter' &&
        (this.selectedmenuid === 'newitem' ||
          (this.selectedmenuid === 'changeitem' &&
            this.selectednode.isgroup === false)) ? (
          <div className='row align-items-center'>
            <div className='col-12 py-2'>
              <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                Custom Code
              </label>
              <input
                className='input-text-border'
                id='alloccustomcode'
                name='alloccustomcode'
                type='text'
                placeholder={'Custom Code'}
                autoComplete='off'
                style={{ fontSize: '15px' }}
                ref={(obj: any) => (this.txtCustomCode = obj)}
                value={this.state.alloccustomcode}
                onChange={(e: any) => {
                  this.setState({ alloccustomcode: e.target.value });
                  setTimeout(() => {
                    this.txtCustomCode.focus();
                  }, 100);
                }}
              />
            </div>
          </div>
        ) : null}
        {this.allocation === 'job' &&
        (this.selectedmenuid === 'newitem' ||
          (this.selectedmenuid === 'changeitem' &&
            this.selectednode.isgroup === false)) ? (
          <>
            <div className='row align-items-center'>
              <div className='col-12 py-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Reporting To
                </label>
                <div className='w-100'>
                  <label
                    id='ddljobreportto_orgnl'
                    typeof='text'
                    itemType='dropdowntree'
                    className={
                      this.selectedmenuid === 'changeitem' &&
                      this.selectednode.reportToJob
                        ? ''
                        : 'lablecolor-none'
                    }
                    onClick={this.handleLabelDblClick}
                  >
                    {this.selectedmenuid === 'changeitem' &&
                    this.selectednode.reportToJob
                      ? this.selectednode.reportToJob.name
                      : 'None'}
                  </label>
                  {this.state.jfieldsreportto ? (
                    <div className='d-none'>
                      <DropDownTreeComponent
                        id='ddljobreportto'
                        name='ddljobreportto'
                        treeSettings={this.treeSettings}
                        fields={this.state.jfieldsreportto}
                        filterBarPlaceholder='Search a Job'
                        allowFiltering={true}
                        filterType='Contains'
                        //select={handleDropDownTreeChange}
                        //blur={handleDropDownOnBlur}
                        placeholder='Select Job'
                        popupHeight='220px'
                        style={{ fontSize: '15px' }}
                      ></DropDownTreeComponent>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-md-6 py-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Branch :
                </label>
                <div className='w-100'>
                  <label
                    id='ddljobbranch_orgnl'
                    typeof='text'
                    itemType='dropdowntree'
                    className={
                      this.selectedmenuid === 'changeitem' &&
                      this.selectednode.branch
                        ? ''
                        : 'lablecolor-none'
                    }
                    onClick={this.handleLabelDblClick}
                  >
                    {this.selectedmenuid === 'changeitem' &&
                    this.selectednode.branch
                      ? this.selectednode.branch.name
                      : 'None'}
                  </label>
                  {this.state.bfieldsjob ? (
                    <div className='d-none'>
                      <DropDownTreeComponent
                        id='ddljobbranch'
                        name='ddljobbranch'
                        treeSettings={this.treeSettings}
                        fields={this.state.bfieldsjob}
                        filterBarPlaceholder='Search a Branch'
                        allowFiltering={true}
                        filterType='Contains'
                        placeholder='Select Branch'
                        popupHeight='220px'
                        style={{ fontSize: '15px' }}
                      ></DropDownTreeComponent>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-6 py-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Department
                </label>
                <div className='w-100'>
                  <label
                    id='ddljobdepartment_orgnl'
                    typeof='text'
                    itemType='dropdowntree'
                    className={
                      this.selectedmenuid === 'changeitem' &&
                      this.selectednode.department
                        ? ''
                        : 'lablecolor-none'
                    }
                    onClick={this.handleLabelDblClick}
                  >
                    {this.selectedmenuid === 'changeitem' &&
                    this.selectednode.department
                      ? this.selectednode.department.name
                      : 'None'}
                  </label>
                  {this.state.dfieldsjob ? (
                    <div className='d-none'>
                      <DropDownTreeComponent
                        id='ddljobdepartment'
                        name='ddljobdepartment'
                        treeSettings={this.treeSettings}
                        fields={this.state.dfieldsjob}
                        filterBarPlaceholder='Search a Department'
                        allowFiltering={true}
                        filterType='Contains'
                        placeholder='Select Department'
                        popupHeight='220px'
                        style={{ fontSize: '15px' }}
                      ></DropDownTreeComponent>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-md-6 py-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Class
                </label>
                <div className='w-100'>
                  <label
                    id='ddljobclass_orgnl'
                    typeof='text'
                    itemType='dropdowntree'
                    className={
                      this.selectedmenuid === 'changeitem' &&
                      this.selectednode.class
                        ? ''
                        : 'lablecolor-none'
                    }
                    onClick={this.handleLabelDblClick}
                  >
                    {this.selectedmenuid === 'changeitem' &&
                    this.selectednode.class
                      ? this.selectednode.class.name
                      : 'None'}
                  </label>
                  {this.state.cfieldsjob ? (
                    <div className='d-none'>
                      <DropDownTreeComponent
                        id='ddljobclass'
                        name='ddljobclass'
                        treeSettings={this.treeSettings}
                        fields={this.state.cfieldsjob}
                        filterBarPlaceholder='Search a Class'
                        allowFiltering={true}
                        filterType='Contains'
                        placeholder='Select Class'
                        popupHeight='220px'
                        style={{ fontSize: '15px' }}
                      ></DropDownTreeComponent>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-6 py-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Section
                </label>
                <div className='w-100'>
                  <label
                    id='ddljobsection_orgnl'
                    typeof='text'
                    itemType='dropdowntree'
                    className={
                      this.selectedmenuid === 'changeitem' &&
                      this.selectednode.section
                        ? ''
                        : 'lablecolor-none'
                    }
                    onClick={this.handleLabelDblClick}
                  >
                    {this.selectedmenuid === 'changeitem' &&
                    this.selectednode.section
                      ? this.selectednode.section.name
                      : 'None'}
                  </label>
                  {this.state.sfieldsjob ? (
                    <div className='d-none'>
                      <DropDownTreeComponent
                        id='ddljobsection'
                        name='ddljobsection'
                        treeSettings={this.treeSettings}
                        fields={this.state.sfieldsjob}
                        filterBarPlaceholder='Search a Section'
                        allowFiltering={true}
                        filterType='Contains'
                        placeholder='Select Section'
                        popupHeight='220px'
                        style={{ fontSize: '15px' }}
                      ></DropDownTreeComponent>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-md-6 py-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Unit
                </label>
                <div className='w-100'>
                  <label
                    id='ddljobunit_orgnl'
                    typeof='text'
                    itemType='dropdowntree'
                    className={
                      this.selectedmenuid === 'changeitem' &&
                      this.selectednode.unit
                        ? ''
                        : 'lablecolor-none'
                    }
                    onClick={this.handleLabelDblClick}
                  >
                    {this.selectedmenuid === 'changeitem' &&
                    this.selectednode.unit
                      ? this.selectednode.unit.name
                      : 'None'}
                  </label>
                  {this.state.ufieldsjob ? (
                    <div className='d-none'>
                      <DropDownTreeComponent
                        id='ddljobunit'
                        name='ddljobunit'
                        treeSettings={this.treeSettings}
                        fields={this.state.ufieldsjob}
                        filterBarPlaceholder='Search a Unit'
                        allowFiltering={true}
                        filterType='Contains'
                        placeholder='Select Unit'
                        popupHeight='220px'
                        style={{ fontSize: '15px' }}
                      ></DropDownTreeComponent>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-6 py-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Team
                </label>
                <div className='w-100'>
                  <label
                    id='ddljobteam_orgnl'
                    typeof='text'
                    itemType='dropdowntree'
                    className={
                      this.selectedmenuid === 'changeitem' &&
                      this.selectednode.team
                        ? ''
                        : 'lablecolor-none'
                    }
                    onClick={this.handleLabelDblClick}
                  >
                    {this.selectedmenuid === 'changeitem' &&
                    this.selectednode.team
                      ? this.selectednode.team.name
                      : 'None'}
                  </label>
                  {this.state.tfieldsjob ? (
                    <div className='d-none'>
                      <DropDownTreeComponent
                        id='ddljobteam'
                        name='ddljobteam'
                        treeSettings={this.treeSettings}
                        fields={this.state.tfieldsjob}
                        filterBarPlaceholder='Search a Team'
                        allowFiltering={true}
                        filterType='Contains'
                        placeholder='Select Team'
                        popupHeight='220px'
                        style={{ fontSize: '15px' }}
                      ></DropDownTreeComponent>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-md-6 py-2'>
                <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                  Grade
                </label>
                <div className='w-100'>
                  <label
                    id='ddljobgrade_orgnl'
                    typeof='text'
                    itemType='dropdowntree'
                    className={
                      this.selectedmenuid === 'changeitem' &&
                      this.selectednode.grade
                        ? ''
                        : 'lablecolor-none'
                    }
                    onClick={this.handleLabelDblClick}
                  >
                    {this.selectedmenuid === 'changeitem' &&
                    this.selectednode.grade
                      ? this.selectednode.grade.name
                      : 'None'}
                  </label>
                  {this.state.gfieldsjob ? (
                    <div className='d-none'>
                      <DropDownTreeComponent
                        id='ddljobgrade'
                        name='ddljobgrade'
                        treeSettings={this.treeSettings}
                        fields={this.state.gfieldsjob}
                        filterBarPlaceholder='Search a Grade'
                        allowFiltering={true}
                        filterType='Contains'
                        placeholder='Select Grade'
                        popupHeight='220px'
                        style={{ fontSize: '15px' }}
                      ></DropDownTreeComponent>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className='col-md-6 py-2'></div>
            </div>
          </>
        ) : null}
      </>
    );
  }

  public setSelectedDropdownTreeValue() {
    if (this.treeObj.fields.dataSource.length > 0) {
      if (this.treeObj.fields.dataSource[0].id !== this.newitemnode.id) {
        this.treeObj.fields.dataSource.unshift(this.newitemnode);
      }
    } else {
      this.treeObj.fields.dataSource.unshift(this.newitemnode);
    }
    this.setState({
      //[this.fieldsname]: null,
      [this.fieldsname]: this.treeObj.fields,
      allocationfields: this.treeObj.fields,
    });
    setTimeout(() => {
      this.setState({
        // [this.fieldsname]: this.treeObj.fields,
        // allocationfields: this.treeObj.fields,
        alloccode: '',
        allocname: '',
        alloccustomcode: '',
        allocdesc: '',
        allocationlistdialog: false,
      });

      if (this.selectednode) {
        if (this.allocation) {
          if (this.selectednode.isgroup === false) {
            if (!this.props.contractData[this.allocation]) {
              this.props.contractData[this.allocation] = {};
            }
            if (this.props.contractData[this.allocation]) {
              this.props.contractData[this.allocation]['id'] =
                this.selectednode.id;
              this.props.contractData[this.allocation]['name'] =
                this.selectednode.name;

              if (
                this.allocation === 'grade' ||
                this.allocation === 'job' ||
                this.allocation === 'costcenter'
              ) {
                this.props.contractData[this.allocation]['code'] =
                  this.selectednode.code;

                if (this.allocation === 'costcenter') {
                  this.props.contractData[this.allocation]['customCode'] =
                    this.selectednode.customCode;
                }

                if (this.allocation === 'job') {
                  // this.props.contractData[this.allocation]['description'] =
                  //   this.selectednode.description;
                }
              }
              if (this.allocation === 'contractType') {
                this.props.contractData[this.allocation]['itemValue'] =
                  this.selectednode.itemValue;
                this.props.contractData[this.allocation]['itemType'] =
                  this.selectednode.itemType;
                this.props.contractData['commonDataId'] = this.selectednode.id;
              } else {
                this.props.contractData[this.allocation + 'Id'] =
                  this.selectednode.id;
              }
            }
            let ctrl: any = document.getElementById(
              this.ddlTree.element.id + '_orgnl'
            );
            if (ctrl) {
              if (this.selectednode.isgroup === false) {
                ctrl.classList.remove('lablecolor-none');
                //ctrl.textContent = this.selectednode.name;
                this.ddlTree.value = [this.selectednode.id];
              }
            }

            document
              .getElementById(this.ddlTree.element.id)!
              .parentElement!.parentElement!.classList.add('d-none');
            ctrl.classList.remove('d-none');
          }
        } else {
          let ctrl: any = document.getElementById(
            this.ddlTree.element.id + '_orgnl'
          );
          if (ctrl) {
            ctrl.classList.add('lablecolor-none');
          }
        }
        if (this.selectednode.isgroup === false) {
          this.contractchanged = true;
          this.setState({
            [this.allocation + 'id']: this.selectednode.id,
          });
        }
      }
    }, 1000);
  }

  handleLabelDblClick = (e: any) => {
    if (e.target.id.toString().includes('_orgnl') === false) {
      return;
    }
    // if (this.state.periodclosed === true ) {
    //   return;
    // }

    if (!this.user.isManager) {
      return;
    }

    if (
      this.props.selectorData !== undefined &&
      this.props.contractData !== undefined &&
      this.props.selectorData.contract[0].dateStart >
        this.props.contractData.dateStart
    ) {
      return;
    }

    let nonlblelmnt: any = document.getElementById(
      e.target.id.toString().replace('_orgnl', '')
    );
    if (nonlblelmnt) {
      document.getElementById(e.target.id)!.classList.add('d-none');
      nonlblelmnt.parentElement.parentElement.classList.remove('d-none');
      //nonlblelmnt.parentElement.parentElement.style.display = 'block';
      this.dblclickedctrlid = nonlblelmnt.id;

      if (e.target.attributes['itemtype'].value == 'dropdowntree') {
        let obj: any = document.getElementById(nonlblelmnt.id) as Element;
        if (obj !== null) {
          let ddtree: DropDownTreeComponent = obj
            .ej2_instances[0] as DropDownTreeComponent;
          if (ddtree) {
            ddtree.showPopup();
            if (ddtree.value && ddtree.value.length > 0) {
              ddtree.ensureVisible(ddtree.value[0]);
            }
          }
        }
      } else if (e.target.attributes['itemtype'].value == 'dropdownlist') {
        let obj: any = document.getElementById(nonlblelmnt.id) as Element;
        if (obj !== null) {
          let ddlist: DropDownListComponent = obj
            .ej2_instances[0] as DropDownListComponent;
          if (ddlist) {
            ddlist.showPopup();
          }
        }
      } else if (e.target.attributes['itemtype'].value == 'datepicker') {
        let obj: any = document.getElementById(nonlblelmnt.id) as Element;
        if (obj !== null) {
          let dtpicker: DatePickerComponent = obj
            .ej2_instances[0] as DatePickerComponent;
          if (dtpicker) {
            if (nonlblelmnt.id === 'dtpdateend') {
              dtpicker.min = this.state.minDate;
            }
            dtpicker.show();
          }
        }
      }
    }
  };

  public ResetSelectedAllocationVariables(ddltreeid: string) {
    this.endpoint = '';
    this.dialogHeader = '';
    this.allocation = '';
    this.allocationidfield = '';
    this.suballocation = '';
    this.fieldsname = '';
    this.allocfields = null;

    switch (ddltreeid) {
      case 'ddlbranch':
        this.allocation = 'branch';
        this.suballocation = 'subBranchs';
        this.allocationidfield = 'branchId';
        this.endpoint = 'allocation/addbranch';
        this.dialogHeader = 'Branch';
        this.allocfields = this.state.bfields;
        this.fieldsname = 'bfields';
        break;

      case 'ddldepartment':
        this.allocation = 'department';
        this.suballocation = 'subDepartments';
        this.allocationidfield = 'departmentId';
        this.endpoint = 'allocation/adddepartment';
        this.dialogHeader = 'Department';
        this.allocfields = this.state.dfields;
        this.fieldsname = 'dfields';
        break;

      case 'ddljob':
        this.allocation = 'job';
        this.suballocation = 'subJob';
        this.allocationidfield = 'jobId';
        this.endpoint = 'allocation/addjob';
        this.dialogHeader = 'Job';
        this.allocfields = this.state.jfields;
        this.fieldsname = 'jfields';
        break;

      case 'ddlgrade':
        this.allocation = 'grade';
        this.suballocation = 'subGrades';
        this.allocationidfield = 'gradeId';
        this.endpoint = 'grades/add';
        this.dialogHeader = 'Grade';
        this.allocfields = this.state.gfields;
        this.fieldsname = 'gfields';
        break;

      case 'ddlcostcenter':
        this.allocation = 'costcenter';
        this.suballocation = 'subCostcenters';
        this.allocationidfield = 'costcenterId';
        this.endpoint = 'costcenter/add';
        this.dialogHeader = 'Cost Center';
        this.allocfields = this.state.ccfields;
        this.fieldsname = 'ccfields';
        break;

      case 'ddlsection':
        this.allocation = 'section';
        this.suballocation = 'subSections';
        this.allocationidfield = 'sectionId';
        this.endpoint = 'allocation/addsection';
        this.dialogHeader = 'Section';
        this.allocfields = this.state.sfields;
        this.fieldsname = 'sfields';
        break;

      case 'ddlunit':
        this.allocation = 'unit';
        this.suballocation = 'subUnits';
        this.allocationidfield = 'unitId';
        this.endpoint = 'allocation/addunit';
        this.dialogHeader = 'Unit';
        this.allocfields = this.state.ufields;
        break;

      case 'ddlclass':
        this.allocation = 'class';
        this.suballocation = 'subClasses';
        this.allocationidfield = 'classId';
        this.endpoint = 'allocation/addclass';
        this.dialogHeader = 'Class';
        this.allocfields = this.state.cfields;
        this.fieldsname = 'cfields';
        break;

      case 'ddlteam':
        this.allocation = 'team';
        this.suballocation = 'subTeams';
        this.allocationidfield = 'teamId';
        this.endpoint = 'allocation/addteam';
        this.dialogHeader = 'Team';
        this.allocfields = this.state.tfields;
        this.fieldsname = 'tfields';
        break;

      default:
        return;
        break;
    }
  }

  private async IsValidContractStartDate(date: any) {
    let finalresponse: any = null;

    const filteredDates = this.state.periodlist.filter(
      (x: any) =>
        Common.formatDate(new Date(x.startDate), 'yyyyMMdd') <=
        Common.formatDate(new Date(date), 'yyyyMMdd')
    );

    if (filteredDates.length > 0) {
      const lastinfo = filteredDates[filteredDates.length - 1];

      let empids: any = [];
      empids.push(this.props.contractData.employeeId);
      let payload: any = {
        periodId: (lastinfo as any).id,
        employeeIds: empids,
      };
      await Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_PAYROLLSERVICE}/payrolldata/ispayrollprocessed`,
        payload,
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          finalresponse = response;
        })
        .catch((error: any) => {
          console.error(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.messages &&
            error.response.data.messages.length > 0
          ) {
            // DialogConfirm.showDialog({
            //   content: `<p class="dialog-contain">${error.response.data.messages.join(
            //     '<br>'
            //   )}</p>`,
            // });
            this.toastObj = ToastUtility.show({
              cssClass: 'e-toast-error',
              icon: 'far fa-circle-xmark',
              showCloseButton: true,
              content: `${error.response.data.messages.join('<br>')}`,
              target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 3000,
            });
            finalresponse = error;
          }
        })
        .finally(() => {});

      if (finalresponse) {
        return finalresponse;
      }
    }
  }

  render() {
    let cnfDlg: Dialog;

    const handleDropDownTreeChange = (e: any) => {
      if (!e.item.id) return;

      if (e.item.id) {
        let obj: any = document
          .querySelector(`#${e.item.id}`)
          ?.closest('.e-popup-close');
        if (!obj) return;

        let ctrl: any = document.querySelector(
          `#${obj.id.replace('_popup', '')}`
        );
        this.ddlTree = ctrl.ej2_instances[0] as DropDownTreeComponent;

        if (this.ddlTree === undefined) {
          return;
        }

        this.ResetSelectedAllocationVariables(this.ddlTree.element.id);

        if (this.allocfields!.dataSource[0]['id'] === Common.blankguid) {
          this.newitemnode = this.allocfields!.dataSource.shift();
        }

        this.allocfields!.selectable = '';

        if (e.itemData.id == Common.blankguid) {
          //ddllist.clear();
          this.setState({
            allocationlistdialog: true,
            allocationfields: this.allocfields,
          });
        } else {
          if (this.allocation) {
            if (!this.props.contractData[this.allocation]) {
              this.props.contractData[this.allocation] = {};
            }
            if (this.props.contractData[this.allocation]) {
              this.props.contractData[this.allocation]['id'] = e.itemData.id;
              this.props.contractData[this.allocation]['name'] =
                e.itemData.text;
              this.props.contractData[this.allocation + 'Id'] = e.itemData.id;

              let ctrl: any = document.getElementById(
                this.ddlTree.element.id + '_orgnl'
              );
              if (ctrl) {
                ctrl.classList.remove('lablecolor-none');

                let lblelmnt: any = document.getElementById(
                  this.ddlTree.element.id + '_orgnl'
                );
                if (lblelmnt) {
                  document
                    .getElementById(this.ddlTree.element.id)!
                    .parentElement!.parentElement!.classList.add('d-none');
                  lblelmnt.classList.remove('d-none');
                }
              }
            } else {
              let ctrl: any = document.getElementById(
                this.ddlTree.element.id + '_orgnl'
              );
              if (ctrl) {
                ctrl.classList.add('lablecolor-none');
              }
            }

            this.contractchanged = true;
            // this.setState({
            //   [this.allocation + 'id']: null,
            // });
            this.setState({
              refreshpropsvalue: Math.random(),
            });
          }
        }
      }
    };

    const handleDropDownTreeClear = (e: any) => {
      if (e.isInteracted && e.value.length === 0) {
        let lblelmnt: any = document.getElementById(e.element.id + '_orgnl');
        if (lblelmnt) {
          lblelmnt.classList.add('lablecolor-none');
        }

        this.ResetSelectedAllocationVariables(e.element.id);

        this.props.contractData[this.allocation] = null;
        this.props.contractData[this.allocation + 'Id'] = null;
        this.props.contractData[
          `remove${this.dialogHeader.replaceAll(' ', '')}IfNull`
        ] = true; //RemoveBranchIfNull

        this.contractchanged = true;
        // this.setState({
        //   [this.allocation + 'id']: null,
        // });
        this.setState({
          refreshpropsvalue: Math.random(),
        });
      }
    };

    const handleDropDownListChange = (e: any) => {
      if (e.item.id) {
        let obj: any = document
          .querySelector(`[id='${e.item.id}']`)
          ?.closest('.e-popup-open');
        let ctrl: any = document.querySelector(
          `#${obj.id.replace('_popup', '')}`
        );
        let ddllist: DropDownListComponent = ctrl
          .ej2_instances[0] as DropDownListComponent;

        if (ddllist === undefined) {
          return;
        }

        let allocation: string = '';
        if (ddllist.element.id === 'ddlcontracttype') {
          allocation = 'contractType';
        }

        if (allocation) {
          if (!this.props.contractData[allocation]) {
            this.props.contractData[allocation] = {};
          }
          if (this.props.contractData[allocation]) {
            this.props.contractData[allocation]['id'] = e.itemData.id;

            if (allocation === 'contractType') {
              this.props.contractData[allocation]['itemValue'] =
                e.itemData.itemValue;
              this.props.contractData[allocation]['itemType'] =
                e.itemData.itemType;
              this.props.contractData['commonDataId'] = e.itemData.id;
            } else {
              this.props.contractData[allocation + 'Id'] = e.itemData.id;
            }

            let ctrl: any = document.getElementById(
              ddllist.element.id + '_orgnl'
            );
            if (ctrl) {
              ctrl.classList.remove('lablecolor-none');

              // let lblelmnt: any = document.getElementById(
              //   ddllist.element.id + '_orgnl'
              // );
              // if (lblelmnt) {
              document
                .getElementById(ddllist.element.id)!
                .parentElement!.parentElement!.classList.add('d-none');
              ctrl.classList.remove('d-none');
              //}
            }
          } else {
            let ctrl: any = document.getElementById(
              ddllist.element.id + '_orgnl'
            );
            if (ctrl) {
              ctrl.classList.add('lablecolor-none');
            }
          }

          this.contractchanged = true;
          // this.setState({
          //   [allocation + 'id']: e.itemData.id,
          // });
          this.setState({
            refreshpropsvalue: Math.random(),
          });
        }
      }
    };

    const handleDropDownOnBlur = () => {
      if (this.dblclickedctrlid !== '') {
        let lblelmnt: any = document.getElementById(
          this.dblclickedctrlid + '_orgnl'
        );
        if (lblelmnt) {
          document
            .getElementById(this.dblclickedctrlid)!
            .parentElement!.parentElement!.classList.add('d-none');
          lblelmnt.classList.remove('d-none');
        }
      }
      this.dblclickedctrlid = '';
    };

    const handleSalaryChange = (e: any) => {
      this.props.contractData['salary'] = Number(e.value);
      this.contractchanged = true;
      this.props.refreshContract(this.props.selectorData['contract']);
    };

    const handleDatePickerOnChange = async (e: any) => {
      if (e.target.id === 'dtpdatestart') {
        if (e.value) {
          if (
            this.props.contractData['dateEnd'] &&
            new Date(e.value) > new Date(this.props.contractData['dateEnd'])
          ) {
            // DialogConfirm.showDialog({
            //   content:
            //     '<p class="dialog-contain">Sorry, Contract start date should be less than contract end date.</p>',
            // });
            this.toastObj = ToastUtility.show({
              cssClass: 'e-toast-warning',
              icon: 'e-warning toast-icons',
              showCloseButton: true,
              content:
                'Sorry, Contract start date should be less than contract end date.',
              target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 3000,
            });
            return;
          }

          if (
            Common.formatDate(this.state.minDate, 'yyyyMMdd') !==
            Common.formatDate(new Date(), 'yyyyMMdd')
          ) {
            if (
              Common.formatDate(new Date(e.value), 'yyyyMMdd') <=
              Common.formatDate(this.state.minDate, 'yyyyMMdd')
            ) {
              // DialogConfirm.showDialog({
              //   content:
              //     '<p class="dialog-contain">Sorry, Contract start date should not fall in closed period dates.</p>',
              // });
              this.toastObj = ToastUtility.show({
                cssClass: 'e-toast-warning',
                icon: 'e-warning toast-icons',
                showCloseButton: true,
                content:
                  'Sorry, Contract start date should not fall in closed period dates.',
                target: '#basic_card',
                position: { X: 'Center', Y: 'Top' },
                width: 'auto',
                timeOut: 3000,
              });
              return;
            }
          }

          let flag: any = null;
          flag = await this.IsValidContractStartDate(e.value);

          if (flag && flag == true) {
            // DialogConfirm.showDialog({
            //   content:
            //     '<p class="dialog-contain">Sorry, you cannot set this contract start date. As payroll is already processed for the previous period.</p>',
            // });
            this.toastObj = ToastUtility.show({
              cssClass: 'e-toast-warning',
              icon: 'e-warning toast-icons',
              showCloseButton: true,
              content:
                'Sorry, you cannot set this contract start date. As payroll is already processed for the previous period.',
              target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 3000,
            });
            return;
          }

          this.props.contractData['dateStart'] = Common.formatDate(
            e.value,
            'yyyy-MM-dd'
          );
          this.props.refreshContract(this.props.selectorData['contract']);
        } else {
          // DialogConfirm.showDialog({
          //   content:
          //     '<p class="dialog-contain">Please select contract start date.</p>',
          // });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-warning',
            icon: 'e-warning toast-icons',
            showCloseButton: true,
            content: 'Please select contract start date.',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          return;
        }

        this.contractchanged = true;
        this.props.refreshContract(this.props.selectorData['contract']);
        this.setState({
          refreshpropsvalue: Math.random(),
        });
      } else if (e.target.id === 'dtpdateend') {
        if (e.value) {
          if (
            this.props.contractData['dateStart'] &&
            new Date(e.value) < new Date(this.props.contractData['dateStart'])
          ) {
            // DialogConfirm.showDialog({
            //   content:
            //     '<p class="dialog-contain">Sorry, Contract end date should be greater than contract start date.</p>',
            // });
            this.toastObj = ToastUtility.show({
              cssClass: 'e-toast-warning',
              icon: 'e-warning toast-icons',
              showCloseButton: true,
              content:
                'Sorry, Contract end date should be greater than contract start date.',
              target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 3000,
            });
            return;
          }

          this.props.contractData['dateEnd'] = Common.formatDate(
            e.value,
            'yyyy-MM-dd'
          );
          this.props.refreshContract(this.props.selectorData['contract']);
        } else {
          this.props.contractData['dateEnd'] = null;
          this.props.contractData['RemoveDateEndIfNull'] = true;
          this.props.refreshContract(this.props.selectorData['contract']);
        }

        this.contractchanged = true;

        this.setState({
          refreshpropsvalue: Math.random(),
        });
      }
    };

    const dialogContent = () => {
      if (this.state.dlgcontent === 'job') {
        return (
          <DocumentViewer
            id={'wordeditor-contract'}
            indialogbox={true}
            jsonDataViewer={{
              id: this.props.contractData.job.id,
              code: this.props.contractData.job.code,
              name: this.props.contractData.job.name,
              wordTemplate: this.props.contractData.job.wordTemplate,
            }}
            selectedmenu={{ children: [] }}
            selectorService={
              this._structure.SidebarConstants.SidebarService.Job
            }
            hidetoolbar={true}
            hideFooterToolbar={true}
            restrictEditing={true}
          />
        );
      }
    };

    const handleOpenjobdocClick = (e: any) => {
      this.setState({ showdialog: true, dlgcontent: 'job' });
    };

    const handleoverlayClick = (e: any) => {
      if (this.state.dlgcontent === 'job') {
        dialogInstance.hide();
      }
    };

    const contextMenuClick = (e: any) => {
      if (e.item.items.length > 0) {
        return;
      }
      let rootMenu: any = Common.GetSelectedContextMenuRootMenu(e.item, 0);

      if (rootMenu.id === '100') {
        /* End Date */

        let obj: any = document.getElementById('dtpdateend');
        if (obj) {
          let ddl: DatePickerComponent = obj.ej2_instances[0];
          ddl.value = new Date();
          this.props.contractData['dateEnd'] = ddl.value;
          this.contractchanged = true;
          this.setState({ refreshpropsvalue: Math.random() });
        }
      } else if (rootMenu.id === '300') {
        /*Branch*/
        let obj: any = document.getElementById('ddlbranch');
        if (obj) {
          let ddl: DropDownTreeComponent = obj.ej2_instances[0];
          ddl.value = e.item.id;
          ddl.text = e.item.text;

          this.props.contractData['branch'] = {
            id: e.item.id,
            name: e.item.text,
          };
          this.props.contractData.branchId = e.item.id;

          if (rootMenu.id !== e.item.id) {
            this.contractchanged = true;
            this.setState({ refreshpropsvalue: Math.random() });
          }
        }
      } else if (rootMenu.id === '400') {
        /*Department*/
        let obj: any = document.getElementById('ddldepartment');
        if (obj) {
          let ddl: DropDownTreeComponent = obj.ej2_instances[0];
          ddl.value = e.item.id;
          ddl.text = e.item.text;

          this.props.contractData['department'] = {
            id: e.item.id,
            name: e.item.text,
          };
          this.props.contractData.departmentId = e.item.id;

          if (rootMenu.id !== e.item.id) {
            this.contractchanged = true;
            this.setState({ refreshpropsvalue: Math.random() });
          }
        }
      } else if (rootMenu.id === '500') {
        /*Job*/
        let obj: any = document.getElementById('ddljob');
        if (obj) {
          let ddl: DropDownTreeComponent = obj.ej2_instances[0];
          ddl.value = e.item.id;
          ddl.text = e.item.text;

          this.props.contractData['job'] = {
            id: e.item.id,
            name: e.item.text,
          };
          this.props.contractData.jobId = e.item.id;

          if (rootMenu.id !== e.item.id) {
            this.contractchanged = true;
            this.setState({ refreshpropsvalue: Math.random() });
          }
        }
      } else if (rootMenu.id === '600') {
        /*Grade*/
        let obj: any = document.getElementById('ddlgrade');
        if (obj) {
          let ddl: DropDownTreeComponent = obj.ej2_instances[0];
          ddl.value = e.item.id;
          ddl.text = e.item.text;

          this.props.contractData['grade'] = {
            id: e.item.id,
            name: e.item.text,
          };
          this.props.contractData.gradeId = e.item.id;

          if (rootMenu.id !== e.item.id) {
            this.contractchanged = true;
            this.setState({ refreshpropsvalue: Math.random() });
          }
        }
      } else if (rootMenu.id === '700') {
        /*Working Schedule*/
      } else if (rootMenu.id === '900') {
        /*Allowances*/
      }
    };

    var dialogInstance: DialogComponent | any;
    return (
      <>
        <div className='row my-2' id='contract-header'>
          <div
            className='col-12 d-flex align-items-center'
            style={{ height: '42px' }}
          >
            <div className='contract-header'>{this.props.contractTitle}</div>
          </div>
        </div>

        <div id='contractbody' className='contract-body row mx-1'>
          <div className='col-12 h-100'>
            <div className='row contract-content'>
              <div
                id='treegridcontractjson'
                className={`contract-json px-0 ${
                  this.props.inoffcanvas === true ? 'col-12' : 'col-md-6'
                }`}
              >
                <div className='control-pane h-100'>
                  <div className='control-section h-100'>
                    <div className='row' style={{ height: '40px' }}>
                      <div
                        className='col-5'
                        style={{
                          fontWeight: 600,
                          fontSize: '14px',
                          letterSpacing: '1px',
                          lineHeight: '32px',
                        }}
                      >
                        Contract Name :
                      </div>
                      <div
                        className='col-5'
                        style={{ fontSize: '14px', letterSpacing: '1px' }}
                      >
                        {this.props.contractData.name}
                      </div>
                    </div>
                    <div className='row' style={{ height: '40px' }}>
                      <div
                        className='col-5'
                        style={{
                          fontWeight: 600,
                          fontSize: '14px',
                          letterSpacing: '1px',
                          lineHeight: '32px',
                        }}
                      >
                        Contract Type :
                      </div>
                      <div
                        className='col-5'
                        style={{ fontSize: '14px', letterSpacing: '1px' }}
                      >
                        <label
                          id='ddlcontracttype_orgnl'
                          typeof='text'
                          itemType='dropdownlist'
                          className={
                            this.props.contractData.commonData
                              ? ''
                              : 'lablecolor-none'
                          }
                          onClick={this.handleLabelDblClick}
                        >
                          {this.props.contractData.commonData
                            ? this.props.contractData.commonData.itemValue
                            : 'None'}
                        </label>
                        {this.jsonContractType ? (
                          <div className='d-none'>
                            <DropDownListComponent
                              id='ddlcontracttype'
                              dataSource={this.jsonContractType}
                              filterBarPlaceholder='Search a Contract'
                              allowFiltering={true}
                              filterType='Contains'
                              fields={{ value: 'id', text: 'itemValue' }}
                              select={handleDropDownListChange}
                              //change={handleContractChange}
                              blur={handleDropDownOnBlur}
                              placeholder='Select Contract'
                              popupHeight='220px'
                              style={{ fontSize: '15px' }}
                              // value={this.props.contractData.commonData.id}
                            ></DropDownListComponent>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className='row' style={{ height: '40px' }}>
                      <div
                        className='col-5'
                        style={{
                          fontWeight: 600,
                          fontSize: '14px',
                          letterSpacing: '1px',
                          lineHeight: '32px',
                        }}
                      >
                        Start Date :
                      </div>
                      <div
                        className='col-5'
                        style={{ fontSize: '14px', letterSpacing: '1px' }}
                      >
                        <label
                          id='dtpdatestart_orgnl'
                          typeof='text'
                          itemType='datepicker'
                          className={
                            this.props.contractData.dateStart
                              ? ''
                              : 'lablecolor-none'
                          }
                          onClick={this.handleLabelDblClick}
                        >
                          {this.props.contractData.dateStart
                            ? Common.formatDate(
                                new Date(this.props.contractData.dateStart),
                                this.user.settings.formats.DateShort
                              )
                            : 'None'}
                        </label>
                        <div className='d-none'>
                          <DatePickerComponent
                            id='dtpdatestart'
                            name='dtpdatestart'
                            format={this.user.settings.formats.DateShort}
                            value={
                              this.props.contractData.dateStart
                                ? new Date(this.props.contractData.dateStart)
                                : undefined
                            }
                            showClearButton={false}
                            cssClass='e-hidedate-icon'
                            close={handleDropDownOnBlur}
                            openOnFocus={true}
                            onChange={handleDatePickerOnChange}
                            showTodayButton={false}
                            min={
                              this.props.prevctrenddate
                                ? this.props.prevctrenddate
                                : undefined
                            }
                            style={{ fontSize: '15px' }}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className='row'
                      style={{
                        height: '40px',
                        display: this.props.contractData.dateEnd ? '' : 'none',
                      }}
                    >
                      <div
                        className='col-5'
                        style={{
                          fontWeight: 600,
                          fontSize: '14px',
                          letterSpacing: '1px',
                          lineHeight: '32px',
                        }}
                      >
                        End Date :
                      </div>
                      <div
                        className='col-5'
                        style={{ fontSize: '14px', letterSpacing: '1px' }}
                      >
                        <label
                          id='dtpdateend_orgnl'
                          typeof='text'
                          itemType='datepicker'
                          className={
                            this.props.contractData.dateEnd
                              ? ''
                              : 'lablecolor-none'
                          }
                          onClick={this.handleLabelDblClick}
                        >
                          {this.props.contractData.dateEnd
                            ? Common.formatDate(
                                new Date(this.props.contractData.dateEnd),
                                this.user.settings.formats.DateShort
                              )
                            : 'None'}
                        </label>
                        <div className='d-none'>
                          <DatePickerComponent
                            id='dtpdateend'
                            name='dtpdateend'
                            format={this.user.settings.formats.DateShort}
                            value={
                              this.props.contractData.dateEnd
                                ? new Date(this.props.contractData.dateEnd)
                                : undefined
                            }
                            showClearButton={true}
                            cssClass='e-hidedate-icon'
                            close={handleDropDownOnBlur}
                            openOnFocus={true}
                            onChange={handleDatePickerOnChange}
                            showTodayButton={false}
                            style={{ fontSize: '15px' }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='row' style={{ height: '40px' }}>
                      <div
                        className='col-5'
                        style={{
                          fontWeight: 600,
                          fontSize: '14px',
                          letterSpacing: '1px',
                          lineHeight: '32px',
                        }}
                      >
                        Salary :
                      </div>
                      <div
                        className='col-5'
                        style={{ fontSize: '14px', letterSpacing: '1px' }}
                      >
                        <NumericTextBoxComponent
                          id='txtSalary'
                          value={this.props.contractData.salary}
                          //format={this.user.settings.formats.Decimal}
                          decimals={
                            this.user.settings.formats.Decimal.includes('.')
                              ? this.user.settings.formats.Decimal.split(
                                  '.'
                                ).pop().length
                              : null
                          }
                          //currency={'USD'} // CURRENCY TO BE PICKED FROM COMPANY
                          // format={`${
                          //   this.user.settings.formats.Decimal.includes('.')
                          //     ? this.user.settings.formats.Decimal.split(
                          //         '.'
                          //       ).pop().length
                          //     : 2
                          // }`}
                          format={`${this.user.currencySign} 0.${
                            this.user.settings.formats.Decimal.includes('.')
                              ? '0'.repeat(
                                  this.user.settings.formats.Decimal.split(
                                    '.'
                                  ).pop().length
                                )
                              : '0'.repeat(2)
                          }`}
                          showSpinButton={false}
                          cssClass='textbox-noborder'
                          change={handleSalaryChange}
                          created={(e: any) => {
                            document
                              .querySelector('#txtSalary')
                              ?.addEventListener('paste', (e: any) => {
                                e.target.ej2_instances[0].value =
                                  e.clipboardData
                                    .getData('Text')
                                    .replaceAll(',', '');
                              });
                          }}
                          enabled={
                            !this.state.periodclosed && this.user.isManager
                          }
                        />
                      </div>
                    </div>

                    <div
                      className='row'
                      style={{
                        height: '40px',
                        display: this.props.contractData.branch ? '' : 'none',
                      }}
                    >
                      <div
                        className='col-5'
                        style={{
                          fontWeight: 600,
                          fontSize: '14px',
                          letterSpacing: '1px',
                          lineHeight: '32px',
                        }}
                      >
                        Branch :
                      </div>
                      <div
                        className='col-5'
                        style={{ fontSize: '14px', letterSpacing: '1px' }}
                      >
                        <label
                          id='ddlbranch_orgnl'
                          typeof='text'
                          itemType='dropdowntree'
                          className={
                            this.props.contractData.branch
                              ? ''
                              : 'lablecolor-none'
                          }
                          onClick={this.handleLabelDblClick}
                        >
                          {this.props.contractData.branch
                            ? this.props.contractData.branch.name
                            : 'None'}
                        </label>
                        {this.state.bfields ? (
                          <div className='d-none'>
                            <DropDownTreeComponent
                              id='ddlbranch'
                              name='ddlbranch'
                              treeSettings={this.treeSettings}
                              fields={this.state.bfields}
                              filterBarPlaceholder='Search a Branch'
                              allowFiltering={true}
                              filterType='Contains'
                              select={handleDropDownTreeChange}
                              change={handleDropDownTreeClear}
                              blur={handleDropDownOnBlur}
                              placeholder='Select Branch'
                              popupHeight='220px'
                              style={{ fontSize: '15px' }}
                              value={
                                this.props.contractData.branch
                                  ? [this.props.contractData.branch.id]
                                  : []
                              }
                            ></DropDownTreeComponent>
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div
                      className='row'
                      style={{
                        height: '40px',
                        display: this.props.contractData.department
                          ? ''
                          : 'none',
                      }}
                    >
                      <div
                        className='col-5'
                        style={{
                          fontWeight: 600,
                          fontSize: '14px',
                          letterSpacing: '1px',
                          lineHeight: '32px',
                        }}
                      >
                        Department :
                      </div>
                      <div
                        className='col-5'
                        style={{ fontSize: '14px', letterSpacing: '1px' }}
                      >
                        <label
                          id='ddldepartment_orgnl'
                          typeof='text'
                          itemType='dropdowntree'
                          className={
                            this.props.contractData.department
                              ? ''
                              : 'lablecolor-none'
                          }
                          onClick={this.handleLabelDblClick}
                        >
                          {this.props.contractData.department
                            ? this.props.contractData.department.name
                            : 'None'}
                        </label>
                        {this.state.dfields ? (
                          <div className='d-none'>
                            <DropDownTreeComponent
                              id='ddldepartment'
                              name='ddldepartment'
                              treeSettings={this.treeSettings}
                              fields={this.state.dfields}
                              filterBarPlaceholder='Search a Department'
                              allowFiltering={true}
                              filterType='Contains'
                              select={handleDropDownTreeChange}
                              change={handleDropDownTreeClear}
                              blur={handleDropDownOnBlur}
                              placeholder='Select Department'
                              popupHeight='220px'
                              style={{ fontSize: '15px' }}
                              value={
                                this.props.contractData.department
                                  ? [this.props.contractData.department.id]
                                  : []
                              }
                            ></DropDownTreeComponent>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`h-100 ${
                  this.props.inoffcanvas === true ? 'col-12' : 'col-md-6'
                }`}
              >
                <div
                  className='row'
                  style={{
                    display: this.props.contractData.job ? '' : 'none',
                  }}
                >
                  <div
                    className='col-4'
                    style={{
                      fontWeight: 600,
                      fontSize: '14px',
                      letterSpacing: '1px',
                      lineHeight: '32px',
                    }}
                  >
                    Job :
                  </div>
                  <div
                    className='col-7'
                    style={{
                      fontSize: '14px',
                      letterSpacing: '1px',
                    }}
                  >
                    <label
                      id='ddljob_orgnl'
                      typeof='text'
                      itemType='dropdowntree'
                      className={
                        this.props.contractData.job ? '' : 'lablecolor-none'
                      }
                      onClick={this.handleLabelDblClick}
                    >
                      {this.props.contractData.job
                        ? this.props.contractData.job.name
                        : 'None'}
                      {this.props.inoffcanvas === false &&
                        this.props.contractData.job && (
                          <div
                            id='openjobdoc'
                            title='Show Job Description'
                            className='fa-solid fa-up-right-from-square cursor-pointer ms-3'
                            onClick={handleOpenjobdocClick}
                          ></div>
                        )}
                    </label>
                    {this.state.jfields ? (
                      <div className='d-none'>
                        <DropDownTreeComponent
                          id='ddljob'
                          name='ddljob'
                          treeSettings={this.treeSettings}
                          fields={this.state.jfields}
                          filterBarPlaceholder='Search a Job'
                          allowFiltering={true}
                          filterType='Contains'
                          select={handleDropDownTreeChange}
                          change={handleDropDownTreeClear}
                          blur={handleDropDownOnBlur}
                          placeholder='Select Job'
                          popupHeight='220px'
                          style={{ fontSize: '15px' }}
                          value={
                            this.props.contractData.job
                              ? [this.props.contractData.job.id]
                              : []
                          }
                        ></DropDownTreeComponent>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div
                  className='row'
                  style={{
                    display: this.props.contractData.grade ? '' : 'none',
                  }}
                >
                  <div
                    className='col-4'
                    style={{
                      fontWeight: 600,
                      fontSize: '14px',
                      letterSpacing: '1px',
                      lineHeight: '32px',
                    }}
                  >
                    Grade :
                  </div>
                  <div
                    className='col-5'
                    style={{
                      fontSize: '14px',
                      letterSpacing: '1px',
                    }}
                  >
                    <label
                      id='ddlgrade_orgnl'
                      typeof='text'
                      itemType='dropdowntree'
                      className={
                        this.props.contractData.grade ? '' : 'lablecolor-none'
                      }
                      onClick={this.handleLabelDblClick}
                    >
                      {this.props.contractData.grade
                        ? this.props.contractData.grade.name
                        : 'None'}
                    </label>
                    {this.state.gfields ? (
                      <div className='d-none'>
                        <DropDownTreeComponent
                          id='ddlgrade'
                          name='ddlgrade'
                          treeSettings={this.treeSettings}
                          fields={this.state.gfields}
                          filterBarPlaceholder='Search a Grade'
                          allowFiltering={true}
                          filterType='Contains'
                          select={handleDropDownTreeChange}
                          change={handleDropDownTreeClear}
                          blur={handleDropDownOnBlur}
                          placeholder='Select Grade'
                          popupHeight='220px'
                          style={{ fontSize: '15px' }}
                          value={
                            this.props.contractData.grade
                              ? [this.props.contractData.grade.id]
                              : []
                          }
                        ></DropDownTreeComponent>
                      </div>
                    ) : null}
                  </div>
                </div>

                {this.props.contractData.costcenter ? (
                  <div className='row' style={{ height: '40px' }}>
                    <div
                      className='col-4'
                      style={{
                        fontWeight: 600,
                        fontSize: '14px',
                        letterSpacing: '1px',
                        lineHeight: '32px',
                      }}
                    >
                      Cost Center :
                    </div>
                    <div
                      className='col-5'
                      style={{
                        fontSize: '14px',
                        letterSpacing: '1px',
                      }}
                    >
                      <label
                        id='ddlcostcenter_orgnl'
                        typeof='text'
                        itemType='dropdowntree'
                        className={
                          this.props.contractData.costcenter
                            ? ''
                            : 'lablecolor-none'
                        }
                        onClick={this.handleLabelDblClick}
                      >
                        {this.props.contractData.costcenter
                          ? this.props.contractData.costcenter.name
                          : 'None'}
                      </label>
                      {this.state.ccfields ? (
                        <div className='d-none'>
                          <DropDownTreeComponent
                            id='ddlcostcenter'
                            name='ddlcostcenter'
                            treeSettings={this.treeSettings}
                            fields={this.state.ccfields}
                            filterBarPlaceholder='Search a Cost Center'
                            allowFiltering={true}
                            filterType='Contains'
                            select={handleDropDownTreeChange}
                            change={handleDropDownTreeClear}
                            blur={handleDropDownOnBlur}
                            placeholder='Select Cost Center'
                            popupHeight='220px'
                            style={{ fontSize: '15px' }}
                            value={
                              this.props.contractData.costcenter
                                ? [this.props.contractData.costcenter.id]
                                : []
                            }
                          ></DropDownTreeComponent>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {this.props.contractData.class ? (
                  <div className='row' style={{ height: '40px' }}>
                    <div
                      className='col-4'
                      style={{
                        fontWeight: 600,
                        fontSize: '14px',
                        letterSpacing: '1px',
                        lineHeight: '32px',
                      }}
                    >
                      Class :
                    </div>
                    <div
                      className='col-5'
                      style={{
                        fontSize: '14px',
                        letterSpacing: '1px',
                      }}
                    >
                      <label
                        id='ddlclass_orgnl'
                        typeof='text'
                        itemType='dropdowntree'
                        className={
                          this.props.contractData.class ? '' : 'lablecolor-none'
                        }
                        onClick={this.handleLabelDblClick}
                      >
                        {this.props.contractData.class
                          ? this.props.contractData.class.name
                          : 'None'}
                      </label>
                      {this.state.cfields ? (
                        <div className='d-none'>
                          <DropDownTreeComponent
                            id='ddlclass'
                            name='ddlclass'
                            treeSettings={this.treeSettings}
                            fields={this.state.cfields}
                            filterBarPlaceholder='Search a Class'
                            allowFiltering={true}
                            filterType='Contains'
                            select={handleDropDownTreeChange}
                            change={handleDropDownTreeClear}
                            blur={handleDropDownOnBlur}
                            placeholder='Select Class'
                            popupHeight='220px'
                            style={{ fontSize: '15px' }}
                            value={
                              this.props.contractData.class
                                ? [this.props.contractData.class.id]
                                : []
                            }
                          ></DropDownTreeComponent>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {this.props.contractData.section ? (
                  <div className='row' style={{ height: '40px' }}>
                    <div
                      className='col-4'
                      style={{
                        fontWeight: 600,
                        fontSize: '14px',
                        letterSpacing: '1px',
                        lineHeight: '32px',
                      }}
                    >
                      Section :
                    </div>
                    <div
                      className='col-5'
                      style={{
                        fontSize: '14px',
                        letterSpacing: '1px',
                      }}
                    >
                      <label
                        id='ddlsection_orgnl'
                        typeof='text'
                        itemType='dropdowntree'
                        className={
                          this.props.contractData.section
                            ? ''
                            : 'lablecolor-none'
                        }
                        onClick={this.handleLabelDblClick}
                      >
                        {this.props.contractData.section
                          ? this.props.contractData.section.name
                          : 'None'}
                      </label>
                      {this.state.sfields ? (
                        <div className='d-none'>
                          <DropDownTreeComponent
                            id='ddlsection'
                            name='ddlsection'
                            treeSettings={this.treeSettings}
                            fields={this.state.sfields}
                            filterBarPlaceholder='Search a Section'
                            allowFiltering={true}
                            filterType='Contains'
                            select={handleDropDownTreeChange}
                            change={handleDropDownTreeClear}
                            blur={handleDropDownOnBlur}
                            placeholder='Select Section'
                            popupHeight='220px'
                            style={{ fontSize: '15px' }}
                            value={
                              this.props.contractData.section
                                ? [this.props.contractData.section.id]
                                : []
                            }
                          ></DropDownTreeComponent>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {this.props.contractData.unit ? (
                  <div className='row' style={{ height: '40px' }}>
                    <div
                      className='col-4'
                      style={{
                        fontWeight: 600,
                        fontSize: '14px',
                        letterSpacing: '1px',
                        lineHeight: '32px',
                      }}
                    >
                      Unit :
                    </div>
                    <div
                      className='col-5'
                      style={{
                        fontSize: '14px',
                        letterSpacing: '1px',
                      }}
                    >
                      <label
                        id='ddlunit_orgnl'
                        typeof='text'
                        itemType='dropdowntree'
                        className={
                          this.props.contractData.unit ? '' : 'lablecolor-none'
                        }
                        onClick={this.handleLabelDblClick}
                      >
                        {this.props.contractData.unit
                          ? this.props.contractData.unit.name
                          : 'None'}
                      </label>
                      {this.state.ufields ? (
                        <div className='d-none'>
                          <DropDownTreeComponent
                            id='ddlunit'
                            name='ddlunit'
                            treeSettings={this.treeSettings}
                            fields={this.state.ufields}
                            filterBarPlaceholder='Search a Unit'
                            allowFiltering={true}
                            filterType='Contains'
                            select={handleDropDownTreeChange}
                            change={handleDropDownTreeClear}
                            blur={handleDropDownOnBlur}
                            placeholder='Select Unit'
                            popupHeight='220px'
                            style={{ fontSize: '15px' }}
                            value={
                              this.props.contractData.unit
                                ? [this.props.contractData.unit.id]
                                : []
                            }
                          ></DropDownTreeComponent>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                {this.props.contractData.team ? (
                  <div className='row' style={{ height: '40px' }}>
                    <div
                      className='col-4'
                      style={{
                        fontWeight: 600,
                        fontSize: '14px',
                        letterSpacing: '1px',
                        lineHeight: '32px',
                      }}
                    >
                      Team :
                    </div>
                    <div
                      className='col-5'
                      style={{
                        fontSize: '14px',
                        letterSpacing: '1px',
                      }}
                    >
                      <label
                        id='ddlteam_orgnl'
                        typeof='text'
                        itemType='dropdowntree'
                        className={
                          this.props.contractData.team ? '' : 'lablecolor-none'
                        }
                        onClick={this.handleLabelDblClick}
                      >
                        {this.props.contractData.team
                          ? this.props.contractData.team.name
                          : 'None'}
                      </label>
                      {this.state.tfields ? (
                        <div className='d-none'>
                          <DropDownTreeComponent
                            id='ddlteam'
                            name='ddlteam'
                            treeSettings={this.treeSettings}
                            fields={this.state.tfields}
                            filterBarPlaceholder='Search a Team'
                            allowFiltering={true}
                            filterType='Contains'
                            select={handleDropDownTreeChange}
                            change={handleDropDownTreeClear}
                            blur={handleDropDownOnBlur}
                            placeholder='Select Team'
                            popupHeight='220px'
                            style={{ fontSize: '15px' }}
                            value={
                              this.props.contractData.team
                                ? [this.props.contractData.team.id]
                                : []
                            }
                          ></DropDownTreeComponent>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <ContextMenuComponent
            id='cntcontractfields'
            target={
              !this.props.selectorData ||
              this.props.selectorData.contract[0].dateStart >
                this.props.contractData.dateStart
                ? ''
                : '#contractbody'
            }
            items={this.state.contextmenuItems}
            onClick={contextMenuClick}
            select={contextMenuClick}
            cssClass='custom-context-menu'
          />
        </div>
        {this.state.showdialog === true ? (
          <DialogComponent
            id='defaultDialog-dossier'
            showCloseIcon={true}
            visible={this.state.showdialog}
            width={
              this.state.dlgcontent === 'group' ||
              this.state.dlgcontent === 'account'
                ? '380px'
                : '40%'
            }
            height={
              this.state.dlgcontent === 'group' ||
              this.state.dlgcontent === 'account'
                ? '180px'
                : '90%'
            }
            style={{ overflow: 'auto' }}
            target={'#root'}
            ref={(dialog) => (dialogInstance = dialog)}
            close={() => this.setState({ showdialog: false })}
            content={dialogContent}
            //footerTemplate={this.state.showjobdoc ? null : footerContent}
            position={
              this.state.dlgcontent === 'group' ||
              this.state.dlgcontent === 'account'
                ? { X: 'center', Y: 'center' }
                : { X: 'right', Y: 'center' }
            }
            animationSettings={{
              effect:
                this.state.dlgcontent === 'group' ||
                this.state.dlgcontent === 'account'
                  ? 'Zoom'
                  : 'SlideRight',
            }}
            isModal={this.state.dlgcontent === 'job' ? true : true}
            statelessTemplates={[]}
            header={
              this.state.dlgcontent === 'group'
                ? 'Create Group'
                : this.state.dlgcontent === 'account'
                ? 'Create Account'
                : this.state.dlgcontent === 'job'
                ? this.props.contractData.job.title
                : 'Create'
            }
            overlayClick={handleoverlayClick}
          ></DialogComponent>
        ) : null}
        {this.state.allocationlistdialog === true ? (
          <DialogComponent
            id='defaultDialog-allocatonlist'
            showCloseIcon={true}
            visible={this.state.allocationlistdialog}
            width={'25vw'}
            close={this.setSelectedDropdownTreeValue.bind(this)}
            content={this.AllocationListDialogContent.bind(this)}
            isModal={true}
            header={`${this.dialogHeader} List`}
            buttons={[this.DialogButtonModel[0]]}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null}
        {this.state.allocationchangedialog === true ? (
          <DialogComponent
            id='defaultDialog-allocatonchange'
            showCloseIcon={true}
            visible={this.state.allocationchangedialog}
            width={'25vw'}
            close={() => this.setState({ allocationchangedialog: false })}
            content={this.AllocationChangeDialogContent.bind(this)}
            isModal={true}
            header={`Add / Change ${this.dialogHeader} ${
              this.selectedmenuid == 'newgroup' ||
              (this.selectedmenuid == 'changeitem' &&
                this.selectednode &&
                this.selectednode.isgroup === true)
                ? 'Group'
                : ''
            }`}
            buttons={this.DialogButtonModel}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null}
      </>
    );
  }
}

export default Contract;
