import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

export function WithRouter<T>(Component: React.ComponentType<T>) {
  function ComponentWithRouterProp(props: T, ref: React.Ref<any>) {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} ref={ref} />;
  }

  return forwardRef(ComponentWithRouterProp);
}
