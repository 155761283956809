import React, { createRef } from 'react';
import {
  HtmlEditor,
  Inject,
  Link,
  RichTextEditorComponent,
  Toolbar,
} from '@syncfusion/ej2-react-richtexteditor';
import Common from '../../../Util/Common';
import './Post.css';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { title } from 'process';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';

const { REACT_APP_ENDPOINT_CORESERVICE } = process.env;

interface Props {
  handleCancelClick?: (e: any) => void;
  handleSaveClick?: (e: any) => void;
}

export default class Post extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private toastObj: any;
  private fileInputRef = createRef<HTMLInputElement>();

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = { media: [], currentIndex: 0, slideDirection: '', title: '' };

  componentDidMount(): void {}

  render() {
    const onRichTextChange = (e: any) => {
      let obj: any = document.querySelector(
        '#divcreatepost #post-title-richtext'
      );

      let key: string = 'title';
      let value: string = e.value;

      this.setState({ [key]: value });
    };

    const onCancelClick = (e: any) => {
      let obj: any = document.querySelector('#defaultDialog-createpost');

      if (obj) {
        obj.ej2_instances[0].hide();
      }

      //if (this.props.handleCancelClick) this.props.handleCancelClick(e);
    };

    const onSaveClick = (e: any) => {
      if (IsValid() === false) return false;

      const formData = new FormData();
      formData.append('title', this.state.title);
      formData.append('textContent', '');
      for (let i = 0; i <= this.state.media.length - 1; i++) {
        formData.append(`media[${i}].file`, (this.state.media[i] as any).file);
        formData.append(`media[${i}].storageProviderId`, '1');
      }

      Common.ShowSpinner();

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/post/set`,
        formData,
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((data: any) => {
          let obj: any = document.querySelector('#defaultDialog-createpost');

          if (obj) {
            obj.ej2_instances[0].hide();
          }
          if (this.props.handleSaveClick) this.props.handleSaveClick(data);
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {
          Common.HideSpinner();
        });
    };

    const ValidateForm = () => {
      const divs = [
        document.querySelector<HTMLInputElement>(
          '#post-title-richtext_rte-edit-view'
        ),
      ];

      divs.forEach((input: any) => {
        if (input) {
          input.classList.remove('textbox-border-error');

          if (input.textContent.trim() === '' && this.state.media.length <= 0) {
            input.classList.add('textbox-border-error');
          } else {
            // input.classList.add('textbox-class');
          }
        }
      });

      // const inputs = [
      //   document.querySelector<HTMLInputElement>(
      //     '#post-title-richtext_rte-edit-view'
      //   ),
      // ];

      // inputs.forEach((input) => {
      //   if (input) {
      //     input.classList.remove('textbox-border-error');

      //     if (input.value.trim() === '') {
      //       input.classList.add('textbox-border-error');
      //     } else {
      //       input.classList.add('textbox-class');
      //     }
      //   }
      // });
    };

    const IsValid = (): boolean => {
      ValidateForm();

      let obj: any = document.querySelector(
        '#divcreatepost #post-title-richtext'
      );

      if (obj) {
        let rtb: any = obj.ej2_instances[0];
        if (rtb) {
          if (rtb.getText().trim() === '' && this.state.media.length <= 0) {
            const TargetInput: any = document.querySelector(
              '#divcreatepost #post-title-richtext'
            );
            if (TargetInput) {
              TargetInput.style.borderBottom = '2px solid red';
            }
            return false;
          }
        }
      }

      return true;
    };

    const onOpenFileClick = (e: any) => {
      if (this.fileInputRef.current) {
        this.fileInputRef.current.click();
      } else {
        console.error('File input reference is not available.');
      }
    };

    const handleImageChange = (e: any) => {
      const file = e.target.files[0];
      if (!file) return;

      if (file && file.type.startsWith('image/')) {
        if (
          this.state.media.findIndex(
            (x: any) =>
              x.file.type.startsWith('image/') && x.file.name === file.name
          ) >= 0
        ) {
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-warning',
            icon: 'e-warning toast-icons',
            showCloseButton: true,
            content: 'File with the same name is already exist!',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          e.cancel = true;
          return;
        }
        const URL = window.URL;
        const url = URL.createObjectURL((e.target as any).files[0]);

        let media: any[] = this.state.media;
        media.push({ file: file, preview: url.toString() });
        if (media.length > 0) {
          document
            .querySelector('#divcreatepost .posted-body')
            ?.classList.add('has-image');
        } else {
          document
            .querySelector('#divcreatepost .posted-body')
            ?.classList.remove('has-image');
        }

        this.setState({ media: media });
      } else if (file && file.type.startsWith('video/')) {
        if (
          this.state.media.findIndex(
            (x: any) =>
              x.file.type.startsWith('video/') && x.file.name === file.name
          ) >= 0
        ) {
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-warning',
            icon: 'e-warning toast-icons',
            showCloseButton: true,
            content: 'File with the same name is already exist!',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          e.cancel = true;
          return;
        } else if (
          this.state.media.findIndex((x: any) =>
            x.file.type.startsWith('video/')
          ) >= 0
        ) {
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-warning',
            icon: 'e-warning toast-icons',
            showCloseButton: true,
            content: 'Sorry, You cannot post more than one video at a time.',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          e.cancel = true;
          return;
        }
        const URL = window.URL;
        const url = URL.createObjectURL((e.target as any).files[0]);

        let media: any[] = this.state.media;
        media.push({ file: file, preview: url.toString() });
        if (media.length > 0) {
          document
            .querySelector('#divcreatepost .posted-body')
            ?.classList.add('has-image');
        } else {
          document
            .querySelector('#divcreatepost .posted-body')
            ?.classList.remove('has-image');
        }
        this.setState({ media: media });
      } else {
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-warning',
          icon: 'e-warning toast-icons',
          showCloseButton: true,
          content: 'Please upload a valid image file!',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
      }
    };

    let buttons: any[] = [
      {
        click: onOpenFileClick,
        buttonModel: {
          id: 'openfile',
          content: '',
          cssClass: 'far fa-image openfile',
          title: 'Image',
        },
      },
      {
        //click: this.dlgDoneButtonClick.bind(this),
        buttonModel: {
          id: 'document',
          content: '',
          cssClass: 'far fa-file-lines document',
          title: 'Document',
        },
      },
      {
        //click: this.dlgDoneButtonClick.bind(this),
        buttonModel: {
          id: 'poll',
          content: '',
          cssClass: 'far fa-calendar event',
          title: 'Event',
        },
      },
      {
        //click: this.dlgDoneButtonClick.bind(this),
        buttonModel: {
          id: 'poll',
          content: '',
          cssClass: 'far fa-chart-simple poll',
          title: 'Poll',
        },
      },
      {
        //click: this.dlgDoneButtonClick.bind(this),
        buttonModel: {
          id: 'smile',
          content: '',
          cssClass: 'far fa-face-smile smile',
          title: '',
        },
      },
    ];

    const handlePreviousClick = (e: any) => {
      if (this.state.currentIndex > 0) {
        this.setState({
          slideDirection: 'slide-right',
        });
        setTimeout(() => {
          this.setState({
            currentIndex: this.state.currentIndex - 2,
            slideDirection: '',
          });
        }, 300);
      }
    };

    const handleNextClick = (e: any) => {
      if (this.state.currentIndex + 2 < this.state.media.length) {
        this.setState({
          slideDirection: 'slide-left',
        });
        setTimeout(() => {
          this.setState({
            currentIndex: this.state.currentIndex + 2,
            slideDirection: '',
          });
        }, 300);
      }
    };

    const handleImageCloseClick = (e: any) => {
      let img: any = e.target.closest('.divimg').querySelector('img');
      if (!img) {
        img = e.target.closest('.divimg').querySelector('video source');
      }
      let idx: number = this.state.media.findIndex(
        (x: any) => x.preview === img.src
      );

      let media: any = this.state.media;
      media.splice(idx, 1);

      this.setState({
        media: media,
        currentIndex:
          idx > 0 ? (idx / 2 === 0 || idx === 1 ? idx - 1 : idx - 2) : 0,
      });
    };

    return (
      <>
        <div id='divcreatepost'>
          <div className='posted-contain'>
            <div className='posted-userimg e-avatar e-avatar-circle'>
              {!this.user ? (
                <img src='arutilogo192.png' alt='user_pic' />
              ) : this.user.person && this.user.person.image ? (
                <img
                  src={
                    this.user.person && this.user.person.image
                      ? `data:image/png;base64,${this.user.person.image}`
                      : ''
                  }
                  alt='user_image'
                />
              ) : this.user.person ? (
                this.user.person.firstname[0].toUpperCase() +
                this.user.person.lastname[0].toUpperCase()
              ) : (
                this.user.firstName[0].toUpperCase() +
                this.user.lastName[0].toUpperCase()
              )}
            </div>
            <div className='posted-userinfo'>
              <span className='posted-username'>
                {!this.user
                  ? 'Aruti'
                  : !this.user.person
                  ? Common.toTitleCase(this.user.firstName) +
                    ' ' +
                    Common.toTitleCase(this.user.lastName)
                  : Common.toTitleCase(this.user.person.firstname) +
                    ' ' +
                    Common.toTitleCase(this.user.person.lastname)}
              </span>
              <span className='posted-days'></span>
            </div>
          </div>
          <div className='posted-body'>
            <RichTextEditorComponent
              id='post-title-richtext'
              className='post-title'
              //   ref={(richtexteditor) => {
              //     rteObj = richtexteditor;
              //   }}
              placeholder='What do you want to talk about?'
              toolbarSettings={{
                enable: true,
                items: [
                  'Bold',
                  'Italic',
                  'Underline',
                  '|',
                  // 'Formats',
                  // 'Alignments',
                  // 'OrderedList',
                  // 'UnorderedList',
                  // '|',
                  'CreateLink',
                  // 'Image',
                  '|',
                  // 'SourceCode',
                  'Undo',
                  'Redo',
                ],
              }}
              change={onRichTextChange}
            >
              <Inject services={[HtmlEditor, Link, Toolbar]} />
            </RichTextEditorComponent>
            <div className={`preview-div ${this.state.slideDirection}`}>
              {this.state.media
                .slice(this.state.currentIndex, this.state.currentIndex + 2)
                .map((img: any, index: number) => (
                  <div className='divimg' key={index}>
                    {img.file.type.startsWith('image') ? (
                      <img
                        src={img.preview}
                        alt={`Preview ${this.state.currentIndex + index}`}
                        className='preview-image'
                      />
                    ) : img.file.type.startsWith('video') ? (
                      <video className='preview-image' controls>
                        <source src={`${img.preview}`} type={img.file.type} />
                        Your browser does not support the video tag.
                      </video>
                    ) : null}

                    <ButtonComponent
                      content=''
                      className='e-btn e-flat e-round close'
                      iconCss='far fa-xmark right-arrow'
                      onClick={handleImageCloseClick}
                    />
                  </div>
                ))}
              {this.state.media.length > 0 && (
                <div className='navigation'>
                  <ButtonComponent
                    content=''
                    className='e-btn e-flat e-round'
                    iconCss='far fa-arrow-left right-arrow'
                    disabled={this.state.currentIndex === 0}
                    onClick={handlePreviousClick}
                  />
                  <ButtonComponent
                    content=''
                    cssClass='e-btn e-flat e-flat e-round'
                    iconCss='far fa-arrow-right right-arrow'
                    disabled={
                      this.state.currentIndex + 2 >= this.state.media.length
                    }
                    onClick={handleNextClick}
                  />
                </div>
              )}
            </div>

            <div className='posted-actions'>
              {buttons.map((button: any, index: number) => {
                return button.buttonModel.id === 'openfile' ? (
                  <div key={index}>
                    <input
                      type='file'
                      id='img-upload'
                      ref={this.fileInputRef}
                      className='e-custom-file d-none'
                      onChange={handleImageChange}
                      accept='image/*,video/*'
                    />
                    <ButtonComponent
                      {...button.buttonModel}
                      onClick={button.click}
                    />
                  </div>
                ) : (
                  <ButtonComponent
                    key={index}
                    {...button.buttonModel}
                    style={{ color: 'lightgray' }}
                    //onClick={button.click} // Invoke the respective click handler
                  />
                );
              })}

              {/* <button>
                <i className='far fa-image' title='Image'></i>
              </button> */}
              {/* <div className='button-container'>
                <input
                  type='file'
                  id='img-upload'
                  //ref={this.fileInputRef}
                  className='e-custom-file d-none'
                  //onChange={this.fileChanged.bind(this)}
                  accept='image/*'
                />
                <ButtonComponent
                  content=''
                  cssClass='far fa-image openfile'
                  //{...this.beginButtons[0].buttonModel}
                  //onClick={this.handleFirstButtonClick} // First button click handler
                ></ButtonComponent>
              </div>
              <button>
                <i className='far fa-file-lines' title='Document'></i>
              </button>
              <button>
                <i className='far fa-calendar' title='Event'></i>
              </button>
              <button>
                <i className='far fa-chart-simple' title='Poll'></i>
              </button>
              <button className='smile'>
                <i className='far fa-face-smile'></i>
              </button> */}
            </div>
          </div>
          <div className='posted-footer'>
            <button
              id='btnCancel'
              className='e-control e-btn e-lib flat-button e-flat discard'
              onClick={onCancelClick}
            >
              Discard
            </button>

            <ButtonComponent
              cssClass='e-outline posted-post'
              title='Post'
              //content='Post'
              onClick={onSaveClick}
            >
              Post<div className='far fa-arrow-right right-arrow'></div>
            </ButtonComponent>
          </div>
        </div>
      </>
    );
  }
}
