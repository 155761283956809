// * ---------------------------------------------------------------------------------------------------------------1.Aknowlege
// * Sandeep - 2023-09-25 - 430 - Worked on the document fixes and employee dialog box fixes and trying to add employee.
// * Sandeep - 2023-09-26 - 120 - Worked on the alignment fixes and some css for color.

// * ---------------------------------------------------------------------------------------------------------------2.Initialize
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DateTime } from '@syncfusion/ej2-react-charts';
import { ContextMenuComponent } from '@syncfusion/ej2-react-navigations';
import {
  ChangeEventArgs,
  DropDownTree,
  DropDownListComponent,
  DropDownTreeComponent,
  FilteringEventArgs,
  TreeSettingsModel,
} from '@syncfusion/ej2-react-dropdowns';
import React, { Component } from 'react';
import Common from '../Util/Common';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import {
  NumericTextBoxComponent,
  TextBoxComponent,
} from '@syncfusion/ej2-react-inputs';
import '../App.css';
import DialogConfirm from './Dialogbox/DialogConfirm';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
interface personbiomodel {
  isAdd?: boolean;
  selectorService?: any;
  firstname?: string;
  middlename?: string;
  lastname?: string;
  gender?: string;
  date?: Date;
  birthplace?: string;
  nationalityid?: string;
  nationality?: string;
}

interface employeebiomodel {
  isAdd?: boolean;
  selectorService?: any;
  personname?: string;
  personid?: string;
  jsonperson?: any;
  showDialog?: boolean;
  appointmentdate?: DateTime;
  contractid?: string;
  // companyid?: number;
  birthdate?: DateTime;
  jobid?: string;
  branchid?: string;
  departmentid?: string;
  scale?: Number;
  showDialogContractType?: boolean;
  contract_type?: string;
  email?: string;
  personEmail?: string;
  employeecode?: string;
  showDialogDepartment?: boolean;
  department?: string;
  showDialogBranch?: boolean;
  branch?: string;
  showDialogJob?: boolean;
  job?: string;
}

interface correspondencebiomodel {
  isAdd?: boolean;
  selectorService?: any;
  name?: string;
  accountid?: string;
  accountname?: string;
  dossier?: any;
  correspondencedate?: Date;
  senderid?: string;
  sendername?: string;
  recipientid?: string;
  recipientname?: string;
  'correspondence.subject'?: string;
  protocol?: string;
  jsonpersoncompany?: { [key: string]: Object }[];
  menuItem?: any;
  showDialog?: boolean;
  showDialogCompany?: boolean;
  company_name?: string;
}

const { REACT_APP_ENDPOINT_EMPLOYEESERVICE, REACT_APP_ENDPOINT_CORESERVICE } =
  process.env;

class DialogFormTemplate extends React.Component<{}, {}> {
  private user: any;
  private _structure: any;
  private _fulljson: any;
  private cMenu: ContextMenuComponent | null = null;
  private selectorservice: any = null;
  private fields: object = { text: 'name', value: 'id' };
  private cfields: object = { value: 'id', text: 'itemValue' };
  private gfields: object = { value: 'id', text: 'itemValue' };
  private listObj: DropDownListComponent | any;
  private listContract: DropDownListComponent | any;
  private listGender: DropDownListComponent | any;
  private listJob: DropDownListComponent | any;
  private listBranch: DropDownListComponent | any;
  private listDepartment: DropDownTreeComponent | any;
  private jsonpersonobj: any;
  private intNewPersonId: any = 0;
  private emp_personfname: string = '';
  private emp_personmname: string = '';
  private emp_personlname: string = '';
  private emp_personbirthdate?: DateTime;
  private emp_persongender: string = '';
  private drpSender: DropDownTreeComponent | any = undefined;
  private drpRecipient: DropDownTreeComponent | any = undefined;
  private contract_type: any;
  public extradialogheader = 'New Person';
  private token: any = null;
  private jsondepatment: any;
  private jsonbranch: any;
  private jsonjob: any;
  //Hemant 19-04-2024 -- Start
  private sCombobox: string = '';
  toastObj: any;
  //Hemant 19-04-2024 -- End

  private listNationality: DropDownListComponent | any;
  private personNationality: string = '';
  private objNationality: any = [];
  private personbirthplace: string = '';

  public footerTemplate(): JSX.Element {
    return (
      <div>
        <label
          style={{ fontSize: '12px', paddingBottom: '7px', fontWeight: 'bold' }}
        >
          * Indicates mandatory information.
        </label>
      </div>
    );
  }

  private CloseDialog = (e: any) => {
    this.RefreshDropDown();
    this.setState({
      showDialog: false,
      showDialogContractType: false,
      showDialogJob: false,
      showDialogBranch: false,
      showDialogDepartment: false,
    });
  };

  private RefreshDropDown() {
    try {
      if (
        this.listObj &&
        this.listObj.text !== null &&
        this.listObj.value === '-999'
      ) {
        //PERSON
        this.listObj.value = '';
        this.listObj.text = '';
        this.listObj.refresh();
      }

      if (
        this.listContract.text !== null &&
        this.listContract.value === '-999'
      ) {
        //CONTRACT TYPE
        this.listContract.value = '';
        this.listContract.text = '';
        this.listContract.refresh();
      }

      if (this.listJob.text !== null && this.listJob.value[0] === '-999')
        //JOB
        this.listJob.value = '';

      if (this.listBranch.text !== null && this.listBranch.value[0] === '-999')
        //BRANCH
        this.listBranch.value = '';

      if (
        this.listDepartment.text !== null &&
        this.listDepartment.value[0] === '-999'
      ) {
        this.listDepartment.value = '';
      }
      // DEPARTMENT
    } catch (error: any) {
      console.error(error);
    }
  }

  public extradialogbuttons: any = [
    {
      buttonModel: {
        content: Common.toTitleCase('Cancel'),
        cssClass: 'flat-button',
      },
      click: () => {
        switch (this.selectorservice.Id) {
          case this._structure.SidebarConstants.SidebarService.Employee.Id:
            this.RefreshDropDown();
            this.setState({ showDialog: false });
            break;

          case this._structure.SidebarConstants.SidebarService.Correspondence
            .Id:
            let datacorresp: correspondencebiomodel = this.state;
            if (datacorresp.showDialogCompany === true) {
              if (this.drpSender.value[0] === '-888') this.drpSender.clear();
              if (this.drpRecipient.value[0] === '-888')
                this.drpRecipient.clear();
            } else {
              if (this.drpSender.value[0] === '-999') this.drpSender.clear();
              if (this.drpRecipient.value[0] === '-999')
                this.drpRecipient.clear();
            }

            this.setState({ showDialog: false, showDialogCompany: false });
            break;

          default:
            break;
        }
      },
    },
    {
      buttonModel: {
        content: Common.toTitleCase('Accept'),
        cssClass: 'flat-button',
        isPrimary: true,
      },
      click: () => {
        //this.personsave();
        this.ValidateForm();
        switch (this.selectorservice.Id) {
          case this._structure.SidebarConstants.SidebarService.Employee.Id:
            let dataemployeebio: employeebiomodel = this.state;
            if (dataemployeebio.showDialogContractType === true) {
              this.ContractTypeSave();
            } else if (dataemployeebio.showDialogDepartment === true) {
              this.DepartmentSave();
            } else if (dataemployeebio.showDialogBranch === true) {
              this.BranchSave();
            } else if (dataemployeebio.showDialogJob === true) {
              this.JobSave();
            } else {
              this.personsave();
            }
            break;

          case this._structure.SidebarConstants.SidebarService.Correspondence
            .Id:
            let datacorresp: correspondencebiomodel = this.state;
            if (datacorresp.showDialogCompany === true) {
              this.CompanySave();
            } else {
              this.personsave();
            }
            break;

          default:
            break;
        }
      },
    },
  ];

  constructor(props: any) {
    super(props);
    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    //this._fulljson = JSON.parse(Common.getFullJson() ?? '');

    this.token = Common.parseJwt(Common.getItem('token') ?? '');

    this.state = Object.assign({}, props);
    this.selectorservice = props.selectorService;
  }

  componentDidMount() {
    switch (this.selectorservice.Id) {
      case this._structure.SidebarConstants.SidebarService.Person.Id:
        this.GetCountryList();
        break;
      case this._structure.SidebarConstants.SidebarService.Employee.Id:
        this.GetPerson();
        this.GetDepartment();
        this.GetBranch();
        this.GetJob();
        this.GetCountryList();
        break;
      case this._structure.SidebarConstants.SidebarService.Correspondence.Id:
        this.GetPerson();
        break;

      default:
        break;
    }
  }

  public GetJob() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/listjob`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        data.splice(0, 0, { id: '-999', name: 'New ...' });
        this.jsonjob = data;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }
  public GetBranch() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/listbranch`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        data.splice(0, 0, { id: '-999', name: 'New ...' });
        this.jsonbranch = data;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private GetDepartment() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/listdepartment`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        data.splice(0, 0, { id: '-999', name: 'New ...' });
        this.jsondepatment = data;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetPerson() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/${this._structure.SidebarConstants.SidebarService.Person.UrlGet}`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        return response.data;
      })
      .then((data: any) => {
        switch (this.selectorservice.Id) {
          case this._structure.SidebarConstants.SidebarService.Employee.Id:
            data.sort((a: any, b: any) => {
              return a.name > b.name ? 1 : -1;
            });

            data.splice(0, 0, { id: '-999', name: 'New ...' });
            let onlyperson = data.filter((x: any) => x.employee == null);
            this.jsonpersonobj = onlyperson;
            this.setState({ jsonperson: onlyperson });
            break;

          case this._structure.SidebarConstants.SidebarService.Correspondence
            .Id:
            this.GetCompany(data.filter((x: any) => x.employee !== null));
            break;

          default:
            break;
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetContractType() {
    // let _contractstype = Common.FindJsonByObjectId(
    //   this._fulljson,
    //   this._structure.Constants.Contract,
    //   this.user
    // );
    // return _contractstype.children;

    // return this.user.commondata.filter(
    //   (g: any) => g.itemType == this._structure.CommonDataItems.ContractType
    // )
    let contracttype: any;
    contracttype = this.user.commondata.filter(
      (g: any) => g.itemType == this._structure.CommonDataItems.ContractType
    );
    contracttype.splice(0, 0, {
      id: '-999',
      itemType: 5,
      itemValue: 'New ...',
    });
    return contracttype;
  }

  public GetCountryList() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/country/get`,
      {},
      Common.getToken() || '',
      this.user,
      this.token.tenant
    )
      .then((response: any) => {
        response.data.forEach((x: any) => {
          this.objNationality.push({
            id: x.id,
            name: x.name,
          });
        });
        this.objNationality = response.data.map((x: any) => {
          return { id: x.id, name: x.name };
        });
        let obj: any = document.querySelector(
          '#treegrid-selector_dialogEdit_wrapper_dialog-content #nationalityid'
        );
        if (obj) {
          obj.ej2_instances[0].refresh();
        }
        return response.data;
      })
      .then((response: any) => {
        return response;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public GetCompany(persondata: any = null) {
    // Common.ApiCallAsync(
    //   'GET',
    //   `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/api/tenants`,
    //   {},
    //   Common.getToken() || '',
    //   this.user
    // )
    //   .then((response: any) => {
    //     return response.data;
    //   })
    //   .then((companydata: any) => {
    let decodedtoken: any = Common.parseJwt(Common.getToken()!);
    let companydata: any = [];
    companydata.push({
      id: decodedtoken.tenant,
      name: this.user.tenantName,
      parentid: -1,
    });
    // companydata
    //       .sort((a: any, b: any) => {
    //         return a.name > b.name ? 1 : -1;
    //       })
    //       .forEach((x: any) => {
    //         x['parentid'] = -1;
    //       });
    // companydata.splice(0, 0, { id: -888, name: 'New ...', parentid: -1 }); //Uncomment when allow to create company
    persondata
      .sort((a: any, b: any) => {
        return a.name > b.name ? 1 : -1;
      })
      .forEach((x: any) => {
        x['parentid'] = -2;
        if (
          this.selectorservice.Id ===
          this._structure.SidebarConstants.SidebarService.Correspondence.Id
        ) {
          x['id'] = x.employee.id;
        }
      });
    //persondata.splice(0, 0, { id: -999, name: 'New ...', parentid: -2 }); //Uncomment when allow to create person
    persondata.splice(1, 0, { id: -1, name: 'Company', hasChild: true });
    persondata.splice(2, 0, { id: -2, name: 'Person', hasChild: true });

    persondata = persondata.concat(
      companydata.map((x: any) => ({
        id: x.id,
        name: x.name,
        parentid: x.parentid,
      }))
    );
    this.setState({ jsonpersoncompany: persondata });
    return companydata;
    // });
    // .catch((error: any) => {
    //   console.error(error);
    // })
    // .finally(() => {});
  }

  public SetPerson() {
    if (
      this.emp_personfname !== '' &&
      this.emp_personlname !== '' &&
      (this.emp_personbirthdate !== undefined ||
        this.emp_personbirthdate !== null)
    ) {
      let personSetDto: any = {
        firstname: this.emp_personfname,
        lastname: this.emp_personlname,
        birthdate: this.emp_personbirthdate,
        middlename: this.emp_personmname ? this.emp_personmname : '',
        birthplace: this.personbirthplace ? this.personbirthplace : '',
        nationalityid: this.personNationality ? this.personNationality : '',
        bio: [
          {
            commonDataId: this.emp_persongender,
          },
        ],
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_CORESERVICE}/${this._structure.SidebarConstants.SidebarService.Person.UrlSet}`,
        personSetDto,
        Common.getToken() || '',
        this.user,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((data: any) => {
          switch (this.selectorservice.Id) {
            case this._structure.SidebarConstants.SidebarService.Employee.Id:
              this.jsonpersonobj.push(data);
              this.jsonpersonobj.sort((a: any, b: any) => a.id - b.id);
              this.intNewPersonId = data.id;
              //this.jsonpersonobj = data;

              this.setState({
                jsonperson: this.jsonpersonobj,
                showDialog: false,
              });
              this.toastObj = ToastUtility.show({
                cssClass: 'e-toast-success',
                icon: 'far fa-circle-check',
                showCloseButton: true,
                content: 'Record saved SuccessFully..!',
                target: '#basic_card',
                position: { X: 'Center', Y: 'Top' },
                width: 'auto',
                timeOut: 3000,
              });
              this.listObj.refresh();
              this.listObj.value = data.id;
              this.listObj.text = data.name;
              this.listObj.ensureVisible(data.id.toString());

              break;

            case this._structure.SidebarConstants.SidebarService.Correspondence
              .Id:
              let datacorresp: correspondencebiomodel = this.state;
              let pcdata: any = datacorresp.jsonpersoncompany;
              let flter: any = pcdata.filter((x: any) => x.parentid === -2);
              if (flter.length > 0) {
                pcdata.splice(3, 0, {
                  id: data.id,
                  name: data.name,
                  parentid: -2,
                });
              }
              let s1: string[] =
                this.drpSender.value.length > 0 &&
                this.drpSender.value[0] === '-999'
                  ? [data.id.toString()]
                  : this.drpSender.value;
              let s2: string[] =
                this.drpRecipient.value.length > 0 &&
                this.drpRecipient.value[0] === '-999'
                  ? [data.id.toString()]
                  : this.drpRecipient.value;
              let t1: string[] =
                this.drpSender.value.length > 0 &&
                this.drpSender.value[0] === '-999'
                  ? data.name.toString()
                  : [this.drpSender.text];
              let t2: string[] =
                this.drpRecipient.value.length > 0 &&
                this.drpRecipient.value[0] === '-999'
                  ? data.name.toString()
                  : [this.drpRecipient.text];

              this.setState({
                jsonpersoncompany: pcdata,
                showDialog: false,
              });
              this.drpSender.refresh();
              this.drpRecipient.refresh();
              this.drpSender.value = s1;
              this.drpSender.text = t1;
              this.drpRecipient.value = s2;
              this.drpRecipient.text = t2;
              this.drpSender.ensureVisible(s1[0]);
              this.drpRecipient.ensureVisible(s2[0]);
              break;

            default:
              break;
          }
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  }

  onpersonemp_namechange = (e: any) => {
    switch (e.target.id) {
      case 'pfname':
        this.emp_personfname = e.target.value;
        break;
      case 'pmname':
        this.emp_personmname = e.target.value;
        break;
      case 'plname':
        this.emp_personlname = e.target.value;
        break;
    }
  };

  onpersonemp_genderchange = (e: any) => {
    this.emp_persongender = e.target.value;
  };

  onpersonemp_nationalitychange = (e: any) => {
    this.personNationality = e.target.value;
  };

  onperson_birthplacechange = (e: any) => {
    this.personbirthplace = e.target.value;
  };

  onpersonemp_datechange = (e: any) => {
    this.emp_personbirthdate = e.target.value;
  };

  onChangeCompany = (e: any) => {
    this.setState({
      [(e.target as HTMLInputElement).name]: e.target.value,
    });
  };

  onChangeContractType = (e: any) => {
    this.setState({
      [(e.target as HTMLInputElement).name]: e.target.value,
    });
  };

  OnChangeJob = (e: any) => {
    this.setState({
      [(e.target as HTMLInputElement).name]: e.target.value,
    });
  };

  OnChangeBranch = (e: any) => {
    this.setState({
      [(e.target as HTMLInputElement).name]: e.target.value,
    });
  };

  OnChangeDepartment = (e: any) => {
    this.setState({
      [(e.target as HTMLInputElement).name]: e.target.value,
    });
  };

  public dialogContent() {
    var _content: any;
    _content = (
      <>
        <div className='row align-items-center'>
          <div className='col-6 py-2'>
            <TextBoxComponent
              className='input-text-border'
              id='pfname'
              name='pfname'
              type='text'
              onFocus={this.onFocusFunction}
              floatLabelType='Auto'
              placeholder='Enter First Name *'
              autocomplete='off'
              onChange={this.onpersonemp_namechange.bind(this)}
              style={{ fontSize: '15px' }}
            />
          </div>
          <div className='col-6 py-2'>
            <TextBoxComponent
              className='input-text-border'
              id='pmname'
              floatLabelType='Auto'
              name='pmname'
              onFocus={this.onFocusFunction}
              type='text'
              onChange={this.onpersonemp_namechange.bind(this)}
              placeholder='Enter Middle Name'
              autocomplete='off'
              style={{ fontSize: '15px' }}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-6 py-2'>
            {/* <span className='mandatoryinfo'>*</span> */}

            <TextBoxComponent
              floatLabelType='Auto'
              className='input-text-border'
              id='plname'
              name='plname'
              type='text'
              onFocus={this.onFocusFunction}
              onChange={this.onpersonemp_namechange.bind(this)}
              placeholder='Enter Last Name *'
              autocomplete='off'
              style={{ fontSize: '15px' }}
            />
          </div>
          <div className='col-6 py-2'>
            {/* <span className='mandatoryinfo'>*</span> */}

            <DatePickerComponent
              id='pdate'
              floatLabelType='Auto'
              format={this.user.settings.formats.DateShort}
              //value={data.date!}
              onFocus={this.onFocusFunction}
              onChange={this.onpersonemp_datechange.bind(this)}
              showClearButton={false}
              cssClass='e-hidedate-icon'
              openOnFocus={true}
              showTodayButton={false}
              //placeholder={this.user.settings.formats.DateShort}
              placeholder='Birth date *'
              style={{ fontSize: '15px' }}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-6 py-2'>
            {/* <span className='mandatoryinfo'>*</span> */}

            <DropDownListComponent
              id='pgender'
              dataSource={this.user.commondata.filter(
                (g: any) => g.itemType == this._structure.CommonDataItems.Gender
              )}
              ref={(scope) => {
                this.listGender = scope;
              }}
              // onFocus={this.onFocusFunction}
              floatLabelType='Auto'
              filterBarPlaceholder='Search Gender *'
              allowFiltering={true}
              filterType='Contains'
              fields={this.gfields}
              onChange={this.onpersonemp_genderchange.bind(this)}
              placeholder='Select Gender'
              popupHeight='220px'
              style={{ fontSize: '15px' }}
            />
          </div>
          <div className='col-6 py-2'>
            <DropDownListComponent
              id='pNationality'
              dataSource={this.objNationality}
              ref={(scope) => {
                this.listNationality = scope;
              }}
              floatLabelType='Auto'
              filterBarPlaceholder='Search Nationality'
              allowFiltering={true}
              filterType='Contains'
              fields={this.fields}
              onChange={this.onpersonemp_nationalitychange.bind(this)}
              placeholder='Select Nationality'
              popupHeight='220px'
              style={{ fontSize: '15px' }}
            />
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-6 py-2'>
            <TextBoxComponent
              floatLabelType='Auto'
              className='input-text-border'
              id='pbirthplace'
              name='pbirthplace'
              type='text'
              onFocus={this.onFocusFunction}
              onChange={this.onperson_birthplacechange.bind(this)}
              placeholder='Enter Birth Place'
              autocomplete='off'
              style={{ fontSize: '15px' }}
            />
          </div>
        </div>
      </>
    );
    return <>{_content}</>;
  }

  public onFiltering = (e: FilteringEventArgs) => {
    let query: Query = new Query();
    let predicate: Predicate = new Predicate('name', 'contains', e.text).or(
      'id',
      'lessthanorequal',
      0
    );
    // query =
    //   e.text !== '' ? query.where('name', 'contains', e.text, true) : query;
    query =
      e.text !== ''
        ? query.where(predicate, undefined, undefined, true)
        : query;
  };
  onFocusFunction = (event: any) => {
    event.target.classList.remove('textbox-border-error');
  };
  ValidateForm = () => {
    const inputs = [
      document.querySelector<HTMLInputElement>('#pgender'),
      document.querySelector<HTMLInputElement>('#pdate'),
      document.querySelector<HTMLInputElement>('#plname'),
      document.querySelector<HTMLInputElement>('#pfname'),
      document.querySelector<HTMLInputElement>('#contract_type'),
      document.querySelector<HTMLInputElement>('#company_name'),
    ];

    inputs.forEach((input) => {
      if (input) {
        input.classList.remove('textbox-border-error');

        if (input.value.trim() === '') {
          input.classList.add('textbox-border-error');
        } else {
          input.classList.add('textbox-class');
        }
      }
    });
  };
  private personsave() {
    this.ValidateForm();
    if (this.emp_personfname == '') {
      // DialogConfirm.showDialog({
      //   content:
      //     '<p class="dialog-contain">Sorry, firstname is mandatory information.</p>',
      // });
      const TargetInput = document.querySelector<HTMLInputElement>('#pfname');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }
    if (this.emp_personlname == '') {
      // DialogConfirm.showDialog({
      //   content:
      //     '<p class="dialog-contain">Sorry, lastname is mandatory information.</p>',
      // });
      const TargetInput = document.querySelector<HTMLInputElement>('#plname');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }
    if (this.emp_persongender == '') {
      // DialogConfirm.showDialog({
      //   content:
      //     '<p class="dialog-contain">Sorry, gender is mandatory information.</p>',
      // });
      const TargetInput = document.querySelector<HTMLInputElement>('#pgender');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }
    if (
      this.emp_personbirthdate == undefined ||
      this.emp_personbirthdate == null
    ) {
      // DialogConfirm.showDialog({
      //   content:
      //     '<p class="dialog-contain">Sorry, BirthDate is mandatory information.</p>',
      // });
      const TargetInput = document.querySelector<HTMLInputElement>('#pdate');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }
    this.SetPerson();
  }
  private CompanySave() {
    this.ValidateForm();
    let data: correspondencebiomodel = this.state;
    if (!data.company_name) {
      // DialogConfirm.showDialog({
      //   content: '<p class="dialog-contain">Please enter company name.</p>',
      // });
      const TargetInput =
        document.querySelector<HTMLInputElement>('#company_name');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }
    this.SetCompany();
  }

  public SetCompany() {
    let data: correspondencebiomodel = this.state;
    if (data.company_name) {
      let companySetDto: any = {
        name: data.company_name,
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_EMPLOYEESERVICE}/company/Set`,
        companySetDto,
        Common.getToken() || '',
        this.user
      )
        .then((response: any) => {
          return response.data;
        })
        .then((data: any) => {
          switch (this.selectorservice.Id) {
            case this._structure.SidebarConstants.SidebarService.Correspondence
              .Id:
              let datacorresp: correspondencebiomodel = this.state;
              let pcdata: any = datacorresp.jsonpersoncompany;
              let flter: any = pcdata.filter((x: any) => x.parentid === -1);
              if (flter.length > 0) {
                pcdata.splice(1, 0, {
                  id: data.id,
                  name: data.name,
                  parentid: -1,
                });
              }
              let s1: string[] =
                this.drpSender.value.length > 0 &&
                this.drpSender.value[0] === '-888'
                  ? [data.id.toString()]
                  : this.drpSender.value;
              let s2: string[] =
                this.drpRecipient.value.length > 0 &&
                this.drpRecipient.value[0] === '-888'
                  ? [data.id.toString()]
                  : this.drpRecipient.value;
              this.setState({
                jsonpersoncompany: pcdata,
                showDialog: false,
                showDialogCompany: false,
              });
              this.toastObj = ToastUtility.show({
                cssClass: 'e-toast-success',
                icon: 'far fa-circle-check',
                showCloseButton: true,
                content: 'Record saved SuccessFully..!',
                target: '#basic_card',
                position: { X: 'Center', Y: 'Top' },
                width: 'auto',
                timeOut: 3000,
              });
              this.drpSender.refresh();
              this.drpRecipient.refresh();
              this.drpSender.value = s1;
              this.drpRecipient.value = s2;
              this.drpSender.ensureVisible(s1[0]);
              this.drpRecipient.ensureVisible(s2[0]);
              break;

            default:
              break;
          }
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    }
  }

  private ContractTypeSave() {
    this.ValidateForm();
    // console.log(this.contract_type);
    var _input: any = document.getElementById('contract_type');
    if (_input.value.trim().length <= 0) {
      // DialogConfirm.showDialog({
      //   content:
      //     '<p class="dialog-contain">Sorry,  Contract Type is mandatory information.</p>',
      // });
      const TargetInput =
        document.querySelector<HTMLInputElement>('#contract_type');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }

    var payload: any = {
      itemType: this._structure.CommonDataItems.ContractType,
      itemValue: _input.value,
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/commondata/add`,
      payload,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        if (this.user.commondata) {
          this.user.commondata.push({
            id: resopnse.data.id,
            itemType: this._structure.CommonDataItems.ContractType,
            itemValue: resopnse.data.itemValue,
          });
          Common.setItem('user', this.user);
          this.setState({ showDialog: false });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-success',
            icon: 'far fa-circle-check',
            showCloseButton: true,
            content: 'Contract type saved SuccessFully..!',
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
          this.listContract.refresh();
          this.listContract.value = resopnse.data.id;
          this.listContract.text = resopnse.data.itemValue;
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private JobSave() {
    this.ValidateForm();
    var _input: any = document.getElementById('job');
    if (_input.value.trim().length <= 0) {
      // DialogConfirm.showDialog({
      //   content:
      //     '<p class="dialog-contain">Sorry, Job is mandatory information.</p>',
      // });
      const TargetInput = document.querySelector<HTMLInputElement>('#job');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }

    var payload: any = {
      code: _input.value,
      name: _input.value,
      reportToJobId: null,
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/addjob`,
      payload,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        this.setState({ showDialog: false });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-success',
          icon: 'far fa-circle-check',
          showCloseButton: true,
          content: 'Job Record saved SuccessFully..!',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        let job: any = this.jsonjob;
        job.splice(this.jsonjob.length + 1, 0, {
          id: resopnse.data.id,
          name: resopnse.data.name,
          addNewOption: null,
          branchId: null,
          isgroup: false,
          selectable: true,
          showAllDuplicates: null,
          subBranchs: [],
        });
        this.jsonjob = job;
        this.listJob.refresh();
        this.listJob.value = resopnse.data.id;
        this.listJob.text = resopnse.data.name;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private BranchSave() {
    this.ValidateForm();
    var _input: any = document.getElementById('branch');
    if (_input.value.trim().length <= 0) {
      // DialogConfirm.showDialog({
      //   content:
      //     '<p class="dialog-contain">Sorry, Branch is mandatory information.</p>',
      // });
      const TargetInput = document.querySelector<HTMLInputElement>('#branch');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }

    var payload: any = {
      name: _input.value,
      isgroup: false,
      selectable: true,
      showAllDuplicates: true,
      addNewOption: true,
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/addbranch`,
      payload,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        this.setState({ showDialog: false });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-success',
          icon: 'far fa-circle-check',
          showCloseButton: true,
          content: 'Branch Record saved SuccessFully..!',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        let branch: any = this.jsonbranch;
        branch.splice(this.jsonbranch.length + 1, 0, {
          id: resopnse.data.id,
          name: resopnse.data.name,
          addNewOption: null,
          branchId: null,
          isgroup: false,
          selectable: true,
          showAllDuplicates: null,
          subBranchs: [],
        });
        this.jsonbranch = branch;
        this.listBranch.refresh();
        this.listBranch.value = resopnse.data.id;
        this.listBranch.text = resopnse.data.name;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private DepartmentSave() {
    this.ValidateForm();
    var _input: any = document.getElementById('department');
    if (_input.value.trim().length <= 0) {
      // DialogConfirm.showDialog({
      //   content:
      //     '<p class="dialog-contain">Sorry, Department is mandatory information.</p>',
      // });
      const TargetInput =
        document.querySelector<HTMLInputElement>('#department');
      if (TargetInput) {
        TargetInput.style.borderBottom = '2px solid red';
      }
      return;
    }

    var payload: any = {
      name: _input.value,
      isgroup: false,
      selectable: true,
      showAllDuplicates: true,
      addNewOption: true,
    };

    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/allocation/adddepartment`,
      payload,
      Common.getToken() || '',
      this.user
    )
      .then((resopnse: any) => {
        this.setState({ showDialog: false });
        this.toastObj = ToastUtility.show({
          cssClass: 'e-toast-success',
          icon: 'far fa-circle-check',
          showCloseButton: true,
          content: 'Department Record saved SuccessFully..!',
          target: '#basic_card',
          position: { X: 'Center', Y: 'Top' },
          width: 'auto',
          timeOut: 3000,
        });
        let department: any = this.jsondepatment;
        department.splice(this.jsondepatment.length + 1, 0, {
          id: resopnse.data.id,
          name: resopnse.data.name,
          addNewOption: null,
          departmentId: null,
          isgroup: false,
          selectable: true,
          showAllDuplicates: null,
          subDepartments: [],
        });
        this.jsondepatment = department;
        this.listDepartment.refresh();
        this.listDepartment.value = resopnse.data.id;
        this.listDepartment.text = resopnse.data.name;
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  public DropDownListChange(): void {
    this.sCombobox = 'Person';
    if (this.listObj === null) {
      return;
    }
    if (parseInt(this.listObj.value.toString()) == -999) {
      this.setState({ showDialog: true });
    } else {
      let personEmail: string = '';
      if (
        this.listObj.itemData.addresses &&
        this.listObj.itemData.addresses.length > 0
      ) {
        let addr: any = this.listObj.itemData.addresses.filter(
          (x: any) => x.type === 'Personal'
        );
        if (addr.length > 0) {
          if (addr[0].contacts && addr[0].contacts.length > 0) {
            let cards: any = addr[0].contacts[0].cards;
            if (cards && cards.length > 0) {
              let itm: any = cards[0].items.filter(
                (x: any) => x.itemType === 'Email'
              );
              if (itm.length > 0) {
                personEmail = itm[0].itemValue;
              }
            }
          }
        }
      }

      this.setState({
        personname: this.listObj.text,
        personid: this.listObj.value,
        birthdate: this.jsonpersonobj.filter(
          (x: any) => x.id == this.listObj.value
        )[0].date,
        personEmail: personEmail,
      });
      if (document.getElementById('personname') !== undefined) {
        let _pname = document.getElementById('personname');
        (_pname as any).value = this.listObj.text;
      }
      if (document.getElementById('birthdate') !== undefined) {
        let _birthdate = document.getElementById('birthdate');
        (_birthdate as any).value = this.jsonpersonobj.filter(
          (x: any) => x.id == this.listObj.value
        )[0].date;
      }
    }
  }

  public dialogContentCompany() {
    let datacorresp: correspondencebiomodel = this.state;
    return (
      <>
        <div className='row align-items-center'>
          {/* <div className='col-3'>
            <label>Name</label>
            <span className='mandatoryinfo'>*</span>
          </div> */}
          <div className='col-12 py-2'>
            <TextBoxComponent
              className='input-text-border'
              id='compayname'
              name='company_name'
              type='text'
              placeholder='Enter Company Name *'
              floatLabelType='Auto'
              onFocus={this.onFocusFunction}
              value={datacorresp.company_name}
              autocomplete='off'
              onChange={this.onChangeCompany.bind(this)}
              // required={true}
              // autoFocus={true}
            />
          </div>
        </div>
      </>
    );
  }

  public dialogContentContractType() {
    let datacorresp: employeebiomodel = this.state;
    return (
      <>
        <div className='row align-items-center'>
          {/* <div className='col-3'>
            <label>Name</label>
            <span className='mandatoryinfo'>*</span>
          </div> */}
          <div className='col-12 py-2'>
            <TextBoxComponent
              className='input-text-border'
              id='contract_type'
              name='contract_type'
              type='text'
              placeholder='Enter Contract Type *'
              floatLabelType='Auto'
              onFocus={this.onFocusFunction}
              value={datacorresp.contract_type}
              autocomplete='off'
              onChange={this.onChangeContractType.bind(this)}
              // required={true}
              // autoFocus={true}
            />
          </div>
        </div>
      </>
    );
  }

  public dialogContentJob() {
    let datacorresp: employeebiomodel = this.state;
    return (
      <>
        <div className='row align-items-center'>
          {/* <div className='col-3'>
            <label>Name</label>
            <span className='mandatoryinfo'>*</span>
          </div> */}
          <div className='col-12 py-2'>
            <TextBoxComponent
              className='input-text-border'
              id='job'
              name='job'
              type='text'
              placeholder='Enter Job *'
              floatLabelType='Auto'
              onFocus={this.onFocusFunction}
              value={datacorresp.job}
              autocomplete='off'
              onChange={this.OnChangeJob.bind(this)}
              // required={true}
              // autoFocus={true}
            />
          </div>
        </div>
      </>
    );
  }

  public dialogContentBranch() {
    let datacorresp: employeebiomodel = this.state;
    return (
      <>
        <div className='row align-items-center'>
          {/* <div className='col-3'>
            <label>Name</label>
            <span className='mandatoryinfo'>*</span>
          </div> */}
          <div className='col-12 py-2'>
            <TextBoxComponent
              className='input-text-border'
              id='branch'
              name='branch'
              type='text'
              placeholder='Enter Branch *'
              floatLabelType='Auto'
              onFocus={this.onFocusFunction}
              value={datacorresp.branch}
              autocomplete='off'
              onChange={this.OnChangeBranch.bind(this)}
              // required={true}
              // autoFocus={true}
            />
          </div>
        </div>
      </>
    );
  }

  public dialogContentDepartment() {
    let datacorresp: employeebiomodel = this.state;
    return (
      <>
        <div className='row align-items-center'>
          {/* <div className='col-3'>
            <label>Name</label>
            <span className='mandatoryinfo'>*</span>
          </div> */}
          <div className='col-12 py-2'>
            <TextBoxComponent
              className='input-text-border'
              id='department'
              name='department'
              type='text'
              placeholder='Enter Department *'
              floatLabelType='Auto'
              onFocus={this.onFocusFunction}
              value={datacorresp.department}
              autocomplete='off'
              onChange={this.OnChangeDepartment.bind(this)}
              // required={true}
              // autoFocus={true}
            />
          </div>
        </div>
      </>
    );
  }

  public dialogTemplate(): JSX.Element {
    const onChange = (args: any) => {
      if (args.target !== undefined) {
        this.setState({
          [(args.target as HTMLInputElement).name]: args.target.value,
        });
      } else {
        this.setState({
          scale: args.value,
        });
      }
    };
    const handleTextAccountClick = (e: any) => {
      if (this.cMenu) {
        let obj1: HTMLElement = document.getElementById(
          'account.name'
        ) as HTMLElement;

        let c_area: any = obj1.getBoundingClientRect();

        this.cMenu.open(c_area.top + c_area.height, c_area.left);
      }
    };
    const handleCntxMenuClick = (e: any) => {
      this.setState({
        accountid: e.item.properties.id,
        accountname: e.item.properties.text,
      });
    };
    const onFocusFunction = (event: any) => {
      event.target.classList.remove('textbox-border-error');
      event.target.style.border = 'none';
    };

    const dialogTemplatePerson = (data: personbiomodel): JSX.Element => {
      const handleGenderChange = (e: any) => {
        if (this.listGender === null) {
          return;
        }
        this.setState({ gender: this.listGender.id });
      };

      const handleNationalityChange = (e: any) => {
        if (!this.listNationality) {
          return;
        }
        this.setState({ nationalityid: this.listNationality.id });
      };

      return (
        <>
          <div style={{ width: '35vw', height: '100%' }}>
            <div className='row align-items-center'>
              <div className='col-6 py-2'>
                <TextBoxComponent
                  className='textbox-class'
                  //onFocus={onFocusFunction}
                  id='firstname'
                  name='firstname'
                  type='text'
                  floatLabelType='Auto'
                  // disabled={!data.isAdd}
                  value={data.firstname!}
                  onChange={onChange}
                  autocomplete='off'
                  placeholder='Enter First Name *'
                  style={{ fontSize: '15px' }}
                  showClearButton={true}
                />
              </div>
              <div className='col-6 py-2'>
                {/* <span className='mandatoryinfo'>*</span> */}
                {/* <label style={{ fontSize: '13px', paddingBottom: '7px' }}>
                      LastName
                    </label> */}
                <TextBoxComponent
                  className='textbox-class'
                  //onFocus={onFocusFunction}
                  id='lastname'
                  name='lastname'
                  type='text'
                  floatLabelType='Auto'
                  // disabled={!data.isAdd}
                  value={data.lastname!}
                  onChange={onChange}
                  placeholder='Enter Last Name *'
                  autocomplete='off'
                  style={{ fontSize: '15px' }}
                />
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='col-6 py-2'>
                <TextBoxComponent
                  className='textbox-class'
                  //onFocus={onFocusFunction}
                  id='middlename'
                  floatLabelType='Auto'
                  name='middlename'
                  type='text'
                  // disabled={!data.isAdd}
                  value={data.middlename!}
                  onChange={onChange}
                  placeholder='Enter Middle Name'
                  autocomplete='off'
                  style={{ fontSize: '15px' }}
                />
              </div>
              {data.isAdd ? (
                <>
                  <div className='col-6 py-2'>
                    <DatePickerComponent
                      id='date'
                      className='textbox-class'
                      //onFocus={onFocusFunction}
                      format={this.user.settings.formats.DateShort}
                      //value={data.date!}
                      showClearButton={false}
                      cssClass='e-hidedate-icon'
                      openOnFocus={true}
                      showTodayButton={false}
                      floatLabelType='Auto'
                      onChange={onChange}
                      //placeholder={this.user.settings.formats.DateShort}
                      placeholder='Birth date *'
                      style={{ fontSize: '15px' }}
                    />
                  </div>
                </>
              ) : (
                <div className='col-6 py-2'>
                  <DropDownListComponent
                    id='nationalityid'
                    className='textbox-class'
                    dataSource={this.objNationality}
                    ref={(scope) => {
                      this.listNationality = scope;
                    }}
                    floatLabelType='Auto'
                    filterBarPlaceholder='Search Nationality'
                    allowFiltering={true}
                    filterType='Contains'
                    fields={this.fields}
                    change={handleNationalityChange}
                    placeholder='Select Nationality'
                    popupHeight='220px'
                    style={{ fontSize: '15px' }}
                    value={data.nationalityid}
                    // created={(e: any) => {
                    //   this.listNationality.value = data.nationalityid;
                    // }}
                    showClearButton={true}
                  />
                </div>
              )}
            </div>
            {data.isAdd ? (
              <>
                <div className='row align-items-center'>
                  <div className='col-6 py-2'>
                    <DropDownListComponent
                      id='gender'
                      className='textbox-class'
                      // //onFocus={onFocusFunction}
                      dataSource={this.user.commondata.filter(
                        (g: any) =>
                          g.itemType == this._structure.CommonDataItems.Gender
                      )}
                      ref={(scope) => {
                        this.listGender = scope;
                      }}
                      floatLabelType='Auto'
                      filterBarPlaceholder='Search Gender'
                      allowFiltering={true}
                      filterType='Contains'
                      fields={this.gfields}
                      change={handleGenderChange}
                      placeholder='Select Gender *'
                      popupHeight='220px'
                      style={{ fontSize: '15px' }}
                    />
                  </div>
                  <div className='col-6 py-2'>
                    <DropDownListComponent
                      id='nationalityid'
                      className='textbox-class'
                      dataSource={this.objNationality}
                      ref={(scope) => {
                        this.listNationality = scope;
                      }}
                      value={data.nationalityid}
                      floatLabelType='Auto'
                      filterBarPlaceholder='Search Nationality'
                      allowFiltering={true}
                      filterType='Contains'
                      fields={this.fields}
                      change={handleNationalityChange}
                      placeholder='Select Nationality'
                      popupHeight='220px'
                      style={{ fontSize: '15px' }}
                      showClearButton={true}
                    />
                  </div>
                </div>
              </>
            ) : (
              ''
            )}
            <div className='row align-items-center'>
              <div className='col-6 py-2'>
                <TextBoxComponent
                  className='textbox-class'
                  id='birthplace'
                  floatLabelType='Auto'
                  name='birthplace'
                  type='text'
                  value={data.birthplace!}
                  onChange={onChange}
                  placeholder='Enter Birth Place'
                  autocomplete='off'
                  style={{ fontSize: '15px' }}
                />
              </div>
              <div className='col-6 py-2'></div>
            </div>
          </div>
        </>
      );
    };

    const dialogTemplateEmployee = (data: employeebiomodel): JSX.Element => {
      this.contract_type = this.GetContractType();
      let treeSettings: TreeSettingsModel = { expandOn: 'Click' };

      const dfields = {
        dataSource: this.jsondepatment,
        value: 'id',
        text: 'name',
        child: 'subDepartments',
        selectable: 'selectable',
      };

      const bfields = {
        dataSource: this.jsonbranch,
        value: 'id',
        text: 'name',
        child: 'subBranchs',
        selectable: 'selectable',
      };
      const jfields = {
        dataSource: this.jsonjob,
        value: 'id',
        text: 'name',
        child: 'subJob',
        selectable: 'selectable',
      };
      const handleContractChange = (e: any) => {
        this.sCombobox = 'ContractType';
        if (this.listContract === null) {
          return;
        }
        if (parseInt(e.itemData.id) == -999) {
          this.setState({
            showDialogContractType: true,
            showDialog: true,
            showDialogCompany: false,
            showDialogDepartment: false,
            showDialogBranch: false,
            showDialogJob: false,
            contract_type: '',
          });
        }
        this.setState({
          contractid: this.listContract.Id,
        });
      };

      const handleJobChange = (e: any) => {
        this.sCombobox = 'Job';
        if (this.listJob === null) {
          return;
        }
        if (parseInt(e.itemData.id) == -999) {
          this.setState({
            showDialogJob: true,
            showDialog: true,
            showDialogBranch: false,
            showDialogDepartment: false,
            showDialogContractType: false,
            showDialogCompany: false,
            job: '',
          });
          this.setState({
            jobid: this.listJob.value[0],
          });
        }
      };

      const handleBranchChange = (e: any) => {
        this.sCombobox = 'Branch';
        if (this.listBranch === null) {
          return;
        }
        if (parseInt(e.itemData.id) == -999) {
          this.setState({
            showDialogBranch: true,
            showDialog: true,
            showDialogDepartment: false,
            showDialogJob: false,
            showDialogContractType: false,
            showDialogCompany: false,
            branch: '',
          });
          this.setState({
            branchid: this.listBranch.value[0],
          });
        }
      };

      const handleDepartmentChange = (e: any) => {
        this.sCombobox = 'Department';
        if (this.listDepartment === null) {
          return;
        }
        if (parseInt(e.itemData.id) == -999) {
          this.setState({
            showDialog: true,
            showDialogDepartment: true,
            showDialogContractType: false,
            showDialogBranch: false,
            showDialogJob: false,
            showDialogCompany: false,
            department: '',
          });
        }
        this.setState({
          departmentid: this.listDepartment.value[0],
        });
      };

      return (
        <>
          <div style={{ width: '40vw', height: '100%' }}>
            {/* <div className='row align-items-center'>
              <div className='col-3 py-2'>
                <label >Name :</label>
              </div>
              <div className='col-9 py-2'>'Employee Code Value'</div>
            </div> */}
            {data.isAdd && (
              <div className='row align-items-center'>
                <div className='col-6 py-2'>
                  {/* <span className='mandatoryinfo'>*</span> */}

                  <TextBoxComponent
                    id='personname'
                    className='textbox-class'
                    //onFocus={onFocusFunction}
                    name='personname'
                    type='hidden'
                    floatLabelType='Auto'
                    autocomplete='off'
                    onChange={onChange}
                  />
                  <TextBoxComponent
                    id='birthdate'
                    className='textbox-class'
                    //onFocus={onFocusFunction}
                    name='birthdate'
                    type='hidden'
                    floatLabelType='Auto'
                    autocomplete='off'
                    onChange={onChange}
                  />
                  <DropDownListComponent
                    id='personid'
                    className='textbox-class'
                    // //onFocus={onFocusFunction}
                    dataSource={this.jsonpersonobj}
                    ref={(scope) => {
                      this.listObj = scope;
                    }}
                    filterBarPlaceholder='Search a Person *'
                    allowFiltering={true}
                    floatLabelType='Auto'
                    filterType='Contains'
                    fields={this.fields}
                    change={this.DropDownListChange.bind(this)}
                    placeholder='Select Person *'
                    popupHeight='220px'
                    style={{ fontSize: '15px' }}
                  ></DropDownListComponent>
                </div>
                <div className='col-6 py-2'>
                  {/* <span className='mandatoryinfo'>*</span> */}

                  <DatePickerComponent
                    id='appointmentdate'
                    className='textbox-class'
                    //onFocus={onFocusFunction}
                    format={this.user.settings.formats.DateShort}
                    //value={data.date!}
                    onChange={onChange}
                    floatLabelType='Auto'
                    showClearButton={false}
                    cssClass='e-hidedate-icon'
                    openOnFocus={true}
                    showTodayButton={false}
                    //placeholder={this.user.settings.formats.DateShort}
                    placeholder='Appointment Date *'
                    style={{ fontSize: '15px' }}
                  />
                </div>
              </div>
            )}
            <div className='row align-items-center'>
              {data.isAdd ? (
                <div className='col-6 py-2'>
                  {/* <span className='mandatoryinfo'>*</span> */}

                  <DropDownListComponent
                    id='contractid'
                    className='textbox-class'
                    // //onFocus={onFocusFunction}
                    dataSource={this.contract_type}
                    ref={(scope) => {
                      this.listContract = scope;
                    }}
                    filterBarPlaceholder='Search a Contract'
                    allowFiltering={true}
                    filterType='Contains'
                    floatLabelType='Auto'
                    fields={this.cfields}
                    change={handleContractChange}
                    placeholder='Select Contract *'
                    popupHeight='220px'
                    style={{ fontSize: '15px' }}
                  ></DropDownListComponent>
                </div>
              ) : (
                <div className='col-6 py-2'>
                  <TextBoxComponent
                    id='employeecode'
                    name='employeecode'
                    className='textbox-class'
                    //onFocus={onFocusFunction}
                    floatLabelType='Auto'
                    type='text'
                    placeholder='Enter employee code *'
                    style={{ padding: '8px 0px' }}
                    autocomplete='off'
                    value={data.employeecode ? data.employeecode : ''}
                  />
                </div>
              )}
              <div className='col-6 py-2'>
                <TextBoxComponent
                  id='email'
                  className='textbox-class'
                  //onFocus={onFocusFunction}
                  name='email'
                  floatLabelType='Auto'
                  type='email'
                  placeholder='Enter email'
                  autocomplete='off'
                  style={{ padding: '8px 0px' }}
                  value={data.email ? data.email : data.personEmail}
                />
              </div>
            </div>
            {data.isAdd && (
              <div className='row align-items-center'>
                <div className='col-6 py-2'>
                  <DropDownTreeComponent
                    id='jobid'
                    name='jobid'
                    className='textbox-class'
                    // //onFocus={onFocusFunction}
                    treeSettings={treeSettings}
                    fields={jfields}
                    floatLabelType='Auto'
                    ref={(scope) => {
                      this.listJob = scope;
                    }}
                    filterBarPlaceholder='Search a Job'
                    allowFiltering={true}
                    filterType='Contains'
                    select={handleJobChange}
                    placeholder='Select Job'
                    popupHeight='220px'
                    style={{ fontSize: '15px' }}
                  />
                </div>
                <div className='col-6 py-2'>
                  {/* <input className='input-text-border' id='scale' name='scale' placeholder='Salary' style={{ fontSize: '15px', textAlign: 'right' }} onChange={onChange}></input> */}
                  <NumericTextBoxComponent
                    id='scale'
                    name='scale'
                    className='textbox-class'
                    //onFocus={onFocusFunction}
                    floatLabelType='Auto'
                    placeholder='Salary'
                    //format={this.user.settings.formats.Decimal}
                    decimals={
                      this.user.settings.formats.Decimal.includes('.')
                        ? this.user.settings.formats.Decimal.split('.').pop()
                            .length
                        : null
                    }
                    // currency={'USD'} // CURRENCY TO BE PICKED FROM COMPANY
                    // format={`c${this.user.settings.formats.Decimal.includes('.')
                    //   ? this.user.settings.formats.Decimal.split(
                    //     '.'
                    //   ).pop().length
                    //   : 2
                    //   }`}
                    format={`${this.user.currencySign} 0.${
                      this.user.settings.formats.Decimal.includes('.')
                        ? '0'.repeat(
                            this.user.settings.formats.Decimal.split('.').pop()
                              .length
                          )
                        : '0'.repeat(2)
                    }`}
                    showSpinButton={false}
                    change={onChange}
                    created={(e: any) => {
                      document
                        .querySelector('#scale')
                        ?.addEventListener('paste', (e: any) => {
                          e.target.ej2_instances[0].value = e.clipboardData
                            .getData('Text')
                            .replaceAll(',', '');
                        });
                    }}
                  />
                </div>
              </div>
            )}
            {data.isAdd && (
              <div className='row align-items-center'>
                <div className='col-6 py-2'>
                  <DropDownTreeComponent
                    id='branchid'
                    className='textbox-class'
                    // //onFocus={onFocusFunction}
                    floatLabelType='Auto'
                    name='branchid'
                    treeSettings={treeSettings}
                    fields={bfields}
                    ref={(scope) => {
                      this.listBranch = scope;
                    }}
                    filterBarPlaceholder='Search a Branch'
                    allowFiltering={true}
                    filterType='Contains'
                    select={handleBranchChange}
                    placeholder='Select Branch'
                    popupHeight='220px'
                    style={{ fontSize: '15px' }}
                  ></DropDownTreeComponent>
                </div>
                <div className='col-6 py-2'>
                  <DropDownTreeComponent
                    id='departmentid'
                    className='textbox-class'
                    // //onFocus={onFocusFunction}
                    name='departmentid'
                    floatLabelType='Auto'
                    ref={(scope) => {
                      this.listDepartment = scope;
                    }}
                    treeSettings={treeSettings}
                    fields={dfields}
                    filterBarPlaceholder='Search a Department'
                    allowFiltering={true}
                    filterType='Contains'
                    select={handleDepartmentChange}
                    placeholder='Select Department'
                    popupHeight='220px'
                    style={{ fontSize: '15px' }}
                  ></DropDownTreeComponent>
                </div>
              </div>
            )}
          </div>
          <div>
            {data.showDialog === true ? (
              <DialogComponent
                id='defaultDialog1'
                showCloseIcon={true}
                visible={data.showDialog}
                width={'25vw'}
                ///height={'25vh'}
                close={this.CloseDialog.bind(this)}
                content={
                  this.sCombobox === 'ContractType' &&
                  data.showDialogContractType === true
                    ? this.dialogContentContractType.bind(this)
                    : this.sCombobox === 'Job' && data.showDialogJob === true
                    ? this.dialogContentJob.bind(this)
                    : this.sCombobox === 'Branch' &&
                      data.showDialogBranch === true
                    ? this.dialogContentBranch.bind(this)
                    : this.sCombobox === 'Department' &&
                      data.showDialogDepartment === true
                    ? this.dialogContentDepartment.bind(this)
                    : this.dialogContent.bind(this)
                }
                isModal={true}
                header={
                  this.sCombobox === 'ContractType' &&
                  data.showDialogContractType === true
                    ? 'New Contract Type'
                    : this.sCombobox === 'Job' && data.showDialogJob === true
                    ? 'New Job'
                    : this.sCombobox === 'Branch' &&
                      data.showDialogBranch === true
                    ? 'New Branch'
                    : this.sCombobox === 'Department' &&
                      data.showDialogDepartment === true
                    ? 'New Department'
                    : this.extradialogheader
                }
                buttons={this.extradialogbuttons}
                statelessTemplates={[]}
                //footerTemplate={this.footerTemplate}
              ></DialogComponent>
            ) : null}
          </div>
        </>
      );
    };

    const dialogTemplateCorrespondence = (
      data: correspondencebiomodel
    ): JSX.Element => {
      // let drpSender: DropDownTreeComponent;
      // let drpRecipient: DropDownTreeComponent;
      if (data['correspondence.subject'] === undefined) {
        data['correspondence.subject'] = data.accountname;
      }
      let fields = {
        dataSource: data.jsonpersoncompany,
        value: 'id',
        text: 'name',
        parentValue: 'parentid',
        hasChildren: 'hasChild',
      };
      let showCheckBox = true;
      let treeSettings: TreeSettingsModel = { expandOn: 'Click' };
      let customTemplate = 'Selected items count: ${value.length} item(s) ';

      const handleDropDownTreeChange = (e: any) => {
        let itemid: string = `#${e.item.id}`;
        if (e.item.id === '') {
          if (e.itemData.parentID === '-1') {
            //itemid = '#correspondence-dropdowntree-sender';
            (document.getElementById('correspondence-sender-id') as any).value =
              e.itemData.id;
          } else if (e.itemData.parentID === '-2') {
            //itemid = '#correspondence-dropdowntree-sender';
            (
              document.getElementById('correspondence-recipient-id') as any
            ).value = e.itemData.id;
          }
          return;
        } else {
          let drp: HTMLSelectElement = document
            .querySelector(itemid)
            ?.parentElement?.closest('.e-treeview') as HTMLSelectElement;

          if (
            Number(e.itemData.id) === -999 ||
            Number(e.itemData.id) === -888
          ) {
            if (Number(e.itemData.id) === -999) {
              this.setState({ showDialog: true });
            } else if (Number(e.itemData.id) === -888) {
              this.setState({ showDialog: true, showDialogCompany: true });
            }
            if (drp.id === this.drpSender.element.id + '_tree') {
              //this.drpSender.clear();
              (
                document.getElementById('correspondence-sender-id') as any
              ).value = null;
            } else if (drp.id === this.drpRecipient.element.id + '_tree') {
              //this.drpRecipient.clear();
              (
                document.getElementById('correspondence-recipient-id') as any
              ).value = null;
            }
            return;
          } else if (
            Number(e.itemData.id) === -1 ||
            Number(e.itemData.id) === -2
          ) {
            if (Number(e.itemData.id) === -1) {
              this.drpSender.showPopup();
            } else if (Number(e.itemData.id) === -2) {
              this.drpRecipient.showPopup();
            }
          } else {
            if (drp.id === this.drpSender.element.id + '_tree') {
              if (
                (document.getElementById('correspondence-recipient-id') as any)
                  .value === e.itemData.id
              ) {
                (
                  document.getElementById('correspondence-sender-id') as any
                ).value = null;
                (
                  document.querySelector(
                    '#correspondence-sender-id.e-dropdowntree'
                  ) as any
                ).ej2_instances[0].value = null;
                return;
              }
              (
                document.getElementById('correspondence-sender-id') as any
              ).value = e.itemData.id;
            } else if (drp.id === this.drpRecipient.element.id + '_tree') {
              if (
                (document.getElementById('correspondence-sender-id') as any)
                  .value === e.itemData.id
              ) {
                (
                  document.getElementById('correspondence-recipient-id') as any
                ).value = null;
                (
                  document.querySelector(
                    '#correspondence-recipient-id.e-dropdowntree'
                  ) as any
                ).ej2_instances[0].value = null;
                return;
              }
              (
                document.getElementById('correspondence-recipient-id') as any
              ).value = e.itemData.id;
            }
          }
        }
      };

      const handleDataBoundSender = (e: any) => {
        if (this.drpSender && this.drpSender.value.length <= 0) {
          setTimeout(() => {
            // let s: string[] = [this.user.company.id.toString()];
            let s: string[] = [Common.parseJwt(Common.getToken()!).tenant];
            this.drpSender.value = s;
            this.drpSender.ensureVisible(s[0]);
          }, 500);
        }
      };

      return (
        <div style={{ width: '28vw', height: '100%' }}>
          <input
            id='correspondence-accountid'
            name='correspondence.accountid'
            type='hidden'
            value={data.accountid!}
            onChange={onChange}
          />
          <input
            id='correspondence-sender-id'
            name='correspondence.sender.id'
            type='hidden'
            autoComplete='off'
            onChange={onChange}
          />
          <input
            id='correspondence-recipient-id'
            name='correspondence.recipient.id'
            type='hidden'
            autoComplete='off'
            onChange={onChange}
          />
          <div className='row align-items-center'>
            <div className='col-3 py-2'>
              <label>Sender *</label>
            </div>
            <div className='col-9 py-2'>
              <DropDownTreeComponent
                id='correspondence-sender-id'
                name='correspondence.sender.id'
                allowFiltering={true}
                filterType='Contains'
                fields={fields}
                treeSettings={treeSettings}
                //customTemplate={customTemplate}
                mode='Custom'
                placeholder='Select a sender'
                popupHeight='250px'
                select={handleDropDownTreeChange}
                ref={(obj: any) => (this.drpSender = obj)}
                style={{ fontSize: '15px' }}
                //value={[data.senderid ? data.senderid!.toString() : '']}
                dataBound={handleDataBoundSender}
              />
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-3 py-2'>
              <label>Recipient *</label>
            </div>
            <div className='col-9 py-2'>
              <DropDownTreeComponent
                id='correspondence-recipient-id'
                name='correspondence.recipient.id'
                allowFiltering={true}
                filterType='Contains'
                fields={fields}
                treeSettings={treeSettings}
                customTemplate={customTemplate}
                mode='Custom'
                placeholder='Select a recipient'
                popupHeight='250px'
                select={handleDropDownTreeChange}
                ref={(obj: any) => (this.drpRecipient = obj)}
                style={{ fontSize: '15px' }}
                //value={[data.recipientid ? data.recipientid!.toString() : '']}
              />
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-3 py-2'>
              <label>Date *</label>
            </div>
            <div className='col-9 py-2'>
              <DatePickerComponent
                id='correspondencedate'
                name='correspondence.date'
                disabled={!data.isAdd}
                value={data.correspondencedate}
                format={this.user.settings.formats.DateShort}
                showClearButton={false}
                cssClass='e-hidedate-icon'
                onChange={onChange}
                openOnFocus={true}
                showTodayButton={false}
                //placeholder={this.user.settings.formats.DateShort}
                placeholder='Select Date'
                style={{ fontSize: '15px' }}
              />
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-3 py-2'>
              <label>Subject *</label>
            </div>
            <div className='col-9 py-2'>
              <input
                id='correspondence-subject'
                name='correspondence.subject'
                type='text'
                className='input-text-border'
                disabled={!data.isAdd}
                value={data['correspondence.subject']!}
                autoComplete='off'
                onChange={onChange}
                required={true}
                placeholder='Enter Subject'
                style={{ fontSize: '15px' }}
              />
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-3 py-2'>
              <label>Protocol</label>
            </div>
            <div className='col-9 py-2'>
              <input
                id='correspondence-protocol'
                name='correspondence.protocol'
                type='text'
                className='input-text-border'
                disabled={!data.isAdd}
                value={data.protocol!}
                autoComplete='off'
                onChange={onChange}
                //onClick={handleTextAccountClick}
                placeholder='Enter Protocol'
                style={{ fontSize: '15px' }}
              />
            </div>
          </div>
          <div>
            {/* <ContextMenuComponent
              id={'dlgformtemplate-contextmenu'}
              ref={(scope) => (this.cMenu = scope as ContextMenuComponent)}
              items={data.menuItem!}
              select={handleCntxMenuClick}
            /> */}
          </div>
          {data.showDialog === true ? (
            <DialogComponent
              id='defaultDialogCorrespondence'
              showCloseIcon={true}
              visible={data.showDialog}
              width={'20vw'}
              //height={data.showDialogCompany === true ? '165px' : '200px'}
              close={() =>
                this.setState({ showDialog: false, showDialogCompany: false })
              }
              content={
                data.showDialogCompany === true
                  ? this.dialogContentCompany.bind(this)
                  : this.dialogContent.bind(this)
              }
              isModal={true}
              header={
                data.showDialogCompany === true
                  ? 'New Company'
                  : this.extradialogheader
              }
              buttons={this.extradialogbuttons}
              footerTemplate={this.footerTemplate}
              statelessTemplates={[]}
            ></DialogComponent>
          ) : null}
        </div>
      );
    };

    switch (this.selectorservice.Id) {
      case this._structure.SidebarConstants.SidebarService.Person.Id:
        let dataperson: personbiomodel = this.state;
        return <> {dialogTemplatePerson(dataperson)}</>;
        break;

      case this._structure.SidebarConstants.SidebarService.Employee.Id:
        let dataemp: employeebiomodel = this.state;
        return <> {dialogTemplateEmployee(dataemp)}</>;
        break;

      case this._structure.SidebarConstants.SidebarService.Correspondence.Id:
        let datacorresp: correspondencebiomodel = this.state;
        return <> {dialogTemplateCorrespondence(datacorresp)}</>;
        break;

      default:
        return <></>;
        break;
    }
  }

  public render(): any {
    return <>{this.dialogTemplate()}</>;
  }
}

export default DialogFormTemplate;
