import React from 'react';
import './Profile.css';
import Common from '../../../Util/Common';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import {
  DatePickerComponent,
  DateRangePickerComponent,
  RangeEventArgs,
} from '@syncfusion/ej2-react-calendars';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import {
  ContextMenuComponent,
  MenuItemModel,
  MenuEventArgs,
} from '@syncfusion/ej2-react-navigations';
import {
  DropDownButtonComponent,
  ItemModel,
} from '@syncfusion/ej2-react-splitbuttons';
import Favorite from './Favorite';
import LeaveApplication from '../../Leave/LeaveApplication';
import DialogConfirm from '../../Dialogbox/DialogConfirm';
import ResourceLinkCard from './ResourceLinkCard';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';

const {
  REACT_APP_ENDPOINT_LEAVESERVICE,
  REACT_APP_ENDPOINT_CORESERVICE,
  REACT_APP_ENDPOINT_ATTENDANCESERVICE,
} = process.env;

interface Props {
  rootmenu: any;
  selectedmenu: any;
  updateJsonDataViewer?: any;
}

export default class Profile extends React.Component<Props> {
  private user: any;
  private _structure: any;
  private token: any = null;
  private items: ItemModel[] = [];
  private lStartDate: any = undefined;
  private lEndDate: any = undefined;
  // private dStartDate: DatePickerComponent | any = null;
  // private dEndDate: DatePickerComponent | any = null;
  private dRange: DateRangePickerComponent | any;
  toastObj: any;

  constructor(props: any) {
    super(props);

    this.user = JSON.parse(Common.getUser() ?? '');
    this._structure = JSON.parse(Common.getStructure() ?? '');
    this.token = Common.parseJwt(Common.getItem('token') ?? '');
  }

  state = {
    leavetypes: [],
    _menuItem: undefined,
    showLeaveDialog: false,
    holidays: [],
    weekends: [],
    InTime: '',
    OutTime: '',
  };

  componentDidMount() {
    if (this.state.leavetypes.length <= 0) {
      this.fetchleavetypes();
    }

    if (this.state.holidays.length <= 0) {
      this.fetchHoliday(new Date().getFullYear());
    }

    if (this.state.weekends.length <= 0) {
      if (this.user.employee) {
        let dayids: any;
        dayids = this.user.employee.employeeShift[0].shift.shiftTran.filter(
          (s: any) => s.isWeekend === true
        );

        this.setState({
          weekends: dayids,
        });
      }
    }

    if (this.user.employee) {
      this.GetAttendanceData(
        this.user.employee.id,
        this.user.employee.employeeShift[0].shiftId,
        Common.RemoveTimezoneOffsetFromDate(new Date())
      );
    }
  }

  disabledDate = (e: any) => {
    if (e) {
      if (this.state.holidays.length > 0) {
        if (
          this.state.holidays.filter(
            (h: any) => new Date(h.date).getTime() == e.date.getTime()
          ).length > 0
        ) {
          e.isDisabled = true;
        }
      }
      if (this.state.weekends.length > 0) {
        if (
          this.state.weekends.filter((w: any) => w.dayid == e.date.getDay())
            .length > 0
        ) {
          e.isDisabled = true;
        }
      }
    }
  };

  private fetchHoliday(myear: number) {
    let startDate = Common.formatDate(new Date(myear, 0, 1), 'yyyy-MM-dd');
    let endDate = Common.formatDate(new Date(myear, 11, 31), 'yyyy-MM-dd');

    let payload: any = {
      fromDate: startDate,
      toDate: endDate,
    };
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_CORESERVICE}/holiday/get`,
      payload,
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((response: any) => {
        this.setState({ holidays: response.data });
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {});
  }

  private fetchleavetypes() {
    Common.ApiCallAsync(
      'POST',
      `${REACT_APP_ENDPOINT_LEAVESERVICE}/leavetype/getleavetypes`,
      { includeBalances: false },
      Common.getToken() || '',
      null,
      this.token.tenant
    )
      .then((resopnse: any) => {
        if (resopnse.data.length > 0 && this.items.length <= 0) {
          for (let i = 0; i < resopnse.data.length; i++) {
            let item: any = resopnse.data[i];
            this.items.push({ id: item.id, text: item.name });
          }
        }
        this.setState({ leavetypes: resopnse.data, _menuItem: this.items });
      })
      .catch((error: any) => {
        console.error(this.items, error);
      })
      .finally(() => {});
  }

  private GetAttendanceData(
    employeeId: string,
    shiftId: string,
    logindatetime: Date
  ) {
    try {
      var payload: any = {
        employeeId: employeeId,
        shiftId: shiftId,
        StartDate: logindatetime,
        EndDate: logindatetime,
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_ATTENDANCESERVICE}/attendance/getall/ess`,
        payload,
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((resopnse: any) => {
          let logindata = resopnse;
          if (logindata != undefined && logindata.length > 0) {
            if (logindata[logindata.length - 1].typeid == 1) {
              let InTime = logindata
                .filter((item: any) => item.typeid == 1)
                .map((item: any) =>
                  Common.formatDate(new Date(item.logindatetime), 'HH:mm')
                );
              this.setState({
                InTime: InTime[InTime.length - 1],
                OutTime: '',
              });
            } else if (logindata[logindata.length - 1].typeid == 2) {
              let OutTime = logindata
                .filter((item: any) => item.typeid == 2)
                .map((item: any) =>
                  Common.formatDate(new Date(item.logindatetime), 'HH:mm')
                );
              this.setState({
                InTime: '',
                OutTime: OutTime[OutTime.length - 1],
              });
            }
          }
        })
        .catch((error: any) => {
          console.error(error);
        })
        .finally(() => {});
    } catch (error) {
      console.error(error);
    }
  }

  setMenuitems = () => {
    // if (this.state.leavetypes.length > 0 && this.items.length <= 0) {
    //   for (let i = 0; i < this.state.leavetypes.length; i++) {
    //     let item: any = this.state.leavetypes[i];
    //     this.items.push({ id: item.id, text: item.name })
    //   }
    //   this.setState({ _menuItem: this.items })
    // }
  };

  handleSave = (data: any) => {
    if (data !== undefined) {
      let strMsg: any = '';
      strMsg =
        'Leave request (' +
        data.leaveType.name +
        ') for ' +
        Common.formatDate(
          Common.RemoveTimezoneOffsetFromDate(new Date(data.startDate)),
          'yyyy-MM-dd'
        ) +
        ' to ' +
        Common.formatDate(
          Common.RemoveTimezoneOffsetFromDate(new Date(data.endDate)),
          'yyyy-MM-dd'
        ) +
        ' has been successfully submitted. You will be notified once your request is approved.';
      if (this.dRange.value) {
        this.dRange.value = null;
      }

      // DialogConfirm.showDialog({
      //   content: `<p class="dialog-contain">${strMsg}</p>`,
      // });
      this.toastObj = ToastUtility.show({
        cssClass: 'e-toast-success',
        icon: 'far fa-circle-check',
        showCloseButton: true,
        content: strMsg,
        target: '#basic_card',
        position: { X: 'Center', Y: 'Top' },
        width: 'auto',
        timeOut: 3000,
      });
      this.setState({ showLeaveDialog: false });
    }
  };
  SelectedEvent = (e: RangeEventArgs) => {
    if (e.endDate) {
      const selectedRange = e.text || '';
      if (this.dRange) {
        const inputElement = this.dRange.element as HTMLInputElement;
        inputElement.value = selectedRange;
      }
      const element = document.querySelector(
        '.e-daterangepicker.e-popup.e-control.e-lib.e-popup-open.e-keyboard'
      );
      if (element) {
        const htmlElement = element as HTMLElement;
        htmlElement.style.visibility = 'hidden';
      }
    }
  };
  render() {
    let mobileno: string = '';
    if (this.user.person) {
      if (
        this.user.person &&
        this.user.person.addresses &&
        this.user.person.addresses.length > 0
      ) {
        let addr: any = this.user.person.addresses.filter(
          (x: any) => x.type === 'Personal'
        );
        if (addr.length > 0) {
          if (addr[0].contacts && addr[0].contacts.length > 0) {
            let cards: any = addr[0].contacts[0].cards;
            if (cards && cards.length > 0) {
              let itm: any = cards[0].items.filter(
                (x: any) => x.itemType === 'Mobile'
              );
              if (itm.length > 0) {
                mobileno = itm[0].itemValue;
              }
            }
          }
        }
      }
    } else {
      mobileno = this.user.phoneNumber;
    }

    const onProfileClick = (e: any) => {
      if (!this.user.employee) {
        // DialogConfirm.showDialog({
        //   content: '<p class="dialog-contain">Sorry, You are not linked with any employee!</p>',
        // });
        return;
      }

      let dv: any = this.user.person;
      dv['employee'] = this.user.employee;
      dv['name'] = this.user.person.name;

      dv['rootmenu'] = this.props.rootmenu;
      dv['selectedmenu'] = { ...this.props.selectedmenu };
      dv['selectedmenu']['dynamicViewer']['compname'] = '_profilebio_';
      dv['selectedtabindex'] = 0;

      dv['tabset'] = [
        {
          name: 'Profile',
          order: 0,
        },
        {
          name: 'Employment',
          order: 1,
        },
      ];
      // if (!this.user.isManager) {
      //   dv['tabset'].splice(3, 1);
      // }
      UpdateDataViewer(dv);
    };

    const UpdateDataViewer = (dv: any) => {
      // if (!this.user.employee) {
      //   //DialogUtility.alert('Sorry, You are not linked with any employee!');
      //   return;
      // }

      // let dv: any = this.user.employee;
      // dv['name'] =
      //   this.user.employee.employeecode + ' : ' + this.user.employee.personName;

      // dv['rootmenu'] = this.props.rootmenu;
      // dv['rootmenu'] = this.props.selectedmenu;
      // dv['selectedtabindex'] = tabindex;

      // dv['tabset'] = [
      //   {
      //     name: 'Personal',
      //     order: 0,
      //   },
      //   {
      //     name: 'Dossier',
      //     order: 1,
      //   },
      //   {
      //     name: 'Contracts',
      //     order: 2,
      //   },
      //   {
      //     id: 'payheads',
      //     name: 'Pay Heads',
      //     order: 3,
      //   },
      //   {
      //     name: 'Pay Slips',
      //     order: 4,
      //   },
      //   {
      //     name: 'Timesheet',
      //     order: 5,
      //   },
      //   {
      //     name: 'Leaves',
      //     order: 6,
      //   },
      //   {
      //     name: 'Loans',
      //     order: 7,
      //   },
      //   {
      //     name: 'Membership',
      //     order: 8,
      //   },
      //   {
      //     name: 'Bank',
      //     order: 9,
      //   },
      //   {
      //     name: 'Obligation',
      //     order: 10,
      //   },
      //   {
      //     name: 'Disciplinary',
      //     order: 11,
      //   },
      // ];
      // if (!this.user.isManager) {
      //   dv['tabset'].splice(3, 1);
      // }
      this.props.updateJsonDataViewer(
        { ...this.props.rootmenu },
        { ...this.props.selectedmenu },
        dv,
        true
      );
    };

    this.setMenuitems();

    const ApplyLeave = (
      dateStart: string,
      dateEnd: string,
      leaveId: any,
      dateRange: any
    ) => {
      let payload: any = {
        employeeId: this.user.employee.id,
        leaveTypesId: leaveId,
        applyDate: new Date(),
        startDate: dateStart,
        endDate: dateEnd,
        statusId: 2,
        leaveAppliedDays: dateRange,
        appliedDays: dateRange.length,
        remark: '',
      };

      Common.ApiCallAsync(
        'POST',
        `${REACT_APP_ENDPOINT_LEAVESERVICE}/leaveapplication/add${
          !this.user.isManager ? '/ess' : ''
        }`,
        payload,
        Common.getToken() || '',
        null,
        this.token.tenant
      )
        .then((response: any) => {
          return response.data;
        })
        .then((response: any) => {
          let strMsg: any = '';
          strMsg =
            'Leave request (' +
            response.leaveType.name +
            ') for ' +
            Common.formatDate(
              Common.RemoveTimezoneOffsetFromDate(this.dRange.value[0]),
              'yyyy-MM-dd'
            ) +
            ' to ' +
            Common.formatDate(
              Common.RemoveTimezoneOffsetFromDate(this.dRange.value[1]),
              'yyyy-MM-dd'
            ) +
            ' has been successfully submitted. You will be notified once your request is approved.';
          if (this.dRange.value[0]) {
            this.dRange.value[0] = null;
          }
          if (this.dRange.value[1]) {
            this.dRange.value[1] = null;
          }
          // DialogConfirm.showDialog({
          //   content: `<p class="dialog-contain">${strMsg}</p>`,
          // });
          this.toastObj = ToastUtility.show({
            cssClass: 'e-toast-success',
            icon: 'far fa-circle-check',
            showCloseButton: true,
            content: strMsg,
            target: '#basic_card',
            position: { X: 'Center', Y: 'Top' },
            width: 'auto',
            timeOut: 3000,
          });
        })
        .catch((error: any) => {
          console.error(error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.messages &&
            error.response.data.messages.length > 0
          ) {
            // DialogConfirm.showDialog({
            //   content: `<p class="dialog-contain">${error.response.data.messages.join(
            //     '<br>'
            //   )}</p>`,
            // });
            this.toastObj = ToastUtility.show({
              cssClass: 'e-toast-warning',
              icon: 'e-warning toast-icons',
              showCloseButton: true,
              content: `${error.response.data.messages.join('<br>')}`,
              target: '#basic_card',
              position: { X: 'Center', Y: 'Top' },
              width: 'auto',
              timeOut: 3000,
            });
          }
        })
        .finally(() => {});
    };
    const onSelect = (e: any) => {
      this.setState({ showLeaveDialog: true });
      // if (this.lStartDate == undefined) {
      //   DialogUtility.alert(
      //     'Sorry, Please set the start date in order to apply for leave.'
      //   );
      //   return;
      // }
      // if (this.lEndDate == undefined) {
      //   DialogUtility.alert(
      //     'Sorry, Please set the end date in order to apply for leave.'
      //   );
      //   return;
      // }
      // if (this.lStartDate > this.lEndDate) {
      //   DialogUtility.alert(
      //     'Sorry, Please set the correct start and end date in order to apply for leave.'
      //   );
      //   return;
      // }

      // if (e) {
      //   let dateRange: any = [];
      //   let sRange = Common.getDatesInRange(this.lStartDate, this.lEndDate);
      //   for (let i = 0; i < sRange.length; i++) {
      //     dateRange.push({
      //       appliedDate: Common.formatDate(
      //         Common.RemoveTimezoneOffsetFromDate(new Date(sRange[i])),
      //         'yyyy-MM-dd'
      //       ),
      //     });
      //   }

      //   ApplyLeave(
      //     Common.formatDate(
      //       Common.RemoveTimezoneOffsetFromDate(new Date(this.lStartDate)),
      //       'yyyy-MM-dd'
      //     ),
      //     Common.formatDate(
      //       Common.RemoveTimezoneOffsetFromDate(new Date(this.lEndDate)),
      //       'yyyy-MM-dd'
      //     ),
      //     e.item.id,
      //     dateRange
      //   );
      //}
    };

    return (
      <>
        <div id='dashboardprofile' className='control-pane'>
          <div className='sample_container card_sample'>
            {/* <!-- Card Component --> */}
            <div className='e-card e-custom-card bordermargincolor fadeUp'>
              <div className='div-img'>
                <img
                  src={'images/Mask_group.png'}
                  style={{ background: 'transparent' }}
                  className='div-profile-bg-img'
                />
                <i
                  title='Show Profile'
                  className='fa-regular fa-pen-to-square'
                  onClick={onProfileClick}
                ></i>
              </div>
              <div className='e-card-header person_logo'>
                {/* <!-- xLarge Circle Avatar--> */}
                <div className='e-avatar e-avatar-circle e-avatar-xlarge'>
                  {this.user.person && this.user.person.image ? (
                    <img
                      // src='https://ej2.syncfusion.com/react/demos/src/avatar/images/pic02.png'
                      src={
                        this.user.person && this.user.person.image
                          ? `data:image/png;base64,${this.user.person.image}`
                          : ''
                      }
                      alt='profile_pic'
                    />
                  ) : this.user.person ? (
                    this.user.person.firstname[0].toUpperCase() +
                    this.user.person.lastname[0].toUpperCase()
                  ) : (
                    this.user.firstName[0].toUpperCase() +
                    this.user.lastName[0].toUpperCase()
                  )}
                </div>
                &nbsp;
              </div>
              <div className='e-card-header peron_profile_name'>
                <div className='e-card-header-caption center'>
                  <div className='e-card-header-title-name'>
                    {this.user.person
                      ? Common.toTitleCaseAll(
                          this.user.person.firstname +
                            ' ' +
                            this.user.person.lastname
                        )
                      : Common.toTitleCaseAll(
                          this.user.firstName + ' ' + this.user.lastName
                        )}
                  </div>
                  <div className='e-card-sub-title'>
                    {this.user.employee && this.user.employee.currentJobName
                      ? this.user.employee && this.user.employee.currentJobName
                      : this.user.roleName}
                  </div>
                  <div className='e-card-In-Out-Time' id='timedisplay'>
                    {this.state.InTime !== ''
                      ? `Clocked In: ${this.state.InTime}`
                      : this.state.OutTime !== ''
                      ? ` Clocked Out: ${this.state.OutTime}`
                      : ''}
                  </div>
                </div>
              </div>
              <div className='e-card-content'>
                <div className='row d-none'>
                  <div className='col-md-2 align-content-center'>
                    <i className='fa-solid fa-at px-2'></i>
                  </div>
                  <div className='col-md-10'>
                    <TextBoxComponent
                      className='textbox-noborder '
                      id='email'
                      name='email'
                      type='email'
                      placeholder='Enter Email'
                      autocomplete='off'
                      value={
                        this.user.employee && this.user.employee.email
                          ? this.user.employee && this.user.employee.email
                          : this.user.email
                      }
                      //   onChange={ontextChange}
                    />
                  </div>
                </div>
                <div className='row d-none'>
                  <div className='col-md-2 align-content-center'>
                    <i className='fa-solid fa-phone px-2'></i>
                  </div>
                  <div className='col-md-10'>
                    <TextBoxComponent
                      className='textbox-noborder '
                      id='mobile'
                      name='mobile'
                      type='text'
                      placeholder='Enter Mobile No.'
                      autocomplete='off'
                      value={mobileno}
                      //   onChange={ontextChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* {this.user.employee && (
              <>
                {this.state._menuItem ? (
                  <>
                    <div
                      className='e-card e-custom-card fadeUp mt-4'
                      style={{ marginTop: '20px' }}
                    >
                      <div
                        className='e-card-header'
                        style={{
                          fontSize: '14px',
                          fontWeight: '500',
                        }}
                      >
                        Leave
                      </div>
                      <div className='e-card-content'>
                        <div className='row py-2'>
                          <div className='col-12'>
                            <DateRangePickerComponent
                              placeholder='Select Leave Duration'
                              floatLabelType='Auto'
                              cssClass='e-hidedate-icon'
                              format={this.user.settings.formats.DateShort}
                              showTodayButton={false}
                              showClearButton={false}
                              allowEdit={false}
                              select={this.SelectedEvent}
                              openOnFocus={true}
                              ref={(scope) => {
                                this.dRange = scope;
                              }}
                            />
                          </div>
                        </div>
                        <div className='row py-2'>
                          <div className='col-12'>
                            <ButtonComponent
                              cssClass='setLeavebtn'
                              onClick={onSelect}
                            >
                              Apply
                            </ButtonComponent>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            )} */}
            <>
              <Favorite
                rootmenu={this.props.rootmenu}
                selectedmenu={this.props.selectedmenu}
                updateJsonDataViewer={UpdateDataViewer}
              />
              {/* <div
                  className='e-card e-custom-card mt-4'
                  style={{ marginTop: '20px' }}
                >
                  <div className='e-card-header'>Favorites</div>
                  <div className='e-card-content'>
                    <div className='row py-2'>
                      <div className='col-12'>
                         <a
                          className='cursor-pointer'
                          onClick={() =>
                            UpdateDataViewer(this.user.isManager ? 6 : 5)
                          }
                        >
                          Apply Leave
                        </a> 
                      </div>
                    </div>
                    <div className='row py-2'>
                      <div className='col-12'>
                        <a
                          className='cursor-pointer'
                          onClick={() =>
                            UpdateDataViewer(this.user.isManager ? 4 : 3)
                          }
                        >
                          View Payslip
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
              <ResourceLinkCard
                rootmenu={this.props.rootmenu}
                selectedmenu={this.props.selectedmenu}
                updateJsonDataViewer={UpdateDataViewer}
              />
            </>
          </div>
        </div>
        {/* {this.state.showLeaveDialog ? (
          <DialogComponent
            id='dlgLeaveApplication'
            className='dlgClassRes'
            showCloseIcon={true}
            header={'Leave Application'}
            width={'40vw'}
            visible={this.state.showLeaveDialog}
            close={() => this.setState({ showLeaveDialog: false })}
            content={() => (
              <LeaveApplication
                leaveobj={undefined}
                isFromHome={true}
                onSave={this.handleSave}
                onCancel={() => this.setState({ showLeaveDialog: false })}
                startdate={
                  this.dRange.value == null ? '' : this.dRange.value[0]
                }
                enddate={this.dRange.value == null ? '' : this.dRange.value[1]}
                disableddays={
                  this.dRange.value !== null
                    ? this.dRange.disabledDays
                    : undefined
                }
              ></LeaveApplication>
            )}
            isModal={true}
            buttons={[{}]}
            statelessTemplates={[]}
          ></DialogComponent>
        ) : null} */}
      </>
    );
  }
}
